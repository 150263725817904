// eslint-disable-next-line max-classes-per-file
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import isEqual from 'react-fast-compare';
import { observable } from 'mobx';
import EditorJs from 'react-editor-js';
import Validator from 'validatorjs';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import axios from 'axios';
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Modal,
  Radio,
  Rate,
  Select,
  Switch,
  TimePicker,
  Tooltip,
  Alert,
  Empty
} from 'antd';
import has from 'lodash/has';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import UploadFile from '../UploadFile';
import EditableTagGroup from '../EditableTagGroup';
import fieldsRules from './rules';
import CList from '../CList';
import BetweenNumberInput from '../BetweenNumberInput';
import DataItemSelector from '../DataItemSelector';
import style from './style.module.scss';
import { PROPERTY_TYPE, RELATION_TYPE, VIEW_KIND } from './constants';
import EDITOR_JS_TOOLS from '../../constants/editorJs';

import urls from '../../constants/urls';

const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

function getDefaultViewByType(type) {
  if (type === 'STRING') {
    return VIEW_KIND.TEXT;
  }
  if (type === 'NUMBER') {
    return VIEW_KIND.NUMBER;
  }
  if (type === 'FILE') {
    return VIEW_KIND.UPLOAD;
  }
  if (type === 'BOOLEAN') {
    return VIEW_KIND.CHECKBOX;
  }
  return VIEW_KIND.TEXT;
}

@withRouter
@inject('t', 'mainStore')
@observer
class ModalForm extends React.Component {
  @observable isVisible = false;

  @observable isLoadingItems = [];

  showModalDialog = () => {
    this.isVisible = true;
  };

  hideModalDialog = () => {
    this.isVisible = false;
  };

  render() {
    const { children, title, ...rest } = this.props;
    return [
      <Button key="ok" onClick={this.showModalDialog}>
        <Icon type="profile" theme="outlined" />
        Edit
      </Button>,
      <Modal
        key="modal"
        title={title}
        visible={this.isVisible}
        footer={null}
        closable
        onOk={this.hideModalDialog}
        onCancel={this.hideModalDialog}
        {...rest}
      >
        {children}
      </Modal>
    ];
  }
}

ModalForm.defaultProps = {
  disabled: false,
  editable: false,
  view: {},
  value: {},
  onError: () => {},
  onChange: () => {}
};

const FieldWrapper = ({
  className,
  name,
  label,
  help,
  description,
  errors,
  isPreview,
  children,
  ...rest
}) => (
  <div
    className={classNames({
      [style.field_same_wrapper_preview]: isPreview,
      [style.field_same_wrapper]: !isPreview
    })}
  >
    {isPreview && <div className={style.overlay} />}
    <div className={classNames(style.field, className)} {...rest}>
      <div className={style.field__meta}>
        {help && (
          <Tooltip title={help}>
            <Icon type="question-circle" className={style.field__helpIcon} />
          </Tooltip>
        )}
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label className={style.field__label} htmlFor={`fieldName_${name}`}>
          {label}
        </label>
        <div className={style.field__description}>{description}</div>
        {errors && <div className={style.field__error}>{errors}</div>}
      </div>
      <div className={style.field__input_w}>{children}</div>
    </div>
  </div>
);

export default
@withRouter
@inject('t', 'mainStore')
@observer
class DeclarativeForm extends React.Component {
  @observable errors = {};

  @observable reinitEditorJs = true;

  @observable loadingFields = [];

  // eslint-disable-next-line no-unused-vars
  validate = nextValue => {
    // eslint-disable-next-line no-unused-vars
    const { view, value: propsValue, onError } = this.props;

    // валидацию на фронте пока что отключаем и
    // надеемся что валидации только на бекенде хватит

    const hasPassed = true;
    const hasFails = false;
    const errors = {};

    if (onError) {
      onError(hasPassed, hasFails, errors);
    }

    // eslint-disable-next-line no-undef
    this.errors = {};

    return {
      hasPassed,
      hasFails,
      errors: {}
    };
  };

  handleOnChange = (fieldValue, fieldName, event) => {
    const { onChange, value } = this.props;

    const nextValue = { ...value };
    nextValue[fieldName] = fieldValue;

    // this.validate(nextValue);

    if (onChange) {
      onChange(nextValue, fieldValue, fieldName, event);
    }
  };

  prepareField = (_fieldName, _field) => {
    // eslint-disable-next-line no-unused-vars
    const { t } = this.props;
    let field = {};
    // console.log('prepareField', _field);
    if (isString(_field)) {
      field = {
        type: _field,
        view: {
          kind: getDefaultViewByType(_field)
        }
      };
    } else if (!_field.view && !!_field.type) {
      field = {
        type: _field.type,
        view: {
          kind: getDefaultViewByType(_field)
        }
      };
      if (Object.prototype.hasOwnProperty.call(_field, 'relationship')) {
        field.relationship = _field.relationship;
      }
    } else {
      field = _field;
    }

    if (isString(field.view)) {
      field.view = {
        kind: field.view
      };
    }

    if (has(field, 'view.kind') && field.view.kind === 'text') {
      field.view.kind = VIEW_KIND.TEXT;
    }

    // console.log('validate field', field);
    const validation = new Validator(field, fieldsRules);
    const hasPassed = validation.passes();
    const hasFails = validation.fails();

    if (hasFails) {
      console.log('validation.errors.errors', validation.errors.errors);
      console.log('field', field);
      const { errors } = validation.errors;
      return {
        type: 'invalid',
        view: {
          name: _fieldName,
          kind: 'invalid',
          label: field.view.label || field.view.title || _fieldName,
          description: (
            <div>
              {Object.keys(errors).map(key => (
                <div key={key + _fieldName}>
                  {key}: {errors[key].join(' ')}
                </div>
              ))}
            </div>
          )
        }
      };
    }

    // console.log('PreparedField', field);
    // console.log('_PreparedField', _field);

    if (hasPassed) {
      return field;
    }

    return field;
  };

  checkLoading(fieldName) {
    return this.loadingFields.includes(fieldName);
  }

  renderOptions(items = {}) {
    // key - value
    // value - label
    return (
      items &&
      Object.keys(items).map(key => (
        <Option key={key} value={key}>
          {items[key]}
        </Option>
      ))
    );
  }

  renderField(fieldName, field) {
    const { value, t, disabled, isPreview, match } = this.props;
    // console.log('renderField', fieldName, field);
    // console.log('renderField value', value[fieldName]);

    const { type } = field;

    let hasRelationships = false;

    if (type === PROPERTY_TYPE.RELATIONSHIP) {
      if (
        field.relationship &&
        field.relationship.modelId &&
        (field.relationship.type === RELATION_TYPE.ONE ||
          field.relationship.type === RELATION_TYPE.MANY)
      ) {
        hasRelationships = true;
      }
    }

    const {
      kind,
      label,
      help,
      description,
      title,
      placeholder,
      items,
      ...rest
    } = field.view;

    const inputProps = {
      disabled,
      className: style.field__input,
      id: `fieldName_${fieldName}`,
      placeholder,
      value: value[fieldName],
      onChange: (event, ev) => {
        if (event && event.target) {
          const inputValue = event.target.value;
          this.handleOnChange(inputValue, fieldName, event);
        } else {
          const inputValue = event;
          this.handleOnChange(inputValue, fieldName, ev);
        }
      },
      ...rest
    };

    let input = <div className={style.field__empty} />;

    if (kind === 'invalid' || type === 'invalid') {
      return (
        <FieldWrapper
          key={fieldName}
          errors={this.errors[fieldName]}
          name={fieldName}
          label={label || title || fieldName}
        >
          <Alert
            message={t('error')}
            description={description}
            label={label}
            type="error"
            showIcon
          />
        </FieldWrapper>
      );
    }

    if (
      hasRelationships &&
      (kind === VIEW_KIND.SELECT || kind === VIEW_KIND.LIST)
    ) {
      input = (
        <DataItemSelector
          {...inputProps}
          ediatable
          type={field.relationship.type}
          modelId={field.relationship.modelId}
        />
      );
    } else if (kind === VIEW_KIND.TEXT) {
      input = <Input type="text" {...inputProps} />;
    } else if (kind === VIEW_KIND.TEXTAREA) {
      input = <TextArea {...inputProps} type="textarea" />;
      // } else if (kind === VIEW_KIND.TEXT_EDITOR) {
      //  input = <TextArea {...inputProps} type="textarea" />;
    } else if (kind === VIEW_KIND.EditorJs) {
      const projectId = get(match, 'params.projectId');

      const editorJsTools = {
        ...EDITOR_JS_TOOLS,
        image: {
          ...EDITOR_JS_TOOLS.image,
          config: {
            /**
             * Custom uploader
             */
            uploader: {
              /**
               * Upload file to the server and return an uploaded image data
               * @param {File} file - file selected from the device or pasted by drag-n-drop
               * @return {Promise.<{success, file: {url}}>}
               */
              uploadByFile(file) {
                // your own uploading logic here
                // eslint-disable-next-line no-undef
                const formData = new FormData();
                formData.append('file', file);

                return axios
                  .post(`${urls.files(projectId)}?type=image`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                  .then(response => {
                    return {
                      success: 1,
                      file: { ...response.data, url: response.data.URI }
                    };
                  });
              }
            }
          }
        }
      };
      input = (
        <div className={style.editorjs}>
          <EditorJs
            enableReInitialize={this.reinitEditorJs}
            onCompareBlocks={(newData, oldData) => {
              this.reinitEditorJs = false;
              return isEqual(newData, oldData);
            }}
            tools={editorJsTools}
            data={inputProps.value}
            onChange={(api, data) => {
              inputProps.onChange(data);
            }}
          />
        </div>
      );
    } else if (kind === VIEW_KIND.COLOR) {
      input = <Input {...inputProps} type="color" style={{ width: '42px' }} />;
    } else if (kind === VIEW_KIND.TAG) {
      input = (
        <EditableTagGroup {...inputProps} value={value[fieldName] || []} />
      );
    } else if (kind === VIEW_KIND.SWITCH) {
      const { className } = inputProps;
      input = (
        <Switch
          {...inputProps}
          className={classNames(className, style.field__input__switch)}
        />
      );
    } else if (kind === VIEW_KIND.CHECKBOX) {
      input = (
        <Checkbox
          {...inputProps}
          checked={value[fieldName]}
          onChange={event =>
            this.handleOnChange(event.target.checked, fieldName, event)
          }
        />
      );
    } else if (kind === VIEW_KIND.RADIO) {
      input = (
        <RadioGroup {...inputProps}>
          {type === PROPERTY_TYPE.BOOLEAN && [
            <Radio key="radio_yes" value>
              {t('yes')}
            </Radio>,
            <Radio key="radio_no" value={false}>
              {t('no')}
            </Radio>
          ]}
          {isArray(items) &&
            items.map(it => <Radio value={it.value}>{it.label}</Radio>)}
        </RadioGroup>
      );
    } else if (kind === VIEW_KIND.UPLOAD) {
      input = <UploadFile {...inputProps} />;
    } else if (kind === VIEW_KIND.IMAGE_UPLOAD) {
      input = <UploadFile listType="picture-card" {...inputProps} />;
    } else if (kind === VIEW_KIND.RATE) {
      input = <Rate {...inputProps} />;
    } else if (kind === VIEW_KIND.EMAIL) {
      input = <Input {...inputProps} type="email" />;
    } else if (kind === VIEW_KIND.PHONE) {
      input = <Input {...inputProps} type="phone" />;
    } else if (
      kind === VIEW_KIND.DATE_RANGE ||
      kind === VIEW_KIND.DATE_TIME_RANGE
    ) {
      //           showTime={{ format: 'HH:mm' }}
      const val = [];

      if (inputProps.value && inputProps.value.from) {
        val[0] = moment(inputProps.value.from);
      }

      if (inputProps.value && inputProps.value.to) {
        val[1] = moment(inputProps.value.to);
      }

      const format =
        kind === VIEW_KIND.DATE_TIME_RANGE ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD';
      const dtProps = {
        ...inputProps,
        showTime:
          kind === VIEW_KIND.DATE_TIME_RANGE ? { format: 'HH:mm' } : false,
        value: val,
        format,
        onChange: v => {
          inputProps.onChange({
            from: moment.isMoment(v[0]) ? v[0].toDate().getTime() : v[0],
            to: moment.isMoment(v[1]) ? v[1].toDate().getTime() : v[1]
          });
        }
      };
      input = <RangePicker {...dtProps} />;
    } else if (kind === VIEW_KIND.NUMBER) {
      input = <InputNumber {...inputProps} />;
    } else if (kind === VIEW_KIND.NUMBER_RANGE) {
      input = <BetweenNumberInput {...inputProps} />;
    } else if (kind === VIEW_KIND.DATE) {
      const dtProps = {
        ...inputProps,
        value: inputProps.value ? moment(inputProps.value) : undefined
      };
      input = <DatePicker format="YYYY/MM/DD HH:mm" {...dtProps} />;
    } else if (kind === VIEW_KIND.DATE_TIME) {
      const dtProps = {
        ...inputProps,
        value: inputProps.value ? moment(inputProps.value) : undefined
      };
      input = (
        <DatePicker
          format="YYYY/MM/DD HH:mm"
          showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
          {...dtProps}
        />
      );
    } else if (kind === VIEW_KIND.TIME) {
      const format = 'HH:mm';
      let val;
      if (inputProps.value) {
        val = `${moment().format('MM-DD-YYYY')} ${inputProps.value}`;
      }

      const timeProps = {
        ...inputProps,
        format,
        value: val ? moment(val, `MM-DD-YYYY ${format}`) : undefined,
        onChange: v => {
          if (moment.isMoment(v)) {
            inputProps.onChange(v.format(format));
          } else {
            inputProps.onChange(v);
          }
        }
      };
      input = (
        <TimePicker
          showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
          {...timeProps}
        />
      );
    } else if (
      kind === VIEW_KIND.SELECT ||
      kind === VIEW_KIND.MULTIPLE_SELECT
    ) {
      input = (
        <Select
          {...inputProps}
          mode={kind === VIEW_KIND.MULTIPLE_SELECT ? 'multiple' : 'default'}
        >
          {this.renderOptions(items)}
        </Select>
      );
    } else if (kind === VIEW_KIND.LIST) {
      input = <CList editable {...inputProps} />;
    } else if (kind === VIEW_KIND.AUTOCOMPLETE) {
      input = (
        <AutoComplete {...inputProps}>{this.renderOptions(items)}</AutoComplete>
      );
    } else if (kind === VIEW_KIND.TAG) {
      input = <EditableTagGroup {...inputProps} />;
    }

    const {
      renderPreField,
      renderPostField,
      rowClassName,
      fieldClassName
    } = this.props;

    const text = label || title || fieldName;

    return (
      <div
        key={`wrapper_${fieldName}`}
        className={classNames(style.field__general_wrapper, rowClassName)}
      >
        {renderPreField ? renderPreField(fieldName) : null}
        <FieldWrapper
          isPreview={isPreview}
          className={fieldClassName}
          key={fieldName}
          errors={this.errors[fieldName]}
          name={fieldName}
          label={text}
          description={description}
          help={help}
        >
          {input}
        </FieldWrapper>
        {renderPostField ? renderPostField(fieldName) : null}
      </div>
    );
  }

  render() {
    const { view } = this.props;

    if (view.length) {
      return view
        .sort((a, b) => a.order - b.order)
        .map(modelProp => {
          return this.renderField(
            modelProp.name,
            this.prepareField(modelProp.name, modelProp)
          );
        });
    }
    return <Empty />;
  }
}

DeclarativeForm.ModalForm = ModalForm;
