import React from 'react';
import { Layout, Button, Modal, Table, Divider, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { observer, inject, Observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import moment from 'moment';
import style from './style.module.scss';
import routes from '../../constants/routes';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import DataCell from '../../components/DataCell';

const { Header, Content, Footer } = Layout;

export default
@withRouter
@inject('formStore', 'auth', 'mainStore', 't')
@observer
class FormResultPage extends React.Component {
  @observable data = [];

  @observable filters = {};

  @observable sorter = {};

  @observable pagination = {
    pageSize: 15,
    total: 0,
    current: 1
  };

  @observable isLoading = false;

  @observable deleteButtonIsLoading = false;

  @observable willDeleted = [];

  @observable checkList = [];

  columns = [
    {
      title: (
        <Observer
          render={() => {
            return (
              <Checkbox
                onChange={e => {
                  if (e.target.checked) {
                    this.checkList.clear();
                    this.data.forEach(record => {
                      this.checkList.push(record.id);
                    });
                  } else {
                    this.checkList.clear();
                  }
                }}
                checked={
                  this.checkList.length === this.data.length &&
                  this.data.length !== 0
                }
              />
            );
          }}
        />
      ),
      width: '34px',
      key: 'Checkbox',
      render: record => (
        <Observer
          render={() => {
            return (
              <Checkbox
                key={record.id}
                onChange={e => this.handleOnCheck(e, record)}
                checked={this.checkList.includes(record.id)}
              />
            );
          }}
        />
      )
    },
    {
      // eslint-disable-next-line react/destructuring-assignment
      title: this.props.t('Created'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: date => moment(date).format('HH:mm DD MMMM YYYY')
    }
  ];

  componentDidMount() {
    this.fetchAll();
  }

  getForm() {
    const { match, formStore } = this.props;
    const { formId } = match.params;
    return formStore.items.get(formId);
  }

  getColumns = () => {
    const form = this.getForm();

    const schema = toJS(form.schema);

    const c = [];

    schema.forEach(schemaItem => {
      c.unshift({
        title: schemaItem.view.label || schemaItem.name,
        dataIndex: `value.${schemaItem.name}`,
        key: `value.${schemaItem.name}`,
        render: value => (
          <DataCell value={value} view={schemaItem.view.kind} />
        )
      });
    });

    c.unshift(...this.columns);

    return c;
  };

  fetchAll = async () => {
    await this.fetchDataTotal();

    const limit = this.pagination.pageSize;
    const offset = (this.pagination.current - 1) * limit;
    await this.fetchData({
      limit,
      offset,
      filters: this.filters,
      sorter: this.sorter
    });
  };

  handleOnCheck = (event, record) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      this.checkList.push(record.id);
    } else {
      this.checkList.remove(record.id);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.pagination = pagination;
    this.filters = filters;
    this.sorter = sorter;

    const limit = pagination.pageSize;
    const offset = (pagination.current - 1) * limit;

    this.fetchData({
      limit,
      offset,
      filters,
      sorter
    });
  };

  handleOnClickReload = () => {
    this.fetchAll();
  };

  handleOnClickDelete = () => {
    const { match, t } = this.props;
    const form = this.getForm();
    const self = this;
    Modal.confirm({
      title: t('Are you sure to delete this message?'),
      content: t(
        'This item will be deleted immediately. You can`t undo this action'
      ),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      async onOk() {
        self.deleteButtonIsLoading = true;
        const projectId = get(match, 'params.projectId');

        await Promise.all(
          self.checkList.map(recordId => {
            return form.deleteDataRecordById(projectId, recordId);
          })
        );

        self.data.replace(
          self.data.filter(record => !self.checkList.includes(record.id))
        );
        self.checkList.clear();
        self.deleteButtonIsLoading = false;
      }
    });
  };

  fetchDataTotal = async () => {
    const { match, history } = this.props;
    const { formId } = match.params;
    const form = this.getForm();
    if (!form) {
      history.push(`/404?message=${formId} not found!`);
    } else {
      const projectId = get(match, 'params.projectId');
      this.isLoading = true;
      const data = await form.fetchTotal({ projectId });
      this.pagination.total = data.total;
      this.isLoading = false;
    }
  };

  fetchData = async ({ limit, offset, filters, sorter }) => {
    const { match, history } = this.props;
    const { formId } = match.params;
    const form = this.getForm();
    if (!form) {
      history.push(`/404?message=${formId} not found!`);
    } else {
      const projectId = get(match, 'params.projectId');
      this.isLoading = true;
      this.data = await form.fetchResult({
        projectId,
        limit,
        offset,
        filters,
        sorter
      });
      this.isLoading = false;
    }
  };

  render() {
    const form = this.getForm();
    const { mainStore, t } = this.props;
    const { selectedProject } = mainStore;

    if (!form) {
      return null;
    }

    return (
      <Layout>
        <Header className={style.header}>
          <div className={style.header__panel}>
            <Link to={routes.to(routes.forms, selectedProject.id)}>
              <Button shape="circle" icon="arrow-left" />
            </Link>
            <div className={style.title}>{form.name}</div>
            <Divider type="vertical" />
            <Button
              loading={this.isLoading}
              icon="reload"
              onClick={this.handleOnClickReload}
            >
              {t('reload')}
            </Button>
            <Divider type="vertical" />
            <Button
              icon="delete"
              type="danger"
              onClick={this.handleOnClickDelete}
              loading={this.deleteButtonIsLoading}
              disabled={this.checkList.length === 0}
            />
          </div>
        </Header>

        <Content className={style.content}>
          <div className={style.contentBody}>
            <Table
              bordered
              rowKey="id"
              columns={this.getColumns()}
              className={style.table}
              loading={this.isLoading}
              pagination={this.pagination}
              onChange={this.handleTableChange}
              dataSource={this.data}
            />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
      </Layout>
    );
  }
}
