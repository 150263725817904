import { types, getSnapshot, flow } from 'mobx-state-tree';
import axios from 'axios';
import get from 'lodash/get';
import { form } from './base';
import urls from '../constants/urls';

const signInForm = types.compose(
  'SignInForm',
  form,
  types.model({
    email: '',
    password: '',
    remember: false
  })
);

const signUpForm = types.compose(
  'SignUpForm',
  form,
  types.model({
    email: '',
    password: '',
    confirmPassword: ''
  })
);

export default types
  .model('Auth', {
    signUpForm,
    signInForm,
    email: '',
    id: '',
    language: 'en',
    theme: 'light',
    done: false
  })
  .volatile(() => ({
    handlerOnAuth: null,
    handlerOnLogout: null
  }))
  .preProcessSnapshot((snapshot = {}) => ({
    signUpForm: {},
    signInForm: {},
    ...snapshot
  }))
  .views(self => ({
    isAuthenticated() {
      return !!self.id;
    }
  }))
  .actions(self => ({
    onAuth(handler) {
      self.handlerOnAuth = handler;
    },
    onLogout(handler) {
      self.handlerOnLogout = handler;
    },
    check: flow(function*() {
      try {
        const { data } = yield axios.get(urls.signInStatus);
        if (data && data.id) {
          self.id = data.id;
          self.email = data.email;
          self.language = data.language;
          self.theme = data.theme;
        }
        return true;
      } catch (e) {
        throw new Error(e.message);
      }
    }),
    signIn: flow(function*(payload) {
      try {
        const { data } = yield axios.post(urls.signIn, getSnapshot(payload));
        if (data && data.id) {
          self.id = data.id;
          self.email = data.email;
          self.language = data.language || 'en';
          self.role = data.role || 'developer';

          if (self.handlerOnAuth) {
            self.handlerOnAuth();
          }
        }
        return data;
      } catch (e) {
        return e.response.data;
      }
    }),
    signUp: flow(function*(payload, language) {
      try {
        let langProp = '';
        if (language) {
          langProp = `?lang=${language}`;
        }
        const { data } = yield axios.post(
          urls.signUp + langProp,
          getSnapshot(payload)
        );
        return data;
      } catch (e) {
        return e.response.data;
      }
    }),
    logout: flow(function*() {
      yield axios.post(urls.signOut);
      if (self.handlerOnLogout) {
        self.handlerOnLogout();
      }
    }),
    resetPassword: flow(function*(payload) {
      try {
        const { data } = yield axios.put(urls.resetPassword, payload);
        return data;
      } catch (e) {
        return get(e, 'response.data', {});
      }
    }),
    recoverAccess: flow(function*(email) {
      try {
        const { data } = yield axios.post(urls.recoveryAccess, { email });
        return data;
      } catch (e) {
        return get(e, 'response.data', { errors: ['something wrong'] });
      }
    }),
    updateProfile: flow(function*(payload) {
      const { data } = yield axios.put(urls.profile, payload);
      self.theme = data.theme;
      self.language = data.language;
    }),
    deleteProfile: flow(function*() {
      return yield axios.delete(urls.profile);
    }),
    updatePassword: flow(function*(payload) {
      return yield axios.put(urls.password, payload);
    })
  }));
