import React from 'react';
import classNames from 'classnames';
import { Button, Icon } from 'antd';
import style from './style.module.scss';

const ListHeader = ({ className, title, onClick, children, ...rest }) => (
  <div className={classNames(style.header, className)} {...rest}>
    <div className="title">{title}</div>
    {children}
    <Button className={style.handler} onClick={onClick}>
      <Icon type="plus-circle" />
    </Button>
  </div>
);

export default ListHeader;
