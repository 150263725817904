import React from 'react';
import { inject, observer } from 'mobx-react';
import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { Button, Select, Form, Input } from 'antd';
import { USER_ROLE } from '../../constants';
import style from './style.module.scss';

@inject('t')
@observer
class FormAddMember extends React.Component {
  constructor(props) {
    super(props);
    const self = this;
    const { t } = props;
    const plugins = { dvr: validatorjs };
    const fields = [
      {
        name: 'email',
        label: t('Email'),
        placeholder: t('Enter Email'),
        rules: 'required|email|string|min:5',
        value: ''
      },
      {
        name: 'role',
        label: t('Role'),
        placeholder: t('Select role'),
        value: USER_ROLE.EDITOR,
        rules: 'required'
      }
    ];

    const options = {
      showErrorsOnReset: false,
      validateOnBlur: false
    };

    const hooks = {
      onSuccess(form) {
        console.log('Form is valid! Form Values!', form.values());
        self.props.project.addMember(form.values()).then(() => {
          self.form.reset();
        });
      },
      onError(form) {
        // get all form errors
        console.log('All form errors', form.errors());
      }
    };
    this.form = new MobxReactForm({ fields }, { plugins, hooks, options });
  }

  render() {
    const { t } = this.props;
    const { form } = this;
    return (
      <Form layout="inline" onSubmit={form.onSubmit}>
        <div className={style.formItem}>
          <div className={style.formItem__title}>
            <label htmlFor={form.$('email').id}>{form.$('email').label}</label>
            <div className={style.formItem__error}>{form.$('email').error}</div>
          </div>
          <Input {...form.$('email').bind()} />
        </div>
        <div className={style.formItem}>
          <div className={style.formItem__title}>
            <label htmlFor={form.$('role').id}>{form.$('role').label}</label>
            <div className={style.formItem__error}>{form.$('role').error}</div>
          </div>
          <Select {...form.$('role').bind()} style={{ width: 120 }}>
            {Object.keys(USER_ROLE)
              .filter(
                it =>
                  USER_ROLE[it] !== USER_ROLE.OWNER &&
                  USER_ROLE[it] !== USER_ROLE.ALL
              )
              .map(key => (
                <Select.Option key={key} value={USER_ROLE[key]}>
                  {t(USER_ROLE[key])}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div className={style.formItem}>
          <Button
            className={style.formInline__submit}
            onClick={form.onSubmit}
            type="primary"
          >
            {t('Add')}
          </Button>
        </div>
      </Form>
    );
  }
}

export default FormAddMember;
