import React from 'react';
import classNames from 'classnames';
import style from './style.module.scss';

const EmptyButton = ({
  htmlType,
  theme,
  children,
  tooltip,
  className,
  ...rest
}) => {
  return (
    <button
      type="button"
      htmltype={htmlType || 'button'}
      theme={theme}
      className={classNames(style.EmptyButton, className)}
      {...rest}
    >
      {children}
    </button>
  );
};

export default EmptyButton;
