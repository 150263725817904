import { PROPERTY_TYPE, VIEW_KIND } from '../DeclarativeForm/constants';

export default function getDefaultField(name, order) {
  return {
    name,
    order,
    type: PROPERTY_TYPE.STRING,
    relationship: null,
    rules: [],
    default: '',
    view: {
      kind: VIEW_KIND.TEXT,
      label: window.t('label'),
      description: ''
    }
  };
}
