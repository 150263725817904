const USER_ROLE = Object.freeze({
  OWNER: 'OWNER',
  DEVELOPER: 'DEVELOPER',
  EDITOR: 'EDITOR',
  ALL: ['EDITOR', 'DEVELOPER', 'OWNER']
});

const RIGHT = Object.freeze({
  PROJECT_CAN_DELETE: Object.freeze([USER_ROLE.OWNER]),
  PROJECT_CAN_LEAVE: Object.freeze([USER_ROLE.DEVELOPER, USER_ROLE.EDITOR]),
  PROJECT_CAN_INVITE: Object.freeze([USER_ROLE.DEVELOPER, USER_ROLE.OWNER]),
  PROJECT_CAN_EDIT: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER]),
  PROJECT_CAN_CHANGE_OWNER: Object.freeze([USER_ROLE.OWNER]),

  MODEL_CAN_BE_CREATE: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER]),
  MODEL_CAN_BE_UPDATE: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER]),

  HOOK_CAN_BE_CREATE: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER]),
  HOOK_CAN_BE_DELETE: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER]),
  HOOK_CAN_BE_UPDATE: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER]),

  FORM_CAN_DELETE: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER]),
  FORM_CAN_READ: USER_ROLE.ALL,
  FORM_CAN_BE_CREATE: Object.freeze([USER_ROLE.OWNER, USER_ROLE.DEVELOPER])
});

export default {
  USER_ROLE,
  RIGHT
};

export { USER_ROLE, RIGHT };
