import React from 'react';
import classNames from 'classnames';
import style from '../style.module.scss';

const Icon = ({className, ...rest}) => (
    <svg viewBox="0 0 24 24" className={classNames('icon', style.icon, className)} {...rest}>
        <path
            d="M17.016 11.016v-2.016h-2.016v2.016h2.016zM12.984 11.016v-2.016h-1.969v2.016h1.969zM9 11.016v-2.016h-2.016v2.016h2.016zM20.016 2.016c1.078 0 1.969 0.891 1.969 1.969v12c0 1.078-0.891 2.016-1.969 2.016h-14.016l-3.984 3.984v-18c0-1.078 0.891-1.969 1.969-1.969h16.031z"></path>
    </svg>
);

export default Icon;
