import React from 'react';
import classNames from 'classnames';
import { Icon, List, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { observable } from 'mobx';
import get from 'lodash/get';
import IconText from '../../components/IconText';
import routes from '../../constants/routes';
import style from './style.module.scss';
import EmptyButton from '../../components/EmptyButton';
import { RIGHT } from '../../constants';

@withRouter
@inject('t', 'match', 'mainStore')
@observer
class MultiItem extends React.Component {
  @observable isExpanded = false;

  @observable data = [];

  @observable isLoading = false;

  @observable isLoaded = false;

  handleOnExpand = async () => {
    const { item } = this.props;
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded && !this.isLoaded) {
      this.data = await item.fetchEntries({ compact: true });
      this.isLoaded = true;
    }
  };

  renderEntries() {
    const { match, item, t, onClickInfo, onClickEdit } = this.props;
    const projectId = get(match, 'params.projectId');

    if (this.isLoaded) {
      return (
        <List
          key="nested_list"
          className={style.nestedList}
          dataSource={this.data}
          renderItem={dataItem => (
            <List.Item
              className={style.nestedList__item}
              key={dataItem.id}
              actions={[
                <IconText
                  key="info"
                  onClick={e => onClickInfo(item, dataItem, e)}
                  type="api"
                  text={t('API')}
                />,
                <Link
                  to={routes.to(
                    routes.pageRecord,
                    projectId,
                    item.id,
                    dataItem.id
                  )}
                >
                  <IconText
                    key="edit"
                    onClick={e => onClickEdit(item, e)}
                    type="edit"
                    text={t('edit')}
                  />
                </Link>
              ]}
            >
              <List.Item.Meta
                className={style.list__item__meta}
                title={dataItem.value.title || 'n/a'}
                description={dataItem.value.description || 'n/a'}
              />
            </List.Item>
          )}
        />
      );
    }
    return <Spin key="spin" className={style.list__loadable} size="large" />;
  }

  render() {
    const { item, t, match, mainStore, onClickInfo } = this.props;
    const projectId = get(match, 'params.projectId');
    const project = mainStore.projectsStore.get(projectId);
    const role = project.role;

    return [
      <List.Item
        key={item.id}
        className={style.list__item}
        actions={[
          <Link to={routes.to(routes.pageRecord, projectId, item.id, 'new')}>
            <IconText type="plus" text={t('add new page')} />
          </Link>,
          <IconText
            onClick={e => onClickInfo(item, e)}
            type="api"
            text={t('API')}
          />,
          RIGHT.MODEL_CAN_BE_UPDATE.includes(role) && (
            <Link to={routes.to(routes.page, projectId, item.id)}>
              <IconText type="build" text={t('settings')} />
            </Link>
          )
        ]}
      >
        <div className={style.list__item__body}>
          <List.Item.Meta
            className={style.list__item__meta}
            avatar={
              <Icon type={item.icon} className={style.list__item__icon} />
            }
            title={item.title}
            description={item.description}
          />
          <div className={style.list__item__expander}>
            <EmptyButton onClick={this.handleOnExpand}>
              <Icon
                className={classNames({
                  [style.expanded]: this.isExpanded,
                  [style.collapsed]: !this.isExpanded
                })}
                type="right"
              />
            </EmptyButton>
          </div>
        </div>
      </List.Item>,
      this.isExpanded ? this.renderEntries() : null
    ];
  }
}

export default MultiItem;
