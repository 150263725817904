import React from 'react';
import { Button, Checkbox, Modal } from 'antd';
import { observer, inject } from 'mobx-react';
import { toJS, observable, autorun } from 'mobx';
import style from '../../pages/PageEditor/style.module.scss';

export default
@inject('t')
@observer
class ModelMetaEditor extends React.Component {
  @observable isVisible = false;

  @observable value = {};

  w = autorun(() => {
    console.log('autorun change props');
    const { value } = this.props;
    this.value = toJS(value);
  });

  componentDidMount() {
    const { value } = this.props;
    console.log('ModelMetaEditor ', value);
    this.value = toJS(value);
  }

  handleOnClick = () => {
    this.isVisible = true;
  };

  handleOnChange = () => {
    console.log('value is changes');
    const { onChange } = this.props;
    onChange(toJS(this.value));
  };

  handleOnCancel = () => {
    this.isVisible = false;
  };

  render() {
    const { t, className } = this.props;
    return [
      <Button
        type="primary"
        className={className}
        key="save"
        icon="more"
        onClick={this.handleOnClick}
      >
        {t('additional parameters')}
      </Button>,
      <Modal
        key="eto-modal-okno_tvoy_kapitan_o4evidnost`"
        width="720px"
        onClick={event => event.stopPropagation()}
        title={t('additional parameters')}
        visible={this.isVisible}
        onCancel={this.handleOnCancel}
        closable
        footer={null}
      >
        <div>
          <Checkbox
            checked={this.value.isSingleton}
            onChange={e => {
              this.value.isSingleton = e.target.checked;
              this.handleOnChange();
            }}
          />
          <span className={style.label}>{t('is_singleton')}</span>
        </div>
        <div>
          <Checkbox
            checked={this.value.isVisibleInPageEditor}
            onChange={e => {
              this.value.isVisibleInPageEditor = e.target.checked;
              this.handleOnChange();
            }}
          />
          <span className={style.label}>{t('is_visible_in_page_editor')}</span>
        </div>
      </Modal>
    ];
  }
}
