import React from 'react';
import {
  Layout,
  Button,
  Divider,
  Modal,
  Icon,
  Checkbox,
  Tooltip,
  Spin
} from 'antd';
import { inject, Observer, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import nanoid from 'nanoid';
import get from 'lodash/get';
import { Link, Prompt } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import axios from 'axios';
import FormFieldEditor from '../../components/FormFieldEditor';

import DeclarativeForm from '../../components/DeclarativeForm';
import EditableTitle from '../../components/EditableTitle';
import IconSelector from '../../components/IconSelector';
import getDefaultField from '../../components/FormFieldEditor/getDefaultField';
import routes from '../../constants/routes';

import stylePage from '../pages.module.scss';
import style from './style.module.scss';
import { handleError } from '../../utils';
import {
  PROPERTY_TYPE,
  VIEW_KIND
} from '../../components/DeclarativeForm/constants';
import { RIGHT } from '../../constants';
import urls from '../../constants/urls';

const { Header, Content } = Layout;
const iconStyle = { fontSize: '22px', marginLeft: '8px' };

const EDIT_MODE = Object.freeze({
  CONTENT_EDITOR: 'CONTENT_EDITOR',
  SCHEMA_EDITOR: 'SCHEMA_EDITOR',
  SCHEMA_CONTENT_EDITOR: 'SCHEMA_CONTENT_EDITOR'
});

export default
@inject('mainStore', 'formStore', 'auth', 't')
@observer
class PageEditor extends React.Component {
  savedModel = null;

  @observable apiInfoIsVisible = false;

  @observable isLoading = false;

  @observable isVisibleFieldEditor = false;

  @observable data = {
    value: {}
  };

  @observable isDirty = false;

  @observable modelName = 'new page';

  @observable modelToken = 'n/a';

  @observable modelIcon = '';

  @observable meta = {};

  @observable schema = [];

  @observable originModel = {};

  @observable allFields = [];

  @observable field = {};

  @observable fieldName = '';

  componentDidMount() {
    const { mainStore, match, data, t } = this.props;
    const modelId = get(match, 'params.pageId', 'new');
    const isNew = modelId === 'new';
    const model = mainStore.selectedProject.dataModels.get(modelId);

    if (isNew) {
      this.modelIcon = 'file';
      this.modelName = t('new page');
      this.data = { value: {} };

      this.meta = {
        isSingleton: true,
        isVisibleInPageEditor: true
      };

      this.field = null;
      this.fieldName = null;
      this.schema = [
        {
          name: 'title',
          order: -2,
          type: PROPERTY_TYPE.STRING,
          rules: ['required'],
          relationship: null,
          view: {
            kind: VIEW_KIND.TEXT,
            label: t('title'),
            help: t('title_help'),
            default: '',
            description: '',
            title: '',
            placeholder: t('title'),
            items: null
          },
          default: ''
        },
        {
          name: 'description',
          order: -1,
          type: PROPERTY_TYPE.STRING,
          view: {
            kind: VIEW_KIND.TEXTAREA,
            label: t('description')
          }
        },
        {
          name: 'keywords',
          order: 0,
          type: PROPERTY_TYPE.ARRAY,
          view: {
            label: t('keywords'),
            kind: VIEW_KIND.TAG
          }
        }
      ];
    } else {
      this.modelIcon = model.icon;
      this.modelName = model.name;
      this.meta = toJS(model.meta);
      this.schema = toJS(model.schema);
      this.data = data;
    }
    this.allFields = this.schema.map(it => it.name);
    this.updateOrigin();
  }

  updateDiff = () => {
    const currentValue = {
      data: toJS(this.data),
      modelName: toJS(this.modelName),
      modelIcon: toJS(this.modelIcon),
      meta: toJS(this.meta),
      schema: toJS(this.schema),
      modelToken: toJS(this.modelToken),
      allFields: toJS(this.allFields)
    };
    this.isDirty = !isEqual(this.origin, currentValue);
  };

  updateOrigin = () => {
    this.origin = {
      data: toJS(this.data),
      modelName: toJS(this.modelName),
      modelIcon: toJS(this.modelIcon),
      meta: toJS(this.meta),
      schema: toJS(this.schema),
      modelToken: toJS(this.modelToken),
      allFields: toJS(this.allFields)
    };
  };

  handleOnChangeData = nextValue => {
    this.data.value = nextValue;
    this.updateDiff();
  };

  handleChangeFieldName = (nextValue, oldValue) => {
    const { t, match } = this.props;
    const projectId = get(match, 'params.projectId');
    const modelId = get(match, 'params.modelId', 'new');

    if (modelId === 'new' || !this.allFields.includes(oldValue)) {
      if (nextValue !== oldValue) {
        const index = this.schema.findIndex(
          userProp => userProp.name === oldValue
        );
        this.schema[index].name = nextValue;
        this.updateDiff();
      }
    } else {
      this.isLoading = true;

      const modal = Modal.warn({
        // Processing operation
        title: t('Renaming data items...'),
        className: style.modalInfinityProgress,
        okButtonProps: { style: { display: 'none' } },
        content: <Spin size="large" />
      });

      axios
        .put(`${urls.models(projectId)}/${modelId}/schema/${oldValue}`, {
          name: nextValue
        })
        .then(() => {
          const index = this.schema.findIndex(
            userProp => userProp.name === oldValue
          );
          this.schema[index].name = nextValue;
        })
        .catch(e => {
          handleError(e);
        })
        .finally(() => {
          this.isLoading = false;
          modal.destroy();
        });
    }
  };

  handleFinishEditField = () => {
    const index = this.schema.findIndex(
      userProp => userProp.name === this.fieldName
    );
    const value = this.field !== null ? { ...this.field } : null;
    if (index !== -1) {
      this.schema[index] = value;
    } else {
      this.schema.push(value);
    }
    this.field = {};
    this.fieldName = null;
    this.isVisibleFieldEditor = false;
    this.updateDiff();
  };

  handleCancelEditField = () => {
    const self = this;
    const { t } = this.props;

    Modal.confirm({
      title: t('Are you sure cancel this changes?'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        self.field = {};
        self.isVisibleFieldEditor = false;
      }
    });
  };

  handleOnChangeModelName = v => {
    this.modelName = v;
    this.updateDiff();
  };

  onChangeField = nextFieldValue => {
    this.field = nextFieldValue;
  };

  deleteField = fieldName => {
    const index = this.schema.findIndex(
      userProp => userProp.name === fieldName
    );
    if (index !== -1) {
      this.schema.splice(index, 1);
    }
  };

  editField = fieldName => {
    this.fieldName = fieldName;
    this.field = this.schema[fieldName];
    this.isVisibleFieldEditor = true;
  };

  handleOnSavePage = async () => {
    const { mainStore, match, history } = this.props;
    this.isLoading = true;
    const modelId = get(match, 'params.pageId', 'new');
    const isNew = modelId === 'new';
    const project = mainStore.selectedProject;

    try {
      if (isNew) {
        if (this.savedModel) {
          this.savedModel = await project.updateDataModel({
            id: this.savedModel.id,
            name: this.modelName,
            icon: this.modelIcon,
            schema: toJS(this.schema),
            meta: toJS(this.meta)
          });
        } else {
          this.savedModel = await project.createDataModel({
            name: this.modelName,
            icon: this.modelIcon,
            schema: toJS(this.schema),
            meta: toJS(this.meta)
          });
        }
        if (this.savedModel.meta.isSingleton) {
          try {
            const savedData = await this.savedModel.saveEntry(toJS(this.data));
            this.isDirty = false;

            history.push(
              routes.to(
                routes.page,
                mainStore.selectedProject.id,
                this.savedModel.id,
                savedData.id
              )
            );
          } catch (e) {
            console.error(e);
            handleError(e);
          }
        } else {
          this.isDirty = false;
          history.push(routes.to(routes.pages, mainStore.selectedProject.id));
        }
      } else {
        const model = mainStore.selectedProject.dataModels.get(modelId);

        if (
          this.getEditorMode() === EDIT_MODE.SCHEMA_EDITOR ||
          this.getEditorMode() === EDIT_MODE.SCHEMA_CONTENT_EDITOR
        ) {
          const updatedModel = await project.updateDataModel({
            id: modelId,
            name: this.modelName,
            icon: this.modelIcon,
            schema: toJS(this.schema),
            meta: toJS(this.meta)
          });

          if (updatedModel.meta.isSingleton) {
            this.data = await model.saveEntry(toJS(this.data));
          }
          this.allFields = updatedModel.schema.map(it => it.name);

          this.schema = toJS(updatedModel.schema);
        } else {
          this.data = await model.saveEntry(toJS(this.data));
          const dataId = get(match, 'params.dataId', 'new');

          if (dataId === 'new') {
            this.isDirty = false;
            history.push(
              routes.to(
                routes.pageRecord,
                mainStore.selectedProject.id,
                modelId,
                this.data.id
              )
            );
          }
        }
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
      this.updateOrigin();
      this.updateDiff();
    }
  };

  handleOnDeletePage = () => {
    const { t } = this.props;

    // надо определить, а что мы удаляем?
    // или схему или данные или схему и данные(если синглтон)
    const mode = this.getEditorMode();
    Modal.confirm({
      title: t('Are you sure delete this page?'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        const { mainStore, match, history } = this.props;
        const pageId = get(match, 'params.pageId');
        const model = mainStore.selectedProject.dataModels.get(pageId);
        try {
          if (
            mode === EDIT_MODE.CONTENT_EDITOR ||
            mode === EDIT_MODE.SCHEMA_CONTENT_EDITOR
          ) {
            if (this.data.id && this.data.id !== 'new') {
              await model.removeEntry(this.data.id);
            }
          }

          if (
            mode === EDIT_MODE.SCHEMA_EDITOR ||
            mode === EDIT_MODE.SCHEMA_CONTENT_EDITOR
          ) {
            await mainStore.selectedProject.deleteDataModel(pageId);
          }
          this.isDirty = false;
          history.push(routes.to(routes.pages, mainStore.selectedProject.id));
        } catch (e) {
          handleError(e);
        }
      }
    });
  };

  handleOnCreateNewFormField = () => {
    // eslint-disable-next-line prefer-spread
    const maxOrder = Math.max.apply(
      Math,
      this.schema.map(function(o) {
        return o.order;
      })
    );
    this.fieldName = nanoid(12);
    this.field = getDefaultField(this.fieldName, maxOrder + 1);
    this.isVisibleFieldEditor = true;
  };

  handleOnChangeModelIcon = v => {
    this.modelIcon = v;
    this.updateDiff();
  };

  getEditorMode = () => {
    const { match, mainStore } = this.props;
    const dataId = get(match, 'params.dataId');
    const modelId = get(match, 'params.pageId');
    const project = mainStore.selectedProject;
    const role = project.role;

    if (
      this.meta.isSingleton &&
      RIGHT.MODEL_CAN_BE_UPDATE.includes(role) &&
      dataId === undefined &&
      modelId
    ) {
      return EDIT_MODE.SCHEMA_CONTENT_EDITOR;
    }
    if (
      !RIGHT.MODEL_CAN_BE_UPDATE.includes(role) ||
      (!this.meta.isSingleton && dataId && modelId)
    ) {
      return EDIT_MODE.CONTENT_EDITOR;
    }
    if (!this.meta.isSingleton && dataId === undefined && modelId) {
      return EDIT_MODE.SCHEMA_EDITOR;
    }
    throw Error('unexpected state');
  };

  renderModalEditField() {
    const { t, match } = this.props;
    const pageId = get(match, 'params.pageId', 'new');

    return (
      <Modal
        onClick={event => event.stopPropagation()}
        width="90vw"
        title={t('input_fields_editor')}
        visible={this.isVisibleFieldEditor}
        onCancel={this.handleCancelEditField}
        closable
        footer={[
          <div key="footer" className={style.footerModel}>
            <div key="left-side">
              <Button
                icon="delete"
                type="danger"
                disabled={this.fieldName === null}
                onClick={this.handleCancelEditField}
              />
            </div>
            <div key="right-side">
              <Button key="cancel" onClick={this.handleCancelEditField}>
                {t('cancel')}
              </Button>
              <Button key="add" onClick={this.handleFinishEditField}>
                {t('add')}
              </Button>
            </div>
          </div>
        ]}
      >
        <Observer
          render={() => (
            <FormFieldEditor
              isNew={pageId === 'new'}
              name={this.fieldName}
              value={this.field}
              onChange={this.onChangeField}
            />
          )}
        />
      </Modal>
    );
  }

  renderDesign() {
    const isDisabled = !(
      this.getEditorMode() === EDIT_MODE.CONTENT_EDITOR ||
      this.getEditorMode() === EDIT_MODE.SCHEMA_CONTENT_EDITOR
    );

    const canEditSchema =
      this.getEditorMode() === EDIT_MODE.SCHEMA_EDITOR ||
      this.getEditorMode() === EDIT_MODE.SCHEMA_CONTENT_EDITOR;

    const { match, mainStore } = this.props;
    const { projectsStore } = mainStore;
    const pageId = get(match, 'params.pageId', 'new');
    const projectId = get(match, 'params.projectId');
    const role = projectsStore.get(projectId).role;

    return [
      // <div key="info_text" className={style.infoHeader}>
      //   <div className={style.infoHeader__col1__title}>{t('field_name')}</div>
      //   <div className={style.infoHeader__col2}>{t('preview')}</div>
      // </div>,
      <div key="page_form" className={style.design}>
        <div className={style.design__form}>
          <DeclarativeForm
            disabled={isDisabled}
            view={toJS(this.schema)}
            value={this.data.value}
            onChange={this.handleOnChangeData}
            renderPreField={fieldName => {
              return (
                RIGHT.MODEL_CAN_BE_CREATE.includes(role) && (
                  <div className={style.infoHeader__col1}>
                    <EditableTitle
                      onChange={this.handleChangeFieldName}
                      value={fieldName}
                      disabled={
                        fieldName === 'title' ||
                        fieldName === 'description' ||
                        fieldName === 'keywords' ||
                        (pageId !== 'new' && this.allFields.includes(fieldName))
                      }
                    />
                  </div>
                )
              );
            }}
            renderPostField={fieldName => {
              if (!canEditSchema) return null;
              if (
                fieldName === 'title' ||
                fieldName === 'description' ||
                fieldName === 'keywords'
              ) {
                return (
                  <div
                    key={`PostField_${fieldName}`}
                    className={style.design__control}
                  />
                );
              }
              return (
                <div
                  key={`PostField_${fieldName}`}
                  className={style.design__control}
                >
                  <Icon
                    className={style.control__Item}
                    style={iconStyle}
                    type="setting"
                    onClick={() => this.editField(fieldName)}
                  />
                  <Icon
                    className={style.control__Item}
                    style={iconStyle}
                    type="close"
                    onClick={() => this.deleteField(fieldName)}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    ];
  }

  render() {
    const { t, match, mainStore } = this.props;
    const { selectedProject } = mainStore;
    const pageId = get(match, 'params.pageId', 'new');
    const isNew = pageId === 'new';
    const project = mainStore.selectedProject;
    const model = project.dataModels.get(pageId);
    const role = project.role;

    const canEditSchema =
      this.getEditorMode() === EDIT_MODE.SCHEMA_EDITOR ||
      this.getEditorMode() === EDIT_MODE.SCHEMA_CONTENT_EDITOR;

    return (
      <Layout>
        <Prompt
          when={this.isDirty}
          message={t(
            'You have unsaved changes. Are you sure you want to leave?'
          )}
        />
        <Header className={style.header}>
          <Link to={routes.to(routes.pages, selectedProject.id)}>
            <Button shape="circle" icon="arrow-left" />
          </Link>
          <Divider type="vertical" />

          <div className={style.header__title}>{t('page editor')}</div>

          <Divider type="vertical" />

          <div className={style.header__panel}>
            <div className={style.header__leftSide}>
              <Button
                htmlType="button"
                className={stylePage.header__item}
                type="primary"
                disabled={this.isLoading || !this.isDirty}
                onClick={this.handleOnSavePage}
                icon="save"
              >
                {t('save')}
              </Button>
              {canEditSchema && (
                <Button
                  onClick={this.handleOnCreateNewFormField}
                  type="primary"
                  icon="plus-circle"
                >
                  {t('add a new field')}
                </Button>
              )}
            </div>
            <div>
              {(RIGHT.MODEL_CAN_BE_UPDATE.includes(role) ||
                !model.meta.isSingleton) && (
                <Button
                  icon="delete"
                  disabled={this.isLoading || isNew}
                  type="danger"
                  onClick={this.handleOnDeletePage}
                />
              )}
            </div>
          </div>
        </Header>

        <Content className={style.content}>
          <div className={style.contentBodyWithoutFooter}>
            <div className={style.editableString}>
              <div className={style.header__leftSide}>
                <IconSelector
                  className={style.subHeader__item}
                  value={this.modelIcon}
                  onChange={this.handleOnChangeModelIcon}
                />
                <EditableTitle
                  value={this.modelName}
                  onChange={this.handleOnChangeModelName}
                />
              </div>
              {isNew && (
                <div>
                  <span className={style.label}>{t('is_singleton_page')}</span>
                  <Tooltip title={t('help.is_singleton_page')}>
                    <Icon
                      style={{ marginRight: '8px' }}
                      type="question-circle"
                    />
                  </Tooltip>
                  <Checkbox
                    disabled={!isNew}
                    checked={this.meta.isSingleton}
                    onChange={e => (this.meta.isSingleton = e.target.checked)}
                  />
                </div>
              )}
            </div>
            {this.renderDesign()}
          </div>
        </Content>

        {this.isVisibleFieldEditor ? this.renderModalEditField() : null}
        {this.apiInfoIsVisible ? this.renderModalApiInfo() : null}
      </Layout>
    );
  }
}
