import React from 'react';
import { Layout, Button, Table, Modal, Tag } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import get from 'lodash/get';
import classNames from 'classnames';
import routes from '../../constants/routes';
import NoData from '../../components/NoData';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import { handleError, handleReferenceException } from '../../utils';
import DataCell from '../../components/DataCell';
import style from './style.module.scss';
import stylePage from '../pages.module.scss';
import ApiInfo from '../../components/ApiInfo';

const { Header, Content, Footer } = Layout;

export default
@inject('auth', 't', 'mainStore')
@withRouter
@observer
class DataPage extends React.Component {
  @observable selectedFormTemplateId = -1;

  @observable data = [];

  @observable filters = {};

  @observable sorter = {};

  @observable pagination = {
    pageSize: 10,
    total: 0,
    current: 1
  };

  @observable isLoading = false;

  @observable apiInfoIsVisible = false;

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll = async () => {
    const { match, history } = this.props;
    const projectId = get(match, 'params.projectId');
    const limit = this.pagination.pageSize;
    const offset = (this.pagination.current - 1) * limit;

    try {
      await this.fetchDataTotal();
      await this.fetchData({
        limit,
        offset,
        filters: this.filters,
        sorter: this.sorter
      });
    } catch (e) {
      if (e.response && e.response.status === 402) {
        handleError(e, () => {
          history.push(routes.to(routes.summary, projectId));
        });
      } else {
        handleError(e);
      }
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.pagination = pagination;
    this.filters = filters;
    this.sorter = sorter;

    const limit = pagination.pageSize;
    const offset = (pagination.current - 1) * limit;

    this.fetchData({
      limit,
      offset,
      filters,
      sorter
    });
  };

  fetchDataTotal = async () => {
    const { model } = this.props;
    this.isLoading = true;
    const data = await model.fetchTotal();
    this.pagination.total = data.total;
    this.isLoading = false;
  };

  fetchData = async ({ limit, offset, filters, sorter }) => {
    const { model } = this.props;

    this.isLoading = true;

    this.data = await model.fetchEntries({
      limit,
      offset,
      filters,
      sorter
    });
    this.isLoading = false;
  };

  handleOnDeleteDataItem = record => {
    const { model, t, match } = this.props;

    Modal.confirm({
      title: t('Are you sure to delete this data item?'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        try {
          await model.removeEntry(record.id);
          this.data = this.data.filter(item => item.id !== record.id);
        } catch (e) {
          if (e.response && e.response.status === 409) {
            handleReferenceException(e, match);
          } else {
            handleError(e);
          }
        }
      }
    });
  };

  handleOnClick = record => {
    const { model, history, mainStore } = this.props;
    const { selectedProject } = mainStore;
    history.push(
      routes.to(routes.dataRecord, selectedProject.id, model.id, record.id)
    );
  };

  getColumns = () => {
    const { t, model } = this.props;
    const schema = toJS(model.schema);
    const c = schema.map(element => ({
      title: element.view.label,
      dataIndex: element.name,
      key: element.name,
      width: 'auto',
      render: (text, record) => {
        if (record && record.value && record.value[element.name]) {
          return (
            <DataCell
              dataset={record.value}
              value={record.value[element.name]}
              view={element.view.kind}
              type={element.type}
            />
          );
        }
        return <div />;
      }
    }));

    return [
      ...c,
      {
        title: t(''),
        width: '96px',
        render: (text, record, index) => {
          return (
            <div className={style.actionCell}>
              <Button
                icon="delete"
                type="danger"
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.handleOnDeleteDataItem(record, index);
                }}
              />
            </div>
          );
        }
      }
    ];
  };

  renderEmptyList() {
    const { t } = this.props;
    return (
      <div className={stylePage.emptyList}>
        <NoData>{t('Write your first record')}</NoData>
      </div>
    );
  }

  renderModalApiInfo() {
    const { t, model } = this.props;
    return (
      <Modal
        width="720px"
        onClick={event => event.stopPropagation()}
        title={t('API')}
        visible={this.apiInfoIsVisible}
        onCancel={() => {
          this.apiInfoIsVisible = false;
        }}
        closable
        footer={null}
      >
        <div>
          {t('public token')}: <Tag color="#108ee9">{model.token}</Tag>
        </div>

        <div className={style.apiItem}>
          {t('Get all items')}
          <ApiInfo
            api={`${window.location.origin}/api/public/models/${model.token}/data`}
            type="GET"
          />
        </div>
      </Modal>
    );
  }

  render() {
    const { t, model, mainStore } = this.props;
    const { selectedProject } = mainStore;

    return (
      <Layout>
        {this.renderModalApiInfo()}
        <Header className={stylePage.headerWithSide}>
          <div className={stylePage.header__leftSide}>
            <div className={style.header__title}>
              {`${t('data')} «${model.name}»`}
            </div>
          </div>
          <div className={stylePage.header__rightSide}>
            <Button
              className={classNames(
                stylePage.header__item__info,
                stylePage.header__item
              )}
              icon="api"
              type="primary"
              disabled={this.isLoading}
              onClick={() => (this.apiInfoIsVisible = true)}
            >
              {t('API')}
            </Button>
            <Link
              to={routes.to(
                routes.dataRecord,
                selectedProject.id,
                model.id,
                'new'
              )}
            >
              <Button type="primary" icon="plus-circle">
                {t('add new record')}
              </Button>
            </Link>
          </div>
        </Header>
        <Content className={stylePage.content}>
          <div className={stylePage.contentBody}>
            {this.data.length === 0 ? (
              this.renderEmptyList()
            ) : (
              <Table
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => this.handleOnClick(record, rowIndex)
                  };
                }}
                bordered
                rowKey="id"
                columns={this.getColumns()}
                className={style.table}
                loading={this.isLoading}
                pagination={this.pagination}
                onChange={this.handleTableChange}
                dataSource={this.data}
              />
            )}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
      </Layout>
    );
  }
}
