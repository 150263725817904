import { types } from 'mobx-state-tree';

export default types
  .model('AppStore', {
    _isLoading: false
  })
  .actions(self => ({
    isLoading(value) {
      self._isLoading = value;
    }
  }));
