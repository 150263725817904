import React from 'react';
import { List, Button, Icon, Divider } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import style from './style.module.scss';

@observer
class CList extends React.Component {
  static defaultProps = {
    selectable: false,
    editable: false,
    options: [],
    selected: [],
    value: [],
    onChange: () => {},
    formatter: () => {}
  };

  @observable isVisibleAddDialog = false;

  @observable isEditable = false;

  @observable nextValue = '';

  handleShowModalAddDialog = () => {
    this.isVisibleAddDialog = false;
  };

  handleEdit = () => {
    const { value } = this.props;
    this.nextValue = value;
    this.isEditable = true;
  };

  handleCancel = () => {
    this.isEditable = false;
  };

  handleSave = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.nextValue);
      this.nextValue = '';
      this.isEditable = false;
    }
  };

  handleOnSelect = (item, e) => {
    const { value, onChange } = this.props;
    const idx = value.findIndex(it => it.id === item.id);
    if (idx === -1) {
      onChange([...value, item], e);
    } else {
      onChange([...value.filter(it => it.id !== item.id)], e);
    }
  };

  renderItem(item) {
    const { editable, selectable, value, formatter } = this.props;

    return (
      <List.Item
        className={style.itemW}
        onClick={e => this.handleOnSelect(item, e)}
      >
        <div className={style.item}>
          <div className={style.item__content}>
            {selectable && (
              <div className={style.item__state}>
                {value.find(i => i.id === item.id) ? (
                  <Icon type="check" />
                ) : null}
              </div>
            )}
            {formatter
              ? formatter(item)
              : item.title || item.name || item.label}
          </div>
          {editable && (
            <div className={style.controls}>
              <Divider type="vertical" />
              <Icon type="edit" className={style.handler} />
              <Icon type="delete" className={style.handler} />
            </div>
          )}
        </div>
      </List.Item>
    );
  }

  render() {
    const { editable, className, options, ...rest } = this.props;

    return (
      <div className={classNames(className, style.clist)} {...rest}>
        <List
          size="small"
          bordered
          dataSource={options}
          renderItem={item => this.renderItem(item)}
          footer={
            <div className={style.footer}>
              {editable && (
                <Button
                  onClick={this.handleShowModalAddDialog}
                  className={style.handler}
                  type="primary"
                  shape="circle"
                  icon="plus"
                  size="small"
                />
              )}
            </div>
          }
        />
        <div className={style.footer} />
      </div>
    );
  }
}

export default CList;
