import React from 'react';
import { Input, Icon } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { observable, toJS } from 'mobx';
import style from './style.module.scss';

const iconStyle = {
  fontSize: 'inherit',
  marginLeft: '8px',
  marginTop: '4px'
};

@observer
class EditableTitle extends React.Component {
  @observable isEditable = false;

  @observable nextValue = '';

  nodeInput = null;

  componentDidUpdate() {
    if (this.nodeInput) {
      this.nodeInput.focus();
    }
  }

  getRef = e => {
    this.nodeInput = e;
  };

  handleEdit = () => {
    const { value, disabled } = this.props;
    if (disabled) return;
    this.nextValue = value;
    this.isEditable = true;
  };

  handleCopy = () => {
    const { value } = this.props;
    const input = document.createElement('input');
    input.value = value;
    input.classList.add(style.invisibleElement);
    document.body.append(input);
    /* Select the text field */
    input.select();
    document.execCommand('copy', false, null);
    /* Copy the text inside the text field */
    input.blur();
    input.remove();
  };

  handleCancel = () => {
    this.isEditable = false;
  };

  handleSave = () => {
    const { value, onChange } = this.props;
    if (onChange) {
      onChange(toJS(this.nextValue), value);
      this.nextValue = '';
      this.isEditable = false;
    }
  };

  handleOnKeyDown = e => {
    if (e.key === 'Escape') {
      this.handleCancel();
      e.preventDefault();
      e.stopPropagation();
    } else if (e.key === 'Enter') {
      this.handleSave();
    }
  };

  handleOnBlur = () => {
    setTimeout(() => {
      if (this.isEditable) {
        this.handleSave();
      }
    }, 100);
  };

  handleChangeNextValue = e => {
    e.stopPropagation();
    e.preventDefault();
    this.nextValue = e.target.value;
  };

  render() {
    const { rest, className, value, disabled, copy = true } = this.props;

    if (this.isEditable) {
      return (
        <span className={classNames(style.editable, className)}>
          <Input
            ref={this.getRef}
            onBlur={this.handleOnBlur}
            onKeyDown={this.handleOnKeyDown}
            className={style.input}
            {...rest}
            value={this.nextValue}
            onChange={this.handleChangeNextValue}
          />
          <div className={style.control__wrapper}>
            <Icon
              theme="filled"
              style={iconStyle}
              type="close-circle"
              onClick={this.handleCancel}
            />
            <Icon
              theme="filled"
              style={iconStyle}
              type="check-circle"
              onClick={this.handleSave}
            />
          </div>
        </span>
      );
    }

    return (
      <span className={classNames(style.editable, className)}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          onClick={this.handleEdit}
          className={classNames({
            [style.value]: true,
            [style.disabled]: disabled
          })}
        >
          {value}
        </span>
        {!disabled && (
          <Icon
            style={iconStyle}
            className={style.iconEdit}
            type="edit"
            onClick={this.handleEdit}
          />
        )}

        {copy && (
          <Icon
            style={iconStyle}
            className={style.iconEdit}
            type="copy"
            onClick={this.handleCopy}
          />
        )}
      </span>
    );
  }
}

export default EditableTitle;
