import React from 'react';
import Validator from 'validatorjs';
import ReactDOM from 'react-dom';
import './index.css';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment-duration-format';

import { EventEmitter } from 'fbemitter';
import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import detector from 'i18next-browser-languagedetector';
import registerServiceWorker from './registerServiceWorker';
import App from './components/App';
import translationRU from './i18n/ru.json';
import translationEN from './i18n/en.json';
import localeSpecRu from './i18n/moment-format-duration-ru';

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  }
};

window.onload = function onload() {
  window.moment = moment;
  const emitter = new EventEmitter();

  window.addEventListener('resize', e => {
    emitter.emit('resize', e);
  });

  document.body.addEventListener('keyup', e => {
    emitter.emit('keyup', e);
  });

  i18n
    .use(detector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init(
      {
        resources,
        keySeparator: false, // we do not use keys in form messages.welcome
        fallbackLng: {
          'ru-RU': ['ru', 'en'],
          'en-GB': ['en'],
          en: ['en'],
          default: ['en']
        },
        debug: true,
        interpolation: {
          escapeValue: false // react already safes from xss
        }
      },
      (err, t) => {
        Validator.useLang(i18n.language);
        moment.locale(i18n.language);
        moment.updateLocale('ru', localeSpecRu);

        ReactDOM.render(
          <App emitter={emitter} i18n={i18n} t={t} />,
          document.getElementById('root')
        );
      }
    );

  registerServiceWorker();
};
