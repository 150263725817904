import _truncate from 'lodash/truncate';
import { toJS } from 'mobx';
import classNames from 'classnames';
import moment from 'moment';
import { inject } from 'mobx-react';
import React from 'react';
import { PROPERTY_TYPE, VIEW_KIND } from '../DeclarativeForm/constants';
import { strip } from '../../utils';
import style from '../../pages/DataExplorer/style.module.scss';

@inject('t')
class DataCell extends React.Component {
  render() {
    const { value, view, type, t } = this.props;

    if (!value) {
      return null;
    }

    if (view === VIEW_KIND.NUMBER_RANGE) {
      return `${value.from || ''} - ${value.to || ''}`;
    }

    if (view === VIEW_KIND.DATE || view === VIEW_KIND.DATE_TIME) {
      const format =
        view === VIEW_KIND.DATE_TIME ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD';
      return moment(value).format(format);
    }

    if (view === VIEW_KIND.DATE_TIME_RANGE || view === VIEW_KIND.DATE_RANGE) {
      const format =
        view === VIEW_KIND.DATE_TIME_RANGE ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD';

      let res = '';
      if (value.from) {
        res += moment(value.from).format(format);
      }
      res += ' ~ ';
      if (value.to) {
        res += moment(value.to).format(format);
      }
      return res;
    }

    if (view === VIEW_KIND.COLOR) {
      return (
        <div
          style={{
            width: '36px',
            height: '36px',
            backgroundColor: value,
            border: '1px solid gray'
          }}
        />
      );
    }

    if (type === PROPERTY_TYPE.RELATIONSHIP) {
      return t('link_on_another_object'); // TODO - ПОПАДОС
    }

    if (view === VIEW_KIND.EditorJs) {
      const element = Object.values((value || { blocks: [] }).blocks).filter(
        block => block.type === 'header' || block.type === 'paragraph'
      )[0];

      if (element && element.data && element.data.text) {
        return (
          <div>
            {_truncate(strip(JSON.stringify(element.data.text)), {
              length: 400
            })}
          </div>
        );
      }

      return <div />;
    }

    if (view === VIEW_KIND.UPLOAD) {
      const val = toJS(value);
      if (Array.isArray(val)) {
        return val.map(file => (
          <a
            download={file.name}
            key={file.id}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              'ant-upload-list-item-name',
              'ant-upload-list-item-name-icon-count-2',
              style.upload_file
            )}
            title={file.name}
            href={file.URI}
          >
            {file.name}
          </a>
        ));
      }
      return <div />;
    }

    if (view === VIEW_KIND.IMAGE_UPLOAD) {
      const val = toJS(value);
      if (Array.isArray(val)) {
        return val.map(file => (
          <a
            key={file.id}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              'ant-upload-list-item-name',
              'ant-upload-list-item-name-icon-count-2',
              style.upload_img
            )}
            title={file.name}
            href={file.URI}
          >
            <img alt={file.name} width={72} src={file.thumbUrl} />
          </a>
        ));
      }
      return <div />;
    }

    return <div>{value.toString()}</div>;
  }
}

export default DataCell;
