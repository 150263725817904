import React from 'react';
import { Button, Icon, Upload, Modal } from 'antd';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { observable, toJS } from 'mobx';
import get from 'lodash/get';
import urls from '../../constants/urls';
import { handleError } from '../../utils';

@withRouter
@inject('t', 'formStore')
@observer
class UploadFile extends React.Component {
  @observable fileList = [];

  @observable previewImage = null;

  constructor(props) {
    super(props);
    const { showUploadingOnly } = props;
    this.fileList = (props.fileList || [])
      .filter(file => (showUploadingOnly ? file.status === 'uploading' : true))
      .map(fileItem => {
        if (fileItem && fileItem.id) {
          const _fileItem = {
            uid: fileItem.id,
            name: fileItem.name,
            status: fileItem.status,
            url: fileItem.URI,
            type: fileItem.type,
            createdAt: fileItem.createdAt,
            updatedAt: fileItem.updatedAt
          };
          if (this.isImageUpload(props)) {
            _fileItem.thumbUrl = fileItem.thumbUrl;
          }
          return _fileItem;
        }
        return fileItem;
      });
  }

  /**
   {
      uid: '2',
      name: 'yyy.png',
      response: 'Server Error 500', // custom error message to show
      status: 'done',
      url: 'http://www.baidu.com/yyy.png',
    },

   val name: String,
   val type: String,
   val URI: String,
   @ContextualSerialization
   val id: ObjectId? = null,
   @ContextualSerialization
   val createdAt: Date,
   @ContextualSerialization
   val updatedAt: Date
   *
   * * */

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    const { showUploadingOnly } = this.props;

    if (value) {
      this.fileList = toJS(value)
        .filter(file =>
          showUploadingOnly ? file.status === 'uploading' : true
        )
        .map(fileItem => {
          if (fileItem && fileItem.id) {
            const _fileItem = {
              uid: fileItem.id,
              name: this.fmtNameAndSize(fileItem),
              size: fileItem.size,
              status: fileItem.status,
              url: fileItem.URI,
              type: fileItem.type,
              createdAt: fileItem.createdAt,
              updatedAt: fileItem.updatedAt
            };

            if (this.isImageUpload()) {
              _fileItem.thumbUrl = fileItem.thumbUrl;
            }
            return _fileItem;
          }
          return fileItem;
        });
    }
  }

  handleCancel = () => {
    this.previewImage = null;
  };

  handlePreview = file => {
    this.previewImage = file;
  };

  handleOnChange = ({ fileList }) => {
    let hasChanges = false;

    this.fileList = fileList.map(fileItem => {
      if (
        fileItem.status === 'done' &&
        fileItem.response &&
        fileItem.response.id
      ) {
        hasChanges = true;
        const _fileItem = {
          uid: fileItem.response.id,
          name: this.fmtNameAndSize(fileItem.response),
          status: fileItem.status,
          size: fileItem.response.size,
          url: fileItem.response.URI,
          type: fileItem.response.type,
          createdAt: fileItem.response.createdAt,
          updatedAt: fileItem.response.updatedAt
        };
        if (this.isImageUpload()) {
          _fileItem.thumbUrl = fileItem.response.thumbUrl;
        }
        return _fileItem;
      }
      return fileItem;
    });

    if (hasChanges) {
      this.handleChange();
    }
  };

  handleChange = () => {
    const { onChange } = this.props;
    onChange(
      toJS(this.fileList).map(fileItem => {
        const {
          uid,
          url,
          type,
          size,
          createdAt,
          updatedAt,
          thumbUrl
        } = fileItem;

        if (thumbUrl) {
          return {
            id: uid,
            name: this.fmtNameAndSize(fileItem),
            size,
            URI: url,
            thumbUrl,
            type,
            createdAt,
            updatedAt
          };
        }
        return {
          id: uid,
          name: this.fmtNameAndSize(fileItem),
          size,
          URI: url,
          type,
          createdAt,
          updatedAt
        };
      })
    );
  };

  isImageUpload = props => {
    const { listType } = props || this.props;
    const result = listType === 'picture' || listType === 'picture-card';
    return result;
  };

  handleOnRemoveUploadedFile = async file => {
    try {
      // Файлы удаляются вручную отдельно в разделе файлы
      // там же можно включить параметр удаления файла если на него нет ссылок
      // await axios.delete(`${urls.files(projectId)}/${file.uid}${actionImages}`);
      const index = this.fileList.findIndex(it => it.uid === file.uid);
      if (index !== -1) {
        this.fileList.splice(index, 1);
        this.handleChange();
      }
    } catch (e) {
      if (e.response && e.response.status && e.response.status === 404) {
        const index = this.fileList.findIndex(it => it.uid === file.uid);
        this.fileList.splice(index, 1);
        this.handleChange();
      } else {
        handleError(e);
      }
    }
  };

  handleOnError = (error, response) => {
    const { onError, showUploadingOnly } = this.props;
    if (onError) {
      onError(error, response);
    }
    if (showUploadingOnly) {
      this.fileList = [];
    }
  };

  // eslint-disable-next-line react/sort-comp
  nbs = ' ';

  fmtNameAndSize(fileItem) {
    const name = fileItem.name || '';
    if (name.includes(this.nbs)) {
      return name;
    }
    return fileItem.size
      ? `${name}${this.nbs}(${(fileItem.size / 1024 / 1024).toFixed(2)} MB)`
      : '';
  }

  render() {
    const {
      match,
      t,
      disabled,
      className,
      listType,
      showUploadList,
      hideButton,
      buttonType
    } = this.props;

    const projectId = get(match, 'params.projectId');

    let actionImages = '';
    let title = t('Upload a file');
    if (this.isImageUpload()) {
      actionImages = '?type=image';
      title = t('Upload a image');
    }

    return [
      <Upload
        onError={this.handleOnError}
        showUploadList={showUploadList}
        key="uploader"
        listType={listType}
        name="file"
        onPreview={actionImages.length > 0 ? this.handlePreview : undefined}
        className={className}
        multiple={false}
        disabled={disabled}
        fileList={toJS(this.fileList)}
        action={urls.files(projectId) + actionImages}
        onChange={this.handleOnChange}
        onRemove={this.handleOnRemoveUploadedFile}
      >
        <Button
          htmlType="button"
          type={buttonType}
          style={{ display: hideButton ? 'none' : 'block' }}
        >
          <Icon type="upload" />
          {title}
        </Button>
      </Upload>,
      actionImages.length > 0 && !!this.previewImage ? (
        <Modal
          key="Modal"
          title={this.previewImage.name}
          visible={!!this.previewImage}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            alt="previewImage"
            style={{ width: '100%' }}
            src={this.previewImage.url}
          />
        </Modal>
      ) : null
    ];
  }
}

export default UploadFile;
