export default {
  pricing: '/api/pricing',
  currencyRates: '/api/billing/currency-rates',
  signUp: '/api/auth/sign-up',
  recoveryAccess: '/api/auth/recovery-access',
  resetPassword: '/api/auth/reset-password',
  signIn: '/api/auth/sign-in',
  signInStatus: '/api/auth/sign-in-status',
  signOut: '/api/auth/sign-out',
  password: '/api/auth/profile/password',
  profile: '/api/auth/profile',
  extraInfo: '/api/auth/profile/info',
  projects: '/api/projects',

  forms: projectId => `/api/projects/${projectId}/forms`,
  formsResult: (projectId, formId) =>
    `/api/projects/${projectId}/forms/${formId}/data`,
  formsTemplate: projectId => `/api/projects/${projectId}/forms-templates`,
  models: projectId => `/api/projects/${projectId}/models`,
  data: (projectId, modelId) =>
    `/api/projects/${projectId}/models/${modelId}/data`,
  files: projectId => `/api/projects/${projectId}/files`,
  resetPlan: projectId => `/api/projects/${projectId}/billing/reset-plan`,
  processPayment: (projectId, paymentUuid) =>
    `/api/projects/${projectId}/billing/process-payment/${paymentUuid}`,
  changePlan: projectId => `/api/projects/${projectId}/billing/plan`
};
