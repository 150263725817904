import { types } from 'mobx-state-tree';
import TemplateFieldModel from './templateField';
import { PROPERTY_TYPE } from '../components/DeclarativeForm/constants';

export default types
  .compose(
    'FieldModel',
    TemplateFieldModel,
    types.model('AbstractFieldModel', {
      type: PROPERTY_TYPE.STRING,
      name: types.maybe(types.string)
    })
  )
  .views(self => ({
    get key() {
      return self.id;
    }
  }));
