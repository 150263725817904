import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Button, Modal, List, Collapse, Divider } from 'antd';
import isEqual from 'lodash/isEqual';
import { observer, inject } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import FormBuilder from '../../components/FormBuilder';
import style from './style.module.scss';
import IconText from '../../components/IconText';
import ApiInfo from '../../components/ApiInfo';
import DeliveryEditor from '../../components/DeliveryEditor';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import { handleError } from '../../utils';
import routes from '../../constants/routes';
import { RIGHT } from '../../constants';

const { Header, Content, Footer } = Layout;
const Panel = Collapse.Panel;

// eslint-disable-next-line no-unused-vars
function getInputType(type) {
  if (type === 'string') {
    return 'TEXT';
  }

  if (type === 'file') {
    return 'FILE';
  }

  return 'TEXT';
}

export default
@withRouter
@inject('mainStore', 'formStore', 'auth', 't')
@observer
class FormPage extends React.Component {
  @observable addFormIsVisible = false;

  @observable infoFormItem = {};

  @observable infoFormIsVisible = false;

  @observable addFormIsLoading = false;

  @observable isDirty = false;

  @observable isEditing = false;

  @observable confirmLoading = false;

  @observable selectedFormTemplateId = -1;

  @observable deliveryList = [];

  @observable form = {};

  @observable prevForm = {};

  handleOnChangeDeliveryList = value => {
    this.deliveryList = value;
    this.diff();
  };

  handleInfoForm = item => {
    this.infoFormItem = item;
    this.infoFormIsVisible = true;
  };

  diff = () => {
    const form = {
      ...toJS(this.form),
      deliveryList: toJS(this.deliveryList)
    };
    const prevForm = toJS(this.prevForm);
    console.log('new_form', form);
    console.log('old_form', prevForm);
    this.isDirty = !isEqual(form, prevForm);
  };

  handleEditForm = item => {
    const { formStore } = this.props;

    const form = formStore.items.get(item.id);

    this.deliveryList = toJS(form.deliveryList);

    this.form = {
      id: toJS(form.id),
      name: toJS(form.name),
      schema: toJS(form.schema)
    };

    this.prevForm = {
      id: toJS(form.id),
      name: toJS(form.name),
      schema: toJS(form.schema),
      deliveryList: toJS(form.deliveryList)
    };

    this.addFormIsVisible = true;
  };

  handleDeleteForm = item => {
    const { formStore, t } = this.props;
    const self = this;
    Modal.confirm({
      title: t('Are you sure to delete this form?'),
      content: t(
        'This item will be deleted immediately. You can`t undo this action'
      ),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      async onOk() {
        await formStore.deleteForm(item.id).then(() => {
          self.confirmLoading = false;
        });
      }
    });
  };

  @action handleOnSelectFormTemplate = formTemplate => {
    if (formTemplate) {
      this.selectedFormTemplateId = formTemplate.id;
    }
  };

  handleShowAddForm = () => {
    const { auth, t } = this.props;
    // create new Item
    this.isDirty = false;

    this.deliveryList = [
      {
        name: t('Default delivery'),
        transport: 'email',
        destinations: [auth.email]
      }
    ];

    this.form = {
      id: undefined,
      name: t('New form'),
      schema: []
    };

    this.prevForm = {
      id: toJS(this.form.id),
      name: toJS(this.form.name),
      schema: toJS(this.form.schema),
      deliveryList: toJS(this.deliveryList)
    };

    this.addFormIsVisible = true;
    this.addFormIsLoading = false;
  };

  handleOk = () => {
    const { formStore } = this.props;
    this.addFormIsLoading = true;

    const form = {
      ...toJS(this.form),
      deliveryList: toJS(this.deliveryList)
    };

    formStore.addForm(form).then(() => {
      this.addFormIsLoading = false;
      this.addFormIsVisible = false;
      this.selectedFormTemplateId = -1;
    });
  };

  handleOnCloseIsVisible = () => {
    this.infoFormIsVisible = false;
  };

  handleUpdateForm = async () => {
    const { formStore } = this.props;

    const payload = {
      ...toJS(this.form),
      deliveryList: this.deliveryList
    };

    try {
      await formStore.updateForm(payload);
      this.addFormIsVisible = false;
    } catch (e) {
      handleError(e);
    }
  };

  handleCancelUpdateForm = () => {
    this.addFormIsVisible = false;
  };

  // <script src="//cdn.cadia.io/client.latest.js"></script>
  renderInfoFormDialog() {
    const api = `${window.location.origin}/api/public/forms/${this.infoFormItem.token}`;
    return (
      <Modal
        closable
        width={820}
        visible={this.infoFormIsVisible}
        title={null}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOnCloseIsVisible}
          >
            OK
          </Button>
        ]}
        onOk={this.handleOnCloseIsVisible}
      >
        <ApiInfo api={api} type="POST" />
      </Modal>
    );
  }

  renderAddFormDialog() {
    const { t } = this.props;
    return (
      <Modal
        width={1020}
        visible={this.addFormIsVisible}
        title={null}
        closable={false}
        footer={[
          <Button
            icon="rollback"
            key="back"
            onClick={this.handleCancelUpdateForm}
          >
            {t('cancel')}
          </Button>,
          this.form && this.form.id ? (
            <Button
              key="submit"
              type="primary"
              icon="save"
              disabled={!this.isDirty || this.isEditing}
              loading={this.addFormIsLoading}
              onClick={this.handleUpdateForm}
            >
              {t('update')}
            </Button>
          ) : (
            <Button
              key="submit"
              type="primary"
              disabled={!this.isDirty}
              loading={this.addFormIsLoading}
              onClick={this.handleOk}
            >
              {t('create')}
            </Button>
          )
        ]}
      >
        <FormBuilder
          onEdit={isEditing => (this.isEditing = isEditing)}
          onChange={form => {
            this.form = form;
            this.diff();
          }}
          value={this.form}
        />

        <Collapse bordered={false} className={style.modalItem}>
          <Panel
            className={style.panel}
            header={t('Advanced parameters')}
            key="1"
          >
            <DeliveryEditor
              value={this.deliveryList}
              onChange={this.handleOnChangeDeliveryList}
            />
          </Panel>
        </Collapse>
      </Modal>
    );
  }

  renderEmptyList() {
    const { t } = this.props;
    return (
      <div className={style.emptyList}>
        <div className={style.emptyList__title}>{t('Form list is empty')}</div>
        <div className={style.emptyList__description}>
          {t('Add your first form')}
        </div>
      </div>
    );
  }

  renderItems() {
    const { formStore, t, match } = this.props;
    const projectId = get(match, 'params.projectId');

    return (
      <List
        itemLayout="vertical"
        size="large"
        dataSource={formStore.items.values()}
        renderItem={item => (
          <List.Item
            key={item.id}
            actions={[
              <IconText
                onClick={e => this.handleInfoForm(item, e)}
                type="api"
                text={t('API')}
              />,
              <IconText
                onClick={e => this.handleEditForm(item, e)}
                type="edit"
                text={t('edit')}
              />,
              <IconText
                onClick={e => this.handleDeleteForm(item, e)}
                type="delete"
                text={t('delete')}
              />
            ]}
            extra={null}
          >
            <Link to={routes.to(routes.formsResult, projectId, item.id)}>
              <List.Item.Meta
                className={style.item}
                title={item.name || item.id}
                description=""
              />
            </Link>
            {item.content}
          </List.Item>
        )}
      />
    );
  }

  render() {
    const { formStore, match, mainStore } = this.props;
    const { t } = this.props;
    const projectId = get(match, 'params.projectId');
    const project = mainStore.projectsStore.get(projectId);
    const role = project.role;

    return (
      <Layout>
        <Header className={style.header}>
          <div className={style.header__title}>{t('forms')}</div>
          <Divider type="vertical" />
          {RIGHT.FORM_CAN_BE_CREATE.includes(role) && (
            <Button onClick={this.handleShowAddForm} type="primary">
              {t('create form')}
            </Button>
          )}
        </Header>
        <Content className={style.content}>
          <div className={style.contentBody}>
            {formStore.items.size === 0
              ? this.renderEmptyList()
              : this.renderItems()}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
        {this.renderAddFormDialog()}
        {this.renderInfoFormDialog()}
      </Layout>
    );
  }
}
