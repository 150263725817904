import axios from 'axios';

const httpClient = axios.create({});
const _API = '/api/';

// eslint-disable-next-line import/prefer-default-export
export const getOAuth2ProviderByName = async providerName => {
  const response = await httpClient.get(
    `${_API}/auth/oauth2/provider/${providerName}`
  );
  return response.data.value;
};
