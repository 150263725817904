import React from 'react';
import { Icon, Tooltip } from 'antd';
import EmptyButton from '../EmptyButton';

const IconText = ({ type, theme, text, tooltip, ...rest }) => {
  const btn = (
    <EmptyButton theme={theme} {...rest}>
      <Icon type={type} theme={theme} style={{ marginRight: 8 }} />
      {text}
    </EmptyButton>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{btn}</Tooltip>;
  }
  return btn;
};

export default IconText;
