import React from 'react';
import { Redirect, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Button } from 'antd';
import { observable } from 'mobx';
import { onPatch } from 'mobx-state-tree';
import classNames from 'classnames';
import routes from '../../constants/routes';
import style from './style.module.scss';

const FormItem = Form.Item;

@withRouter
@inject('auth', 't')
@observer
class RecoveryAccess extends React.Component {
  @observable isSuccess = false;

  @observable isLoading = false;

  @observable hasError = false;

  @observable errorMessage = '';

  @observable login = '';

  @observable password = '';

  @observable confirmPassword = '';

  componentDidMount() {
    const { auth } = this.props;
    const { signUpForm } = auth;
    this.disposer = onPatch(signUpForm, () => (this.hasError = false));
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
      this.disposer = undefined;
    }
  }

  hasToken = () => {
    const { match } = this.props;
    return !!match.params.token;
  };

  handleOnEnterLogin = event => {
    this.login = event.target.value;
  };

  handleOnEnterPassword = event => {
    this.password = event.target.value;
  };

  handleOnEnterConfirmPassword = event => {
    this.confirmPassword = event.target.value;
  };

  handleOnClickResetPassword = async () => {
    const { auth, match } = this.props;
    this.isLoading = true;
    const result = await auth.resetPassword({
      token: match.params.token,
      newPassword: this.password,
      confirmPassword: this.confirmPassword
    });
    this.isLoading = false;
    if (!Object.prototype.hasOwnProperty.call(result, 'errors')) {
      this.isSuccess = true;
    } else {
      console.log(result.errors);
      this.hasError = true;
      this.errorMessage = result.error.reason || result.errors;
    }
  };

  handleOnClickRecoverAccess = async () => {
    const { auth } = this.props;
    this.isLoading = true;
    const result = await auth.recoverAccess(this.login);
    this.isLoading = false;
    if (!Object.prototype.hasOwnProperty.call(result, 'errors')) {
      this.isSuccess = true;
    } else {
      console.log(result.error);
      this.hasError = true;
      this.errorMessage = result.errors;
    }
  };

  // вообще есть MobxReactForm, возможно стоит использовать его
  // но какие-то сомнения, похоже на оверинжиниринг
  renderLoginForm() {
    const { t } = this.props;
    return [
      <FormItem key="renderLoginForm_item_0">
        <Input
          type="email"
          onChange={this.handleOnEnterLogin}
          value={this.login}
          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={t('Email')}
          autoComplete="email"
        />
      </FormItem>,
      <FormItem key="renderLoginForm_item_1">
        <div className={style.row}>
          <Button
            loading={this.isLoading}
            onClick={this.handleOnClickRecoverAccess}
            type="primary"
            htmlType="button"
            className="login-form-button"
          >
            {t('recover access')}
          </Button>
          <div>
            {t('Or')} <Link to={routes.signIn}>{t('Sign in')}</Link>
          </div>
        </div>
      </FormItem>
    ];
  }

  renderNewPasswordForm() {
    const { t } = this.props;
    return [
      <FormItem key="renderNewPasswordForm_0">
        <Input
          autoComplete="new-password"
          onChange={this.handleOnEnterPassword}
          value={this.password}
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder={t('new password')}
        />
      </FormItem>,
      <FormItem key="renderNewPasswordForm_1">
        <Input
          autoComplete="new-password"
          onChange={this.handleOnEnterConfirmPassword}
          value={this.confirmPassword}
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder={t('Confirm password')}
        />
      </FormItem>,
      <FormItem key="renderNewPasswordForm_2">
        <div className={style.row}>
          <div />
          <Button
            loading={this.isLoading}
            disabled={
              this.password.length === 0 || this.confirmPassword.length === 0
            }
            onClick={this.handleOnClickResetPassword}
            type="primary"
            htmlType="button"
            className="login-form-button"
          >
            {t('change password')}
          </Button>
        </div>
      </FormItem>
    ];
  }

  renderSuccessRecoveryMessage() {
    const { t } = this.props;
    return (
      <div className={style.formWrapper}>
        <FormItem>
          <h3>{t('Recovery access')}</h3>
        </FormItem>
        <Form
          className={classNames('login-form', style.form, style.form__message)}
        >
          <FormItem>
            {t('You success change password. Now you can to')}{' '}
            <Link to={routes.signIn}>{t('sign in')}</Link>
          </FormItem>
        </Form>
      </div>
    );
  }

  renderNextStepMessage() {
    const { t } = this.props;
    return (
      <div className={style.formWrapper}>
        <FormItem>
          <h3>{t('Recovery access')}</h3>
        </FormItem>
        <Form
          className={classNames('login-form', style.form, style.form__message)}
        >
          <FormItem>
            {t('We sent a recovery link. Please, check your email')}
          </FormItem>
        </Form>
      </div>
    );
  }

  render() {
    const { t, auth } = this.props;
    const { isAuthenticated } = auth;

    if (isAuthenticated()) {
      return <Redirect to={routes.home} />;
    }

    if (this.isSuccess && this.hasToken()) {
      return this.renderSuccessRecoveryMessage();
    }

    if (this.isSuccess && !this.hasToken()) {
      return this.renderNextStepMessage();
    }

    return (
      <div className={style.formWrapper}>
        <Form className={classNames('login-form', style.form)}>
          <FormItem className={style.form__message}>
            <h3>{t('Recovery access')}</h3>
          </FormItem>
          {this.hasToken()
            ? this.renderNewPasswordForm()
            : this.renderLoginForm()}
          {this.hasError && (
            <FormItem className={style.form__errors}>
              <p>{t(this.errorMessage)}</p>
            </FormItem>
          )}
        </Form>
      </div>
    );
  }
}

export default RecoveryAccess;
