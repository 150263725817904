import React from 'react';
import { Icon, List } from 'antd';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router';
import IconText from '../../components/IconText';
import style from './style.module.scss';

@withRouter
@inject('t', 'match')
class SingleItem extends React.Component {
  render() {
    const { item, t, onClickInfo, onClickEdit } = this.props;

    return (
      <List.Item
        key={item.id}
        className={style.list__item}
        actions={[
          <IconText
            onClick={e => onClickInfo(item, item.dataItem, e)}
            type="api"
            text={t('API')}
          />,
          <IconText
            onClick={e => onClickEdit(item, e)}
            type="edit"
            text={t('edit')}
          />
        ]}
      >
        <List.Item.Meta
          className={style.list__item__meta}
          avatar={<Icon type={item.icon} className={style.list__item__icon} />}
          title={`${item.title} ${item.dataItem.value.title}`}
          description={item.dataItem.value.description}
        />
      </List.Item>
    );
  }
}

export default SingleItem;
