import { types, flow } from 'mobx-state-tree';
import axios from 'axios';
import qs from 'qs';
import { xahMapToObj } from './utils';
import { handleError } from '../utils';
import urls from '../constants/urls';
import TemplateFieldModel from './templateField';
import FieldModel from './field';

const FormTemplate = types.model('FormTemplate', {
  id: types.identifier,
  name: types.maybe(types.string),
  fields: types.array(TemplateFieldModel)
});

// eslint-disable-next-line no-unused-vars
const ResultForm = types
  .model('ResultForm', {
    id: types.identifier,
    data: types.map(types.string),
    createdAt: types.Date,
    updatedAt: types.Date
  })
  .volatile(() => ({
    checked: false
  }))
  .preProcessSnapshot(({ createdAt, updatedAt, ...rest } = {}) => ({
    createdAt: Date.parse(createdAt),
    updatedAt: Date.parse(updatedAt),
    ...rest
  }))
  .actions(self => ({
    check(value) {
      self.checked = value;
    }
  }))
  .views(self => ({
    get dataSource() {
      return {
        result: self,
        id: self.id,
        createdAt: self.createdAt,
        updatedAt: self.updatedAt,
        check: self.check,
        checked: self.checked,
        ...xahMapToObj(self.data)
      };
    }
  }));

const DeliveryHandler = types
  .model('DeliveryHandler', {
    name: '',
    transport: types.enumeration(['sms', 'telegram', 'email', 'whatsapp']),
    destinations: types.array(types.string)
  })
  .preProcessSnapshot(({ _id, ...rest }) => ({
    id: _id,
    ...rest
  }));

const FormHandler = types
  .model('FormHandler', {
    id: types.identifier,
    name: types.maybe(types.string),
    description: types.maybe(types.string),
    token: types.string,
    schema: types.array(FieldModel),
    deliveryList: types.array(DeliveryHandler),
    createdAt: types.Date,
    updatedAt: types.Date
  })
  .preProcessSnapshot(({ _id, id, createdAt, updatedAt, ...rest } = {}) => ({
    id: _id || id,
    createdAt: Date.parse(createdAt),
    updatedAt: Date.parse(updatedAt),
    ...rest
  }))
  .views(self => ({
    get selectedResult() {
      return self.result.filter(r => r.checked);
    },
    get isAllSelectedResult() {
      return false; // self.result.every(r => r.checked);
    }
  }))
  .actions(self => ({
    fetchResult: flow(function*({ projectId, limit, offset, filters, sorter }) {
      const queryParams = { limit, offset, filters, sorter };
      const stringified = qs.stringify(queryParams, {
        arrayFormat: 'repeat',
        addQueryPrefix: true
      });
      try {
        const { data } = yield axios.get(
          `${urls.formsResult(projectId, self.id)}${stringified}`
        );
        return data;
      } catch (e) {
        handleError(e);
      }
    }),
    fetchTotal: flow(function*({ projectId }) {
      try {
        const { data } = yield axios.get(
          `${urls.formsResult(projectId, self.id)}/total`
        );
        return data;
      } catch (e) {
        handleError(e);
        return {
          total: 0
        };
      }
    }),
    deleteDataRecordById: flow(function*(projectId, id) {
      const { status } = yield axios.delete(
        `${urls.formsResult(projectId, self.id)}/${id}`
      );
      return status === 204;
    })
  }));

export default types
  .model('FormStore', {
    items: types.map(FormHandler),
    templates: types.map(FormTemplate)
  })
  .volatile(() => ({
    projectId: ''
  }))
  .views(() => ({}))
  .actions(self => ({
    fetch: flow(function*(projectId) {
      try {
        const { data } = yield axios.get(urls.forms(projectId));
        self.projectId = projectId;
        self.items = data.reduce((accumulator, currentValue) => {
          accumulator[currentValue.id] = currentValue;
          return accumulator;
        }, {});
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    reset() {
      self.items = {};
      self.templates = {};
    },
    read: flow(function*(id) {
      try {
        const { data } = yield axios.get(`${urls.forms(self.projectId)}/${id}`);
        self.items[data.id] = data;
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    addForm: flow(function*(params) {
      try {
        const { data } = yield axios.post(urls.forms(self.projectId), params);
        self.items.set(data.id, data);
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    updateForm: flow(function*(params) {
      try {
        const { data } = yield axios.put(
          `${urls.forms(self.projectId)}/${params.id}`,
          params
        );
        const form = self.items.get(data.id);
        if (self.items.has(data.id)) {
          self.items.set(data.id, data);
        }
        // Object.keys(data).forEach(key => {
        //   form[key] = data[key];
        // });
        console.log('____self.items[]', form);
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    deleteForm: flow(function*(id) {
      try {
        const form = self.items.get(id);
        const { data } = yield axios.delete(
          `${urls.forms(self.projectId)}/${form.id}`
        );
        console.log('data', data);
        self.items.delete(id);
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    fetchTemplates: flow(function*() {
      try {
        const { data } = yield axios.get(urls.formsTemplate(self.projectId));
        self.templates = data.reduce((accumulator, currentValue) => {
          accumulator[currentValue.id] = currentValue;
          return accumulator;
        }, {});
        return true;
      } catch (e) {
        handleError(e);
      }
    }),
    saveTemplate: flow(function*(params) {
      try {
        const { data } = yield axios.post(
          urls.formsTemplate(self.projectId),
          params
        );
        self.templates.set(data.id, data);
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    updateTemplate: flow(function*(params) {
      try {
        const { data } = yield axios.put(
          `${urls.formsTemplate(self.projectId)}/${params.id}`,
          {
            body: params
          }
        );
        self.items[data.id] = data;
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    deleteTemplate: flow(function*(id) {
      try {
        const form = self.items.get(id);
        yield axios.delete(`${urls.formsTemplate(self.projectId)}/${form.id}`);
        self.items.delete(id);
        return self.items;
      } catch (e) {
        handleError(e);
      }
    })
  }));
