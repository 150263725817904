import { inject, observer } from 'mobx-react';
import React from 'react';
import { Select } from 'antd';
import lodash from 'lodash';

export default
@inject('i18n', 't')
@observer
class LanguageSelector extends React.Component {
  getDefault(value) {
    if (value.includes('ru-')) {
      return 'ru';
    }

    if (value.includes('en-')) {
      return 'en';
    }

    return value;
  }

  handleLanguageChange = value => {
    const { i18n, onChange } = this.props;
    i18n.changeLanguage(value);
    if (lodash.isFunction(onChange)) {
      onChange(value);
    }
  };

  render() {
    const { i18n, ...rest } = this.props;
    const defaultValue = this.getDefault(i18n.language);

    return (
      <Select
        size="small"
        defaultValue={defaultValue}
        {...rest}
        onChange={this.handleLanguageChange}
      >
        <Select.Option key="en" value="en">
          English
        </Select.Option>
        <Select.Option key="ru" value="ru">
          Русский
        </Select.Option>
      </Select>
    );
  }
}
