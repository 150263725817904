import React from 'react';
import classNames from 'classnames';
import {withRouter} from "react-router";
import { Link } from 'react-router-dom';
import {Button} from 'antd';
import style from '../404/style.module.scss';


export default withRouter(({match, location}) => {
  const params = new URLSearchParams(location.search);
  const message = params.get('message');
  return (
    <div className={style.page}>
      <svg dangerouslySetInnerHTML={{
        __html: `
          <defs>
                  <path id="four" d="M90 90 L10 90 L70 10 L 70 110"
                  ></path>
                  <ellipse id="zero" rx="40" ry="45" cx="50" cy="50"></ellipse>
                  <path id="three" d="M 15.728208,11.422171 82.336371,11.396684 23.98495,44.89372 c 1.331252,-0.334887 15.780401,
                  -8.51175 33.239261,-6.509921 5.53234,0.680202 11.29344,1.97021 15.895072,4.946862 4.128616,2.670672 7.230655,
                  6.755431 9.529549,10.951113 1.417285,2.586668 1.976673,5.547855 2.441298,8.426592 0.570787,3.536502 0.833557,
                  7.171006 0.402709,10.72511 -0.466382,3.847223 -1.449462,7.715905 -3.203079,11.21687 -0.893874,1.784552 -2.216504,
                  3.378433 -3.645485,4.819931 -1.90302,1.919686 -4.084853,3.630883 -6.463698,4.99878 -2.871801,1.651359 -6.016777,
                  2.940937 -9.260827,3.777564 -3.003868,0.774684 -6.155531,1.029693 -9.268577,1.103948 C 49.9514,99.43882 46.211174,
                  99.238102 42.579531,98.565401 38.886948,97.881411 35.294066,96.692796 31.837691,95.288744 28.930097,
                  94.107618 26.219188,92.5272 23.505228,90.989588 21.488075,89.846756 17.605271,87.333531 17.605271,87.333531"
                  ></path>
                  <linearGradient id="stripes"
                                  x1="0" y1="0" x2="100%" y2="0%">
                      <stop stop-color="hsl(45,100%,65%)" offset="0"></stop>
                      <stop stop-color="hsl(320,100%,65%)" offset="50%"></stop>
                      <stop stop-color="hsl(200,100%,60%)" offset="100%"></stop>
                  </linearGradient>
              </defs>

      `
      }}/>
      <div className={style.positionBlock}>
        <div className={style.wrapper}>
          <ul className={classNames(style.list, style.listDashArrayAnim)}>
            <li className={style.list__item}>
              <svg
                viewBox="0 0 100 102"
                dangerouslySetInnerHTML={{
                  __html: `
                      <use xlink:href="#four" stroke="#2f54eb" stroke-width="1"></use>
                      <use xlink:href="#four" stroke="url(#stripes)" stroke-width="2" stroke-dasharray="500"></use>
                  `
                }}
              />
            </li>

            <li className={style.list__item}>
              <svg viewBox="0 0 100 102" dangerouslySetInnerHTML={{
                __html: `
                            <use xlink:href="#zero" stroke="#2f54eb" stroke-width="1"></use>
                <use xlink:href="#zero" stroke="url(#stripes)" stroke-width="2" stroke-dasharray="500">
                </use>
              `
              }}/>
            </li>

            <li className={style.list__item}>
              <svg viewBox="0 0 100 102" dangerouslySetInnerHTML={{
                __html: `
                  <use xlink:href="#three" stroke="#2f54eb" stroke-width="1"></use>
                  <use xlink:href="#three" stroke="url(#stripes)" stroke-width="2" stroke-dasharray="500"></use>
              `
              }}/>
            </li>
          </ul>
          <div className={style.info}>
            <p className={style.info__title}>FORBIDDEN</p>
            <Link to="/">
              <Button type="primary" className={style.info__btn} ghost icon="home">Home</Button>
            </Link>
            <p className={style.info__text}>{message && message}</p>
          </div>
        </div>
      </div>
    </div>
  );
});
