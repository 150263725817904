import { types } from 'mobx-state-tree';

const UserModel = types
  .model('User', {
    id: types.identifier,
    status: types.maybe(types.string),
    email: types.string,
    phone: types.maybe(types.string)
  })
  .preProcessSnapshot(({ _id, ...rest }) => {
    return {
      id: _id || rest.id,
      ...rest
    };
  })
  .views(self => ({
    get description() {
      if (self.phone && self.phone.length > 0) {
        return `${self.email} - ${self.phone}`;
      }
      return self.email;
    }
  }));

export default UserModel;
