const msgPage = '/msg';
const signIn = '/sign-in';
const signUp = '/sign-up';
const recoveryAccess = '/recovery-access/:token?';
const verifyAccount = '/verify-account/:msg?';
const dashboard = '/:projectId/dashboard';
const summary = '/:projectId/summary';
const awaitPayment = '/:projectId/await/:uuid';
const content = '/:projectId/content';

const page = '/:projectId/pages/:pageId';
const pageRecord = '/:projectId/pages/:pageId/data/:dataId';
const pages = '/:projectId/pages';

const data = '/:projectId/data';
const dataRecords = '/:projectId/data/:modelId';
const dataRecord = '/:projectId/data/:modelId/data/:recordId';
const models = '/:projectId/models';
const model = '/:projectId/models/:modelId';

const forms = '/:projectId/forms';
const formsResult = '/:projectId/forms/:formId/result';

const projects = '/projects';
const files = '/projects/:projectId/files';
const hooks = '/projects/:projectId/hooks';
const profile = '/profile';
const templatesForm = '/templates/form';
const templatesModel = '/templates/model';

const home = projects;

export default {
  awaitPayment,
  summary,
  signIn,
  msgPage,
  signUp,
  pageRecord,
  recoveryAccess,
  verifyAccount,
  pages,
  content,
  data,
  page,
  dataRecords,
  dataRecord,
  models,
  model,
  files,
  hooks,
  forms,
  formsResult,
  home,
  dashboard,
  projects,
  profile,
  templatesForm,
  templatesModel,
  to: (path, ...values) => {
    return path
      .split('/')
      .map(it => {
        if (it[0] === ':') {
          if (values.length === 0) {
            throw new Error(
              `IllegalArgumentException. Value for ${it} is missing `
            );
          }
          return values.shift();
        }
        return it;
      })
      .join('/');
  }
};
