import { inject, observer } from 'mobx-react';
import React from 'react';
import { Icon, Select } from 'antd';
import { observable } from 'mobx';

import style from '../FormFieldEditor/style.module.scss';

const { Option } = Select;

export default
@inject('i18n', 't', 'mainStore')
@observer
class ModelSelector extends React.Component {
  @observable isLoading = true;

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { mainStore } = this.props;
    const { selectedProject } = mainStore;
    await selectedProject.fetchDataModels();
    this.isLoading = false;
  };

  render() {
    const { i18n, t, value, mainStore, onChange, ...rest } = this.props;
    const { dataModels } = mainStore.selectedProject;

    return (
      <Select
        {...rest}
        loading={this.isLoading}
        className={style.selector}
        onChange={onChange}
        value={value && value.relationship && value.relationship.modelId}
      >
        {!this.isLoading &&
          Array.from(dataModels.values()).map(dataModel => (
            <Option
              key={dataModel.id}
              value={dataModel.id}
              className={style.select__item}
            >
              <div className={style.select__item__withIcon}>
                <Icon
                  style={{ fontSize: '20px', marginRight: '12px' }}
                  type={dataModel.icon}
                />
                <span className={style.flu}>{dataModel.title}</span>
              </div>
            </Option>
          ))}
      </Select>
    );
  }
}
