import { inject, observer } from 'mobx-react';
import React from 'react';
import lodash from 'lodash';
import { Button, Divider, Icon, List, Modal, Select, Spin } from 'antd';
import classNames from 'classnames';

import { observable } from 'mobx';

import { RELATION_TYPE } from '../DeclarativeForm/constants';
import style from '../FormFieldEditor/style.module.scss';
import s from './style.module.scss';

const { Option } = Select;

export default
@inject('i18n', 't', 'mainStore')
@observer
class DataItemSelector extends React.Component {
  @observable isLoading = true;

  @observable dataItems = [];

  @observable isVisibleAddDialog = false;

  @observable selectValue = null;

  model = null;

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { modelId } = this.props;
    if (prevProps.modelId !== modelId) {
      this.fetch();
    }
  }

  fetch = async () => {
    const { mainStore, modelId } = this.props;
    const { selectedProject } = mainStore;
    this.isLoading = true;
    this.dataItems = [];
    this.model = selectedProject.dataModels.get(modelId);
    if (!this.model) {
      await selectedProject.fetchDataModel(modelId);
      this.model = selectedProject.dataModels.get(modelId);
    }
    this.dataItems = await this.model.fetchEntries({});
    this.isLoading = false;
  };

  handleShowModalAddDialog = () => {
    const { ediatable } = this.props;
    if (!ediatable) return;
    this.isVisibleAddDialog = true;
  };

  handleDeleteItem = item => {
    const { value, onChange, ediatable } = this.props;
    if (!ediatable) return;
    onChange(
      value.filter(ItemId => {
        return ItemId !== item.id;
      })
    );
  };

  handleOnOkAddDialog = () => {
    const { value, onChange } = this.props;
    const _value = lodash.isArray(value) ? value : [];
    onChange([..._value, this.selectValue]);
    this.isVisibleAddDialog = false;
    this.selectValue = null;
  };

  handleOnCancelAddDialog = () => {
    this.isVisibleAddDialog = false;
    this.selectValue = null;
  };

  handleOnSelectOneElement = item => {
    this.selectValue = item;
  };

  fmt(dataItem) {
    return (dataItem && dataItem.value && dataItem.value.title) || dataItem.id;
  }

  renderSelector() {
    const { i18n, t, value, mainStore, onChange, ...rest } = this.props;

    return (
      <Select
        {...rest}
        loading={this.isLoading}
        className={style.selector}
        onChange={onChange}
        value={value}
      >
        {!this.isLoading &&
          Array.from(this.dataItems).map(dataItem => (
            <Option
              key={dataItem.id}
              value={dataItem.id}
              className={style.select__item}
            >
              <div className={style.select__item__withIcon}>
                <span className={style.flu}>{this.fmt(dataItem)}</span>
              </div>
            </Option>
          ))}
      </Select>
    );
  }

  renderMultiSelector() {
    const { i18n, t, value, mainStore, onChange, ...rest } = this.props;
    const _value = lodash.isArray(value) ? value : [];
    if (this.isLoading) {
      return <Spin size="large" />;
    }
    return (
      <div className={classNames(classNames, s.list)}>
        <List
          size="small"
          bordered
          dataSource={this.dataItems.filter(item => _value.includes(item.id))}
          renderItem={item => this.renderItem(item)}
          {...rest}
          footer={
            <div className={s.footer}>
              {[
                <Button
                  key="plus_item"
                  onClick={this.handleShowModalAddDialog}
                  className={s.handler}
                  type="primary"
                  shape="circle"
                  icon="plus"
                  size="small"
                />,
                <Modal
                  key="modal"
                  title={null}
                  visible={this.isVisibleAddDialog}
                  closable
                  onOk={this.handleOnOkAddDialog}
                  onCancel={this.handleOnCancelAddDialog}
                  okButtonProps={{ disabled: this.selectValue === null }}
                  {...rest}
                >
                  <Select
                    {...rest}
                    loading={this.isLoading}
                    className={style.selector}
                    onChange={this.handleOnSelectOneElement}
                    value={this.selectValue}
                  >
                    {!this.isLoading &&
                      Array.from(this.dataItems).map(dataItem => (
                        <Option
                          key={dataItem.id}
                          value={dataItem.id}
                          className={style.select__item}
                        >
                          <div className={style.select__item__withIcon}>
                            <span className={style.flu}>
                              {this.fmt(dataItem)}
                            </span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Modal>
              ]}
            </div>
          }
        />
        <div className={s.footer} />
      </div>
    );
  }

  renderItem(item) {
    return (
      <List.Item key={item.id} className={s.itemW}>
        <div className={s.item}>
          <div className={s.item__content}>{this.fmt(item)}</div>
          <div className={s.controls}>
            <Divider type="vertical" />
            <Icon
              type="delete"
              className={s.handler}
              onClick={() => this.handleDeleteItem(item)}
            />
          </div>
        </div>
      </List.Item>
    );
  }

  render() {
    const { type } = this.props;
    console.log('render dataitem selector', type);
    if (type === RELATION_TYPE.MANY) {
      return this.renderMultiSelector();
    }
    return this.renderSelector();
  }
}
