import React from 'react';
import { List, Button, Icon, Divider } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import style from './style.module.scss';
import EditableTitle from '../EditableTitle';

@observer
class OptionEditorList extends React.Component {
  handleAddItem = () => {
    const { onChange, value } = this.props;
    const val = { ...value };
    val.value = 'label';
    if (onChange) {
      onChange(val);
    }
  };

  onRemoveItem = item => {
    const { onChange, value } = this.props;
    const val = value || {};
    if (item.value && val[item.value]) {
      delete val[item.value];
    }

    if (onChange) {
      onChange(val);
    }
  };

  onChangeKey = (key, newKey) => {
    const { onChange, value } = this.props;
    const val = toJS(value || {});
    const valByKey = val[key];
    delete val[key];
    val[newKey] = valByKey;

    if (onChange) {
      onChange(val);
    }
  };

  onChangeValue = (key, newValue) => {
    const { onChange, value } = this.props;
    const val = value || {};
    val[key] = newValue;
    if (onChange) {
      onChange(val);
    }
  };

  renderItem(item) {
    return (
      <List.Item key={item.value} className={style.itemW}>
        <div className={style.item}>
          <div className={style.item__content}>
            <div className={style.item__content__item}>
              <EditableTitle
                className={style.item__input}
                copy={false}
                value={toJS(item.value)}
                onChange={newValue => this.onChangeKey(item.value, newValue)}
              />
            </div>
            <div className={style.item__content__item}>
              <EditableTitle
                className={style.item__input}
                copy={false}
                value={toJS(item.label)}
                onChange={newValue => this.onChangeValue(item.value, newValue)}
              />
            </div>
          </div>
          <div className={style.controls}>
            <Divider type="vertical" />
            <Icon
              onClick={() => this.onRemoveItem(item)}
              type="delete"
              className={style.handler}
            />
          </div>
        </div>
      </List.Item>
    );
  }

  render() {
    const { editable, className, value, ...rest } = this.props;

    const items = Object.keys(toJS(value || {})).map(key => ({
      value: key,
      label: value[key]
    }));

    console.log('OptionEditorList items', items);

    return (
      <div className={classNames(className, style.clist)} {...rest}>
        <List
          size="small"
          bordered
          dataSource={items}
          renderItem={item => this.renderItem(item)}
          footer={
            <div className={style.footer}>
              <Button
                onClick={this.handleAddItem}
                className={style.handler}
                type="primary"
                shape="circle"
                icon="plus"
                size="small"
              />
            </div>
          }
        />
        <div className={style.footer} />
      </div>
    );
  }
}

OptionEditorList.defaultProps = {
  value: {},
  onChange: () => {}
};

export default OptionEditorList;
