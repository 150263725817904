import React from 'react';
import {
  Button,
  Input,
  Select,
  Modal,
  Dropdown,
  Icon,
  Menu,
  Table,
  Divider,
  Tag
} from 'antd';

import { Observer, observer, inject } from 'mobx-react';
import { observable, toJS, computed, autorun } from 'mobx';
import Validator from 'validatorjs';
import IconText from '../IconText';
import InputRule from '../InputRule';
import HelpTooltip from '../IconTooltip';
import EditableTitle from '../EditableTitle';
import { PROPERTY_TYPE, VIEW_KIND } from '../DeclarativeForm/constants';
import style from './style.module.scss';
import { handleError } from '../../utils';

const rules = [
  'required',
  'email',
  'alpha',
  'numeric',
  'string',
  'between:min,max',
  'max:value',
  'min:value'
];

function getRulesByType(type) {
  if (type === FILED_TYPE.FILE) {
    return ['required'];
  }
  if (type === FILED_TYPE.STRING) {
    return rules;
  }
  return [];
}

const FILED_TYPE = Object.freeze({
  FILE: PROPERTY_TYPE.FILE,
  STRING: PROPERTY_TYPE.STRING
});

const formRules = {
  name: 'required|max:25|alpha_dash',
  type: 'required|in:FILE,STRING'
};

function getColorByType(type) {
  if (type === 'file') {
    return 'geekblue';
  }
  if (type === 'string') {
    return 'blue';
  }
  return 'cyan';
}

export default
@inject('formStore', 't')
@observer
class FormBuilder extends React.Component {
  @observable selectedTemplateId = null;

  @observable valueFormView = {};

  @observable showModalTemplateSave = false;

  @observable showModalTemplateLoad = false;

  @observable valueFormValue = {};

  @observable editableItem = {};

  @observable columns = [
    {
      // eslint-disable-next-line react/destructuring-assignment
      title: this.props.t('Type'),
      key: 'type',
      width: 128,
      dataIndex: 'type',
      render: (type, record) => {
        return (
          <Observer
            render={() =>
              this.editableItem &&
              this.editableItem.__oldName === record.name ? (
                <Select
                  value={this.editableItem.type}
                  size="small"
                  optionLabelProp="children"
                  className={style.inputSelector}
                  onChange={this.handleOnChangeType}
                >
                  {this.fieldTypes}
                </Select>
              ) : (
                <Tag color={getColorByType(type)}>{type}</Tag>
              )
            }
          />
        );
      }
    },
    {
      // eslint-disable-next-line react/destructuring-assignment
      title: () => (
        <div>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {this.props.t('Field name')}
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <HelpTooltip title={this.props.t('help.form_field_rules')} />
        </div>
      ),
      dataIndex: 'name',
      width: 144,
      key: 'name',
      render: (text, record) => {
        return (
          <Observer
            render={() =>
              this.editableItem &&
              this.editableItem.__oldName === record.name ? (
                <Input
                  size="small"
                  type="text"
                  value={this.editableItem.name}
                  onChange={e => (this.editableItem.name = e.target.value)}
                />
              ) : (
                text
              )
            }
          />
        );
      }
    },
    {
      // eslint-disable-next-line react/destructuring-assignment
      title: this.props.t('Validation`s rules'),
      key: 'rules',
      dataIndex: 'rules',
      render: (mRules, record) => (
        <Observer
          render={() =>
            this.editableItem && this.editableItem.__oldName === record.name ? (
              <InputRule
                items={getRulesByType(this.editableItem.type)}
                onChange={this.handleChangeRuleList}
                value={toJS(this.editableItem.rules)}
              />
            ) : (
              mRules.map(tag => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              ))
            )
          }
        />
      )
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      width: 224,
      render: (text, record) => {
        const { t } = this.props;
        return (
          <Observer
            render={() =>
              this.editableItem &&
              this.editableItem.__oldName === record.name ? (
                <span>
                  <IconText
                    type="save"
                    text={t('save')}
                    disabled={
                      this.editableItem.name &&
                      this.editableItem.name.length === 0
                    }
                    onClick={() => this.handleOnSave(record)}
                  />
                  <Divider type="vertical" />
                  <IconText
                    type="cancel"
                    text={t('cancel')}
                    onClick={() => this.handleOnCancel()}
                  />
                </span>
              ) : (
                <span>
                  <IconText
                    type="edit"
                    text={t('edit')}
                    onClick={() => this.handleOnEdit(record)}
                  />
                  <Divider type="vertical" />
                  <IconText
                    type="delete"
                    text={t('delete')}
                    onClick={() => this.handleOnDelete(record)}
                  />
                </span>
              )
            }
          />
        );
      }
    }
  ];

  @observable schema = [];

  @observable formName = '';

  @observable valueTemplateName = '';

  fieldTypes = [
    <Select.Option key="string" value={FILED_TYPE.STRING}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {this.props.t('String')}
    </Select.Option>,
    <Select.Option key="file" value={FILED_TYPE.FILE}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {this.props.t('File')}
    </Select.Option>
  ];

  constructor(props) {
    super(props);
    if (props && props.value) {
      this.schema = props.value.schema || [];
      this.formName = props.value.name || '';
      this.id = props.value.id;
    }
  }

  // eslint-disable-next-line camelcase,no-unused-vars
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps && nextProps.value) {
      if (nextProps.value.schema !== this.schema) {
        this.schema = nextProps.value.schema || [];
      }
      if (nextProps.value.name !== this.formName) {
        this.formName = nextProps.value.name || '';
      }
      if (nextProps.value.id !== this.id) {
        this.id = nextProps.value.id || undefined;
      }
    }
  }

  @computed get isDisabled() {
    return !!this.editableItem && Object.keys(this.editableItem).length > 0;
  }

  // eslint-disable-next-line react/sort-comp
  disposer = autorun(() => {
    const { onEdit } = this.props;
    if (onEdit) {
      onEdit(this.isDisabled);
    }
  });

  handleChangeRuleList = nextValue => {
    this.editableItem.rules = nextValue;
  };

  handleOnSaveNewTemplate = () => {
    const { formStore } = this.props;

    formStore.saveTemplate({
      name: this.valueTemplateName,
      schema: this.schema
    });

    this.handleOnCancelSaveNewTemplate();
  };

  handleOnCancelSaveNewTemplate = () => {
    this.showModalTemplateSave = false;
    this.valueTemplateName = '';
  };

  handleOnSelectTemplate = value => {
    this.selectedTemplateId = value;
  };

  handleOnChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({
        id: this.id,
        name: this.formName,
        schema: this.schema
      });
    }
  };

  handleOnSave = () => {
    const { t } = this.props;
    const data = toJS(this.editableItem);

    const v = new Validator(data, formRules);
    console.log('data', data);
    console.log('formRules', formRules);

    if (v.passes()) {
      const fieldName = data.__oldName;
      delete data.__oldName;
      const index = this.schema.findIndex(
        userProp => userProp.name === fieldName
      );
      if (index === -1) {
        this.schema.push(data);
      } else {
        this.schema[index] = data;
      }

      const editableItemIndex = this.schema.findIndex(
        userProp => userProp.name === ''
      );
      if (editableItemIndex !== -1) {
        this.schema.splice(editableItemIndex, 1);
      }
      this.editableItem = null;
      this.handleOnChange();
    } else {
      const err = {};
      Object.keys(v.errors.errors).forEach(key => {
        err[t(key)] = v.errors.errors[key];
      });
      handleError(err);
    }
  };

  handleOnCancel = () => {
    if (this.editableItem.__oldName === '') {
      const editableItemIndex = this.schema.findIndex(
        userProp => userProp.name === ''
      );
      if (editableItemIndex !== -1) {
        this.schema.splice(editableItemIndex, 1);
      }
    }
    this.editableItem = null;
  };

  handleOnAddNewField = () => {
    const maxOrder = this.schema.length
      ? // eslint-disable-next-line prefer-spread
        Math.max.apply(
          Math,
          this.schema.map(function(o) {
            return o.order;
          })
        )
      : -1;

    this.schema.push({
      name: '',
      order: maxOrder + 1,
      type: FILED_TYPE.STRING,
      rules: []
    });
    this.editableItem = {
      __oldName: '',
      name: '',
      order: maxOrder + 1,
      type: FILED_TYPE.STRING,
      view: { kind: VIEW_KIND.TEXT },
      rules: []
    };
  };

  handleOnEdit = item => {
    this.editableItem = {
      ...toJS(item),
      __oldName: item.name
    };
  };

  handleOnDelete = item => {
    const index = this.schema.findIndex(
      userProp => userProp.name === item.name
    );
    this.schema.splice(index, 1);
    this.handleOnChange();
  };

  handleOnChangeType = nextValue => {
    this.editableItem.type = nextValue;
    this.editableItem.view.kind =
      nextValue === PROPERTY_TYPE.FILE ? VIEW_KIND.UPLOAD : VIEW_KIND.TEXT;
    this.editableItem.rules = [];
  };

  handleOnShowModalTemplateLoad = () => {
    this.showModalTemplateLoad = true;
  };

  handleOnShowModalTemplateSave = () => (this.showModalTemplateSave = true);

  handleOnChangeFormName = v => {
    this.formName = v;
    this.handleOnChange();
  };

  handleOnApplyTemplate = () => {
    const { formStore } = this.props;
    const template = formStore.templates.get(this.selectedTemplateId);
    template.fields.forEach(item => {
      const tField = [...toJS(item)];
      this.schema = tField;
    });

    this.showModalTemplateLoad = false;
    this.selectedTemplateId = null;
  };

  handleOnCancelApplyTemplate = () => {
    this.showModalTemplateLoad = false;
    this.selectedTemplateId = null;
  };

  handleOnInputTemplateName = event =>
    (this.valueTemplateName = event.target.value);

  renderMenu() {
    const { t } = this.props;
    return (
      <Menu>
        <Menu.Item key="load">
          <Button
            className={style.likeLink}
            htmlType="button"
            onClick={this.handleOnShowModalTemplateLoad}
          >
            {t('Load')}
          </Button>
        </Menu.Item>
        <Menu.Item key="save">
          <Button
            className={style.likeLink}
            htmlType="button"
            onClick={this.handleOnShowModalTemplateSave}
          >
            {t('save')}
          </Button>
        </Menu.Item>
      </Menu>
    );
  }

  renderDDTemplate() {
    const { t } = this.props;
    return (
      <Dropdown overlay={this.renderMenu()}>
        <Button type="dashed">
          {t('template')} <Icon type="down" />
        </Button>
      </Dropdown>
    );
  }

  render() {
    const { formStore, t } = this.props;
    console.log('this.schema', this.schema);
    const dataSource = toJS(this.schema)
      .map(element => ({
        ...toJS(element),
        name: element.name
      }))
      .sort((a, b) => a.order - b.order);

    return (
      <div className={style.formBuilder}>
        <div className={style.toolPanel}>
          <EditableTitle
            value={this.formName}
            onChange={this.handleOnChangeFormName}
          />
          <div className={style.buttonGroup}>
            {/* {this.renderDDTemplate()} */}
            <Button
              type="primary"
              disabled={this.isDisabled}
              icon="plus-square"
              onClick={this.handleOnAddNewField}
            >
              {t('add a new field')}
            </Button>
            <Modal
              closable={false}
              onOk={this.handleOnApplyTemplate}
              onCancel={this.handleOnCancelApplyTemplate}
              okText="Apply"
              okButtonProps={{ disabled: this.selectedTemplateId === null }}
              cancelText="Cancel"
              visible={this.showModalTemplateLoad}
            >
              <div className={style.template__row}>
                {t('You can select template')}:
              </div>
              <Select
                className={style.inputSelector}
                value={this.selectedTemplateId}
                onChange={this.handleOnSelectTemplate}
              >
                {Array.from(formStore.templates.values()).map(template => (
                  <Select.Option key={template.id} value={template.id}>
                    {template.name}
                  </Select.Option>
                ))}
              </Select>
              <div className={style.template__row}>
                {t('Template will override fields by matching names')}
              </div>
            </Modal>
            <Observer
              render={() => {
                return (
                  <Modal
                    onOk={this.handleOnSaveNewTemplate}
                    onCancel={this.handleOnCancelSaveNewTemplate}
                    title={null}
                    okText={t('save')}
                    okButtonProps={{
                      disabled: this.valueTemplateName.length === 0
                    }}
                    closable={false}
                    visible={this.showModalTemplateSave}
                  >
                    <Input
                      type="text"
                      onChange={this.handleOnInputTemplateName}
                      placeholder={t('Enter template name')}
                      value={this.valueTemplateName}
                    />
                  </Modal>
                );
              }}
            />
          </div>
        </div>
        <Table
          rowKey="name"
          scroll={{ y: 440 }}
          columns={this.columns}
          dataSource={dataSource}
          pagination={false}
          style={{ overflowY: 'auto' }}
        />
      </div>
    );
  }
}
