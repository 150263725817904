import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import { toJS } from 'mobx';
import urls from '../constants/urls';

const Hook = types.model('Hook', {
  id: types.identifier,
  createdAt: types.Date,
  lastAction: types.Date,
  name: types.string,
  status: types.string,
  statusDescription: types.string,
  isDisabled: types.boolean,
  action: types.string,
  url: types.string,
  method: types.string,
  contentType: types.string,
  body: types.string
});

const FixedHook = types.snapshotProcessor(Hook, {
  // from snapshot to instance
  preProcessor({ createdAt, updatedAt, lastAction, ...backendItem }) {
    return {
      ...backendItem,
      createdAt: Date.parse(createdAt),
      lastAction: Date.parse(lastAction)
    };
  }
});

export default types
  .model('HookStore', {
    items: types.map(FixedHook)
  })
  .actions(self => ({
    add(item) {
      self.items.set(item.id, item);
    },
    remove: flow(function*(projectId, hookId) {
      yield axios.delete(`${urls.projects}/${projectId}/hooks/${hookId}`);
      self.items.delete(hookId);
      return self.items;
    }),
    fetchAll: flow(function*(projectId) {
      const { data } = yield axios.get(`${urls.projects}/${projectId}/hooks`);
      self.items = (data || []).reduce((accumulator, currentValue) => {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
      return self.items;
    }),
    create: flow(function*(projectId, payload) {
      const { data } = yield axios.post(
        `${urls.projects}/${projectId}/hooks`,
        payload
      );
      self.items.set(data.id, data);
      return self.items.get(data.id);
    }),
    update: flow(function*(projectId, hookId, payload) {
      const { data } = yield axios.put(
        `${urls.projects}/${projectId}/hooks/${hookId}`,
        payload
      );
      self.items.set(data.id, data);
      return self.items.get(data.id);
    }),
    handleEnableHook: flow(function*(projectId, hookId) {
      const item = toJS(self.items.get(hookId));
      item.isDisabled = false;
      const { data } = yield axios.put(
        `${urls.projects}/${projectId}/hooks/${hookId}`,
        item
      );
      self.items.set(data.id, data);
      return self.items.get(data.id);
    }),
    handleDisableHook: flow(function*(projectId, hookId) {
      const item = toJS(self.items.get(hookId));
      item.isDisabled = true;
      const { data } = yield axios.put(
        `${urls.projects}/${projectId}/hooks/${hookId}`,
        item
      );
      self.items.set(data.id, data);
      return self.items.get(data.id);
    })
  }));
