import React from 'react';
import { Alert, Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import style from './style.module.scss';

const ApiInfo = ({ type, api, theme, text, className, tooltip, ...rest }) => {
  let alertType = 'success';

  if (type.toUpperCase() === 'GET') {
    alertType = 'success';
  } else if (type.toUpperCase() === 'POST') {
    alertType = 'info';
  } else if (type.toUpperCase() === 'PUT') {
    alertType = 'warning';
  } else if (type.toUpperCase() === 'DELETE') {
    alertType = 'error';
  }

  const btn = (
    <div
      className={classNames(style.apiInfo, className)}
      theme={theme}
      {...rest}
    >
      <Alert
        message={
          <div className={style.api_content}>
            <div className={classNames(style.api, style[alertType])}>
              {type.toUpperCase()}
            </div>
            <div className={style.apiUrl}>{api}</div>
          </div>
        }
        type={alertType}
      />
      <CopyToClipboard text={api}>
        <Button className={style.handler} icon="copy" />
      </CopyToClipboard>
    </div>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{btn}</Tooltip>;
  }
  return btn;
};

export default ApiInfo;
