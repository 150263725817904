import React from 'react';
import { Icon, Tooltip } from 'antd';

export default ({
  title,
  className,
  iconName = 'question-circle',
  ...rest
}) => (
  <Tooltip title={title}>
    <Icon
      style={{ marginRight: '8px', marginLeft: '8px' }}
      type={iconName}
      {...rest}
    />
  </Tooltip>
);
