import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { inject, observer } from 'mobx-react';
import get from 'lodash/get';
import routes from '../../constants/routes';
import style from './Layouts.module.scss';
import { USER_ROLE } from '../../constants';

const { Sider } = Layout;
export default
@inject('mainStore', 'auth', 't')
@withRouter
@observer
class ColumnLayout extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    collapsed: localStorage.getItem('SiderIsCollapsed') === 'true' || false
  };

  handleLogout = () => {
    const { auth, history } = this.props;
    history.go(routes.home);
    auth.logout().then(() => {
      history.go(routes.signIn);
    });
  };

  onCollapse = collapsed => {
    localStorage.setItem('SiderIsCollapsed', collapsed);
    this.setState({ collapsed });
  };

  render() {
    const { mainStore, match, t } = this.props;
    const projectId =
      get(match, 'params.projectId') || get(mainStore, 'selectedProject.id');

    const project = projectId && mainStore.getProjectById(projectId);

    // const projectId = get(match, 'params.projectId');
    // const project = mainStore.projectsStore.get(projectId);
    const { collapsed } = this.state;
    return (
      <Layout theme="light" style={{ minHeight: '100vh' }}>
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
        >
          {/* <div className={style.columnLayout__Header}> */}
          {/*  */}
          {/* </div> */}

          {/* <MenuProjectSelector collapsed={collapsed} /> */}

          {project && (
            <div className={style.menuTopTitle}>
              {projectId && project.title}
            </div>
          )}
          <div className={style.menuWrapper}>
            <Menu
              mode="inline"
              selectedKeys={[match.url]}
              defaultOpenKeys={[`subMenu_${match.url}`]}
            >
              <Menu.Item
                key={routes.to(
                  routes.summary,
                  mainStore.selectedProject && mainStore.selectedProject.id
                )}
                disabled={!mainStore.selectedProject}
              >
                <Link
                  to={routes.to(
                    routes.summary,
                    mainStore.selectedProject && mainStore.selectedProject.id
                  )}
                >
                  <Icon type="pie-chart" />
                  <span className={style.menuItemName}>{t('summary')}</span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={routes.to(
                  routes.forms,
                  mainStore.selectedProject && mainStore.selectedProject.id
                )}
                disabled={!mainStore.selectedProject}
              >
                <Link
                  to={routes.to(
                    routes.forms,
                    mainStore.selectedProject && mainStore.selectedProject.id
                  )}
                >
                  <Icon type="form" />
                  <span className={style.menuItemName}>{t('forms')}</span>
                </Link>
              </Menu.Item>

              {/*
              <Menu.Item
                key={routes.to(
                  routes.pages,
                  mainStore.selectedProject && mainStore.selectedProject.id
                )}
                disabled={!mainStore.selectedProject}
              >
                <Link
                  to={routes.to(
                    routes.pages,
                    mainStore.selectedProject && mainStore.selectedProject.id
                  )}
                >
                  <Icon type="copy" />
                  <span>{t('pages')}</span>
                </Link>
              </Menu.Item>
              */}

              <Menu.Item
                key={routes.to(
                  routes.data,
                  mainStore.selectedProject && mainStore.selectedProject.id
                )}
                disabled={!mainStore.selectedProject}
              >
                <Link
                  to={routes.to(
                    routes.data,
                    mainStore.selectedProject && mainStore.selectedProject.id
                  )}
                >
                  <Icon type="database" />
                  <span>{t('data')}</span>
                </Link>
              </Menu.Item>

              {mainStore.selectedProject &&
              (mainStore.selectedProject.role === USER_ROLE.DEVELOPER ||
                mainStore.selectedProject.role === USER_ROLE.OWNER) ? (
                <Menu.Item
                  key={routes.to(
                    routes.models,
                    mainStore.selectedProject && mainStore.selectedProject.id
                  )}
                  disabled={!mainStore.selectedProject}
                >
                  <Link
                    to={routes.to(
                      routes.models,
                      mainStore.selectedProject && mainStore.selectedProject.id
                    )}
                  >
                    <Icon type="build" />
                    <span>{t('models')}</span>
                  </Link>
                </Menu.Item>
              ) : null}

              <Menu.Item
                key={routes.to(
                  routes.hooks,
                  mainStore.selectedProject && mainStore.selectedProject.id
                )}
                disabled={!mainStore.selectedProject}
              >
                <Link
                  to={routes.to(
                    routes.hooks,
                    mainStore.selectedProject && mainStore.selectedProject.id
                  )}
                >
                  <div>
                    <Icon type="api" />
                    <span className={style.menuItemName}>{t('Hooks')}</span>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={routes.to(
                  routes.files,
                  mainStore.selectedProject && mainStore.selectedProject.id
                )}
                disabled={!mainStore.selectedProject}
              >
                <Link
                  to={routes.to(
                    routes.files,
                    mainStore.selectedProject && mainStore.selectedProject.id
                  )}
                >
                  <div>
                    <Icon type="hdd" />
                    <span className={style.menuItemName}>{t('Files')}</span>
                  </div>
                </Link>
              </Menu.Item>

              {/* <SubMenu */}
              {/* key={"subMenu_" + [routes.templatesForm, routes.templatesModel].find(it => it === match.url)} */}
              {/* title={ */}
              {/* <span> */}
              {/* <Icon type="build"/> */}
              {/* <span>Templates</span> */}
              {/* </span> */}
              {/* }> */}
              {/* <Menu.Item key={routes.templatesForm}> */}
              {/* <Link to={routes.templatesForm}> */}
              {/* <Icon type="form"/> */}
              {/* <span>Form</span> */}
              {/* </Link> */}
              {/* </Menu.Item> */}

              {/* <Menu.Item key={routes.templatesModel}> */}
              {/* <Link to={routes.templatesModel}> */}
              {/* <Icon type="rollback"/> */}
              {/* <span>Model</span> */}
              {/* </Link> */}
              {/* </Menu.Item> */}
              {/* </SubMenu> */}
            </Menu>

            <Menu
              mode="inline"
              selectedKeys={[match.url]}
              defaultOpenKeys={[`subMenu_${match.url}`]}
            >
              <Menu.Item key={routes.projects}>
                <Link to={routes.projects}>
                  <Icon type="layout" theme="outlined" />
                  <span className={style.menuItemName}>
                    {t('projects_list')}
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key={routes.profile}>
                <Link to={routes.profile}>
                  <Icon type="user" />
                  <span>{t('profile')}</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="signOut" onClick={this.handleLogout}>
                <Icon type="logout" theme="outlined" />
                <span className={style.menuItemName}>{t('sign_out')}</span>
              </Menu.Item>
            </Menu>
          </div>
        </Sider>
        {this.props.children}
      </Layout>
    );
  }
}
