import React from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';
import style from './style.module.scss';

const LoaderShim = ({ className, title, onClick, children, ...rest }) => (
  <div className={classNames(style.wrapper, className)} {...rest}>
    <div className={style.content}>
      <div className={style.title}>{title}</div>
      <div className={style.spacer} />
      <Spin size="large" />
    </div>
  </div>
);

export default LoaderShim;
