import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';
import { Button, Icon, Tooltip, Select, Modal, Spin } from 'antd';
import { observable, toJS } from 'mobx';
import style from './style.module.scss';

export default
@inject('i18n', 't', 'mainStore')
@observer
class Pricing extends React.Component {
  @observable currency = localStorage.getItem('currency') || 'RUB';

  @observable currencies = { RUB: '₽', EUR: '€', USD: '$' };

  // eslint-disable-next-line react/destructuring-assignment
  getFraction(curr) {
    if (curr === 'RUB') return 0;
    if (curr === 'EUR') return 0;
    return 2;
  }

  getData() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.pricing;
  }

  allFeatures = () => {
    const allFeatures = {};
    this.getData().forEach(plan => {
      Object.keys(plan.counters).forEach(featureName => {
        allFeatures[featureName] = plan.counters[featureName];
      });
      Object.keys(plan.flags).forEach(featureName => {
        allFeatures[featureName] = plan.flags[featureName];
      });

      Object.keys(plan.staticValues).forEach(featureName => {
        allFeatures[featureName] = plan.staticValues[featureName];
      });
    });
    return allFeatures;
  };

  handleChange = value => {
    this.currency = value;
    localStorage.setItem('currency', value);
  };

  renderHeader() {
    const { t, currencyRates } = this.props;
    const rate = currencyRates[`EUR/${this.currency}`];
    const fraction = this.getFraction(this.currency);

    return (
      <div className={style.header}>
        <div
          key="title"
          className={classNames(style.header__cell__h1, style.principal)}
        >
          {t('choose_you_plan')}
        </div>
        {this.getData().map(plan => {
          return (
            <div key={plan.id} className={style.header__cell}>
              <div className={style.header__cell__title}>{plan.name}</div>
              <div className={style.header__cell__subtitle}>
                {t(`plan_${plan.name}_subtitle`)}
              </div>
              <div
                className={classNames(
                  style.header__begin_col,
                  style[`plan_has_color_${plan.name.toLowerCase()}`]
                )}
              >
                <div className={style.header__cell__h1_centered}>
                  {(plan.price * rate).toFixed(fraction)}{' '}
                  {this.currencies[this.currency]}
                </div>
                <div className={style.header__cell__subtitle}>
                  {moment.duration(1, 'months').format('M __')}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => {
                      /* eslint-disable-next-line react/destructuring-assignment */
                      this.props.onChoose(plan, this.currency);
                    }}
                    type="link"
                  >
                    {t('Get started')}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderValue(plan, key) {
    console.log('renderValue', plan, key);

    // eslint-disable-next-line no-prototype-builtins
    if (plan.counters.hasOwnProperty(key)) {
      const value = plan.counters[key];
      return (
        <div
          key={`${plan.id}_${key}`}
          className={classNames(
            style.body_col,
            style[`plan_has_color_${plan.name.toLowerCase()}`]
          )}
        >
          <div className={style.bodyValue}>{value}</div>
        </div>
      );
    }

    // eslint-disable-next-line no-prototype-builtins
    if (plan.staticValues.hasOwnProperty(key)) {
      const value = plan.staticValues[key];

      return (
        <div
          key={`${plan.id}_${key}`}
          className={classNames(
            style.body_col,
            style[`plan_has_color_${plan.name.toLowerCase()}`]
          )}
        >
          <div className={style.bodyValue}>{value}</div>
        </div>
      );
    }

    // eslint-disable-next-line no-prototype-builtins
    if (plan.flags.hasOwnProperty(key)) {
      const isAvailable = plan.staticValues[key];
      if (isAvailable) {
        return <Icon key={`${plan.id}_${key}`} type="check" />;
      }

      return <Icon key={`${plan.id}_${key}`} type="close" />;
    }

    return (
      <div
        key={`${plan.id}_${key}`}
        className={classNames(
          style.body_col,
          style[`plan_has_color_${plan.name.toLowerCase()}`]
        )}
      >
        <div className={style.bodyValue}>n/a</div>
      </div>
    );
  }

  renderContent() {
    const { t } = this.props;

    return (
      <div className={style.pricingWrapper}>
        {this.renderHeader()}
        <div className={style.body}>
          {Object.keys(this.allFeatures()).map(key => (
            <div key={`row_${key}`} className={style.row}>
              <div className={classNames(style.principal, style.middle)}>
                <Tooltip title={t(`tooltip.${key}`)}>
                  <span className={style.cell_principal}>{t(key)}</span>
                </Tooltip>
              </div>
              {this.getData().map(plan => this.renderValue(plan, key))}
            </div>
          ))}
          <div className={style.rowLast}>
            <div className={classNames(style.principal, style.middle)}>
              <span className={style.cell_principal} />
            </div>
            {this.getData().map(plan => {
              return (
                <div
                  key={plan.id}
                  className={classNames(
                    style.end_col,
                    style[`plan_has_color_${plan.name.toLowerCase()}`]
                  )}
                >
                  <div className={style.bodyValue} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t, isLoading, overviewTPisVisible, onCancel } = this.props;
    return (
      <Modal
        wrapClassName={style.modalWrapFullScreen}
        className={style.modalFullScreen}
        width="100%"
        onClick={event => event.stopPropagation()}
        visible={overviewTPisVisible}
        onCancel={onCancel}
        closable
        footer={null}
      >
        <div className={style.modalFullScreenContentWrapper}>
          {!isLoading && (
            <div className={style.preheader}>
              <Select
                defaultValue={this.currency}
                style={{ width: 120 }}
                onChange={this.handleChange}
              >
                {Object.keys(toJS(this.currencies)).map(curName => (
                  <Select.Option value={curName}>
                    {this.currencies[curName]} {t(curName)}{' '}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
          <div className={style.pricing}>
            {isLoading ? <Spin size="large" /> : this.renderContent()}
          </div>
        </div>
      </Modal>
    );
  }
}
