import React from 'react';
import { Layout, Button } from 'antd';
import { inject, observer } from 'mobx-react';
import style from './style.module.scss';
import { footerLabel } from '../../components/DeclarativeForm/constants';

const { Header, Content, Footer } = Layout;
export default
@inject('mainStore', 'formStore', 'auth')
@observer
class TemplatesFormPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header
          className={style.header}
          style={{ background: '#fff', paddingLeft: 24, paddingRight: 24 }}
        >
          <div>
            <Button onClick={this.handleShowAddForm} type="primary">
              Add form
            </Button>
          </div>
          <div />
        </Header>
        <Content className={style.content}>
          <div className={style.contentBody}>templates</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          {footerLabel}
        </Footer>
      </Layout>
    );
  }
}
