import React from 'react';
import { Layout, Button, Divider, Modal } from 'antd';
import { Link, Prompt, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import routes from '../../constants/routes';
import { handleError } from '../../utils';
import DeclarativeForm from '../../components/DeclarativeForm';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import stylePage from '../pages.module.scss';
import style from './style.module.scss';
import ApiInfo from '../../components/ApiInfo';

const { Header, Content, Footer } = Layout;

export default
@inject('auth', 't', 'mainStore')
@withRouter
@observer
class DataEditor extends React.Component {
  @observable data = {
    value: {}
  };

  @observable apiInfoIsVisible = false;

  @observable isLoading = false;

  @observable isDirty = false;

  origin = {};

  componentDidMount() {
    const { data } = this.props;
    this.data = data;
    this.origin = toJS(this.data);
  }

  onChange = value => {
    this.data.value = value;
    this.updateDiff();
  };

  updateDiff = () => {
    this.isDirty = !isEqual(this.origin, toJS(this.data));
  };

  handleOnSaveModel = async () => {
    const { history, match, mainStore, model } = this.props;
    const dataId = get(match, 'params.recordId', 'new');

    this.isLoading = true;

    try {
      const savedData = await model.saveEntry(toJS(this.data));
      if (dataId === 'new') {
        this.isDirty = false;
        history.push(
          routes.to(
            routes.dataRecord,
            mainStore.selectedProject.id,
            model.id,
            savedData.id
          )
        );
      } else {
        this.data = savedData;
        this.origin = toJS(this.data);
        this.updateDiff();
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  };

  renderModalApiInfo() {
    const { t, model } = this.props;
    return (
      <Modal
        width="720px"
        onClick={event => event.stopPropagation()}
        title={t('API')}
        visible={this.apiInfoIsVisible}
        onCancel={() => {
          this.apiInfoIsVisible = false;
        }}
        closable
        footer={null}
      >
        <div className={style.apiItem}>
          {t('Get this item')}
          <ApiInfo
            api={`${window.location.origin}/api/public/models/${model.token}/data/${this.data.id}`}
            type="GET"
          />
        </div>
      </Modal>
    );
  }

  render() {
    console.log('data_editor_render', toJS(this.data.value));
    const { t, model, mainStore } = this.props;
    const { selectedProject } = mainStore;

    return (
      <Layout>
        <Prompt
          when={this.isDirty}
          message={t(
            'You have unsaved changes. Are you sure you want to leave?'
          )}
        />
        {this.renderModalApiInfo()}
        <Header className={stylePage.header}>
          <Link
            to={routes.to(routes.dataRecords, selectedProject.id, model.id)}
          >
            <Button shape="circle" icon="arrow-left" />
          </Link>

          <Divider type="vertical" />

          <div className={style.header__title}>{t('data_editor')}</div>

          <Divider type="vertical" />
          <Button
            className={classNames(
              stylePage.header__item__info,
              stylePage.header__item
            )}
            icon="api"
            type="primary"
            disabled={this.isLoading || this.data.id === 'new'}
            onClick={() => (this.apiInfoIsVisible = true)}
          >
            {t('API')}
          </Button>

          <Button
            htmlType="button"
            className={stylePage.header__item}
            type="primary"
            loading={this.isLoading}
            disabled={this.isLoading || !this.isDirty}
            onClick={this.handleOnSaveModel}
            icon="save"
          >
            {t('save')}
          </Button>
        </Header>
        <Content className={stylePage.content}>
          <div className={stylePage.contentBody}>
            <DeclarativeForm
              view={toJS(model.schema)}
              value={this.data.value}
              onChange={this.onChange}
            />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
      </Layout>
    );
  }
}
