import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import gitlabLogo from './assets/gitlab-logo.svg';
import googleLogo from './assets/google-logo.svg';
import githubLogo from './assets/github-logo.svg';
import style from './style.module.scss';
import LogoButton from './components/button';
// eslint-disable-next-line import/named
import { getOAuth2ProviderByName } from '../../api';
import { handleError } from '../../utils';

export default
@inject('i18n', 't', 'mainStore', 'appStore')
@withRouter
@observer
class OAuth extends React.Component {
  singUpByOAuth2 = async providerName => {
    const { appStore } = this.props;
    try {
      appStore.isLoading(true);
      window.location = await getOAuth2ProviderByName(providerName);
    } catch (e) {
      handleError(e, () => {
        this.singUpByOAuth2(providerName);
      });
      appStore.isLoading(false);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className={style.column}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={style.label}>{t('Sign in with')}</label>
        <div className={style.row}>
          <LogoButton
            onClick={() => {
              this.singUpByOAuth2('google');
            }}
            logo={googleLogo}
          />

          <div className={style.spacer_w16} />

          <LogoButton
            onClick={() => {
              this.singUpByOAuth2('gitlab');
            }}
            logo={gitlabLogo}
          />

          <div className={style.spacer_w16} />

          <LogoButton
            onClick={() => {
              this.singUpByOAuth2('github');
            }}
            logo={githubLogo}
          />
        </div>
      </div>
    );
  }
}
