import React from 'react';
import { Spin, ConfigProvider } from 'antd';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import Validator from 'validatorjs';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from 'react-router-dom';
import get from 'lodash/get';
import ruRu from 'antd/lib/locale-provider/ru_RU';
import enUS from 'antd/lib/locale-provider/en_US';
import moment from 'moment';
import ExtRoute from '../ExtRoute';
import { SimpleLayout, ColumnLayout } from '../Layouts';
import SignIn from '../../pages/SignIn';
import SignUp from '../../pages/SignUp';
import Hooks from '../../pages/Hooks';
import VerifyAccount from '../../pages/VerifyAccount';
import RecoveryAccess from '../../pages/RecoveryAccess';
import TemplatesForm from '../../pages/TemplatesForm';
import TemplatesModel from '../../pages/TemplatesModel';
import Forms from '../../pages/Forms';
import FormResult from '../../pages/FormResult';
import ModelEditor from '../../pages/ModelEditor';
import DataPage from '../../pages/DataPage';
import DataExplorer from '../../pages/DataExplorer';
import DataEditor from '../../pages/DataEditor';
import Pages from '../../pages/Pages';
import PageEditor from '../../pages/PageEditor';
import Page404 from '../../pages/404';
import Await from '../../pages/await';
import Models from '../../pages/Models';
import Page403 from '../../pages/403';
import Summary from '../../pages/Summary';
import Profile from '../../pages/Profile';
import Projects from '../../pages/Projects';
import FIleExplorer from '../../pages/FIleExplorer';
import routes from '../../constants/routes';
import Auth from '../../models/Auth';
import HooksStore from '../../models/HooksStore';
import FileStore from '../../models/FileStore';
import FormStore from '../../models/FormStore';
import MainStore from '../../models/MainStore';
import AppStore from '../../models/AppStore';
import style from './App.module.css';
import LoaderShim from '../LoaderShim';
import ErrorPage from '../../pages/Error';

@observer
class App extends React.Component {
  @observable hasDone = false;

  constructor(props) {
    super(props);
    const auth = Auth.create();
    const appStore = AppStore.create({});
    const filesStore = FileStore.create({ items: [] });
    const hooksStore = HooksStore.create({ items: {} });
    const env = {};
    const mainStore = MainStore.create({}, env);
    const formStore = FormStore.create({}, env);
    const { emitter, i18n, t } = props;
    window.t = t;

    i18n.on('missingKey', lngs => {
      console.error('lngs', lngs);
    });

    i18n.on('missingKey', err => console.error(err));
    i18n.on('languageChanged', () => {
      Validator.useLang(i18n.language);
      moment.locale(i18n.language);
      this.forceUpdate();
    });

    this.stores = {
      appStore,
      hooksStore,
      filesStore,
      t,
      i18n,
      emitter,
      auth,
      mainStore,
      formStore
    };

    Object.keys(this.stores).forEach(key => {
      env[key] = this.stores[key];
    });

    window.store = this.stores;

    auth.onAuth(async () => {
      console.log('auth.language', auth.language);
      console.log('i18n.language', i18n.language);
      console.log('moment.language', moment.locale());
      if (auth.language !== i18n.language) {
        await i18n.changeLanguage(auth.language);
      }

      this.hasDone = false;
      await mainStore.fetch();
      this.hasDone = true;
    });

    auth.onLogout(() => {
      mainStore.reset();
      formStore.reset();
    });

    auth
      .check()
      .then(async () => {
        console.log('auth.language', auth.language);
        console.log('i18n.language', i18n.language);
        console.log('moment.language', moment.locale());
        if (auth.language !== i18n.language) {
          await i18n.changeLanguage(auth.language);
        }
        await mainStore.fetch();
        // await sleep(500);
        this.hasDone = true;
      })
      .catch(e => {
        console.warn(e);
        this.hasDone = true;
      });
  }

  formLoader = async () => {
    const { mainStore, formStore } = this.stores;
    if (mainStore.selectedProject) {
      await formStore.fetch(mainStore.selectedProject.id);
      await formStore.fetchTemplates();
    }
  };

  summaryLoader = async ({ match }) => {
    const { mainStore } = this.stores;
    const projectId = get(match, 'params.projectId');
    const project = mainStore.getProjectById(projectId);
    if (project) {
      try {
        const data = await project.fetchSummary();
        return {
          data
        };
      } catch (e) {
        return { data: null };
      }
    }
    return { data: null };
  };

  dataLoader = async ({ match }) => {
    const { mainStore } = this.stores;
    const id = get(match, 'params.modelId', get(match, 'params.pageId', 'new'));
    const dataId = get(match, 'params.recordId', 'new');
    await this.modelLoader({ match });
    const model = mainStore.selectedProject.dataModels.get(id);
    if (dataId === 'new') {
      return {
        model,
        data: {
          value: {}
        }
      };
    }
    return {
      model,
      data: await model.fetchEntry(dataId)
    };
  };

  // загружает все данные для текущей модели
  dataItemsLoader = async ({ match }) => {
    const { mainStore } = this.stores;
    // id модели
    const id = get(match, 'params.modelId', get(match, 'params.pageId', 'new'));

    // загружаем модель
    await this.modelLoader({ match });

    // получаем модель из сторы, она загрузилась туда выше
    const model = mainStore.selectedProject.dataModels.get(id);

    return {
      model
    };
  };

  modelsLoader = async () => {
    const { mainStore } = this.stores;
    if (mainStore.selectedProject) {
      await mainStore.selectedProject.fetchDataModels();
      // await formStore.fetch(mainStore.selectedProject.id);
      // await formStore.fetchTemplates();
    }
  };

  allPagesLoader = async () => {
    const { mainStore } = this.stores;

    if (mainStore.selectedProject) {
      await mainStore.selectedProject.fetchDataModels();

      await Promise.all(
        mainStore.selectedProject.pages.map(async dataModel => {
          if (dataModel.meta.isSingleton) {
            return dataModel.fetchEntries({ compact: true }).then(result => {
              return result[0];
            });
          }
          return Promise.resolve();
        })
      );
    }
  };

  pageLoader = async ({ match, history }) => {
    const { mainStore } = this.stores;

    if (mainStore.selectedProject) {
      await mainStore.selectedProject.fetchDataModels();
      await this.modelLoader({ match });

      const modelId = get(match, 'params.pageId', 'new');
      const dataId = get(match, 'params.dataId', 'new');

      if (modelId === 'new') {
        return {};
      }
      const model = mainStore.selectedProject.dataModels.get(modelId);
      let dataRecord = { value: {} };

      if (!model) {
        history.push('/404');
        return;
      }
      //  на одной странице сосдержится и модель и данные
      // если это не коллекция данных, то загружаем единственную запись
      if (model.meta.isSingleton) {
        const allDataRecords = await model.fetchEntries({});
        console.log('allDataRecords loader', allDataRecords);

        if (allDataRecords.length > 0) {
          dataRecord = allDataRecords[0];
          if (allDataRecords.length > 1) {
            console.warn('Entries has more than 1');
          }
        }
      } else if (dataId !== 'new') {
        dataRecord = await model.fetchEntry(dataId);
      } else {
        dataRecord = { id: dataId, value: {} };
      }

      return {
        model,
        data: dataRecord
      };
    }

    return {};
  };

  modelLoader = async ({ match }) => {
    const id = get(match, 'params.modelId', 'new');
    const { mainStore } = this.stores;
    if (id !== 'new' && mainStore.selectedProject) {
      await mainStore.selectedProject.fetchDataModel(id);
    }

    return {
      data: {}
    };
  };

  dataPageLoader = async ({ match, history }) => {
    const { mainStore } = this.stores;
    const projectId = get(match, 'params.projectId');

    if (projectId) {
      const project = await mainStore.projectsStore.get(projectId);
      if (!project) {
        history.push('/404');
        return;
      }
      await project.fetchDataModels();
      // await formStore.fetch(mainStore.selectedProject.id);
      // await formStore.fetchTemplates();
    }
  };

  // TODO Заменить бы на загрузку самой формы
  beforeFormResultLoader = async () => {
    await this.formLoader();
  };

  render() {
    if (!this.hasDone) {
      return <Spin className={style.spinner} size="large" />;
    }
    const { mainStore, auth, appStore } = this.stores;
    const { i18n } = this.props;
    let locale = enUS;
    if (i18n.language === 'ru') {
      locale = ruRu;
    }

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Provider {...this.stores}>
        {appStore._isLoading && <LoaderShim />}
        <ConfigProvider locale={locale}>
          <Router>
            <Switch>
              <Route
                exact
                from="/"
                render={() => {
                  if (auth.isAuthenticated()) {
                    if (!mainStore.selectedProject) {
                      return <Redirect to={routes.projects} />;
                    }
                    return (
                      <Redirect
                        to={routes.to(
                          routes.home,
                          mainStore.selectedProject.id
                        )}
                      />
                    );
                  }
                  return <Redirect to={routes.signIn} />;
                }}
              />
              <ExtRoute
                layout={SimpleLayout}
                path={routes.signIn}
                component={SignIn}
              />
              <ExtRoute
                layout={SimpleLayout}
                path={routes.msgPage}
                component={ErrorPage}
              />
              <ExtRoute
                layout={SimpleLayout}
                path={routes.verifyAccount}
                component={VerifyAccount}
              />
              <ExtRoute
                layout={SimpleLayout}
                path={routes.recoveryAccess}
                component={RecoveryAccess}
              />
              <ExtRoute
                layout={SimpleLayout}
                path={routes.signUp}
                component={SignUp}
              />
              <ExtRoute
                isPublic={false}
                loader={this.beforeFormResultLoader}
                layout={ColumnLayout}
                path={routes.formsResult}
                component={FormResult}
              />
              <ExtRoute
                isPublic={false}
                loader={this.formLoader}
                layout={ColumnLayout}
                path={routes.forms}
                component={Forms}
              />
              <ExtRoute
                isPublic={false}
                loader={this.pageLoader}
                layout={ColumnLayout}
                path={routes.pageRecord}
                component={PageEditor}
              />
              <ExtRoute
                isPublic={false}
                loader={this.pageLoader}
                layout={ColumnLayout}
                path={routes.page}
                component={PageEditor}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                loader={this.allPagesLoader}
                path={routes.pages}
                component={Pages}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                loader={this.summaryLoader}
                path={routes.summary}
                component={Summary}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                path={routes.profile}
                component={Profile}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                path={routes.files}
                component={FIleExplorer}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                path={routes.hooks}
                component={Hooks}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                path={routes.projects}
                component={Projects}
              />
              <ExtRoute
                isPublic={false}
                loader={this.dataLoader}
                layout={ColumnLayout}
                path={routes.dataRecord}
                component={DataEditor}
              />
              <ExtRoute
                isPublic={false}
                loader={this.dataItemsLoader}
                layout={ColumnLayout}
                path={routes.dataRecords}
                component={DataExplorer}
              />
              <ExtRoute
                isPublic={false}
                loader={this.dataPageLoader}
                layout={ColumnLayout}
                path={routes.data}
                component={DataPage}
              />
              <ExtRoute
                isPublic={false}
                loader={this.modelLoader}
                layout={ColumnLayout}
                path={routes.model}
                component={ModelEditor}
              />
              <ExtRoute
                isPublic={false}
                loader={this.modelsLoader}
                layout={ColumnLayout}
                path={routes.models}
                component={Models}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                path={routes.templatesModel}
                component={TemplatesModel}
              />
              <ExtRoute
                isPublic={false}
                layout={ColumnLayout}
                path={routes.templatesForm}
                component={TemplatesForm}
              />
              <Route path="/403" component={Page403} />
              <Route path="/404" component={Page404} />
              <Route path={routes.awaitPayment} component={Await} />
              <Route component={Page404} />
            </Switch>
          </Router>
        </ConfigProvider>
      </Provider>
    );
  }
}

export default App;
