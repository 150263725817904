import React from 'react';
import style from '../style.module.scss';

export default ({ label, children, description, ...rest }) => (
  <div className={style.dashboard__item} {...rest}>
    <div className={style.dashboard__item__title}>{label}</div>
    <div>{children}</div>
    {description && (
      <div className={style.dashboard__item__description}>{description}</div>
    )}
  </div>
);
