import React from 'react';
import { Redirect, withRouter } from 'react-router';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';

import classNames from 'classnames';
import routes from '../../constants/routes';
import style from './style.module.scss';

const FormItem = Form.Item;

@withRouter
@inject('auth', 't')
@observer
class VerifyAccount extends React.Component {
  getMessage = () => {
    const { location } = this.props;
    const values = queryString.parse(location.search);
    console.log('values.values', values);
    return values.msg || 'miss_verification_result';
  };

  render() {
    const { t, auth } = this.props;
    const { isAuthenticated } = auth;

    if (isAuthenticated()) {
      return <Redirect to={routes.home} />;
    }

    const msg = this.getMessage();
    const isSuccess = msg === 'success';

    return (
      <div className={style.formWrapper}>
        <FormItem>
          <h3>{t('account_activation')}</h3>
        </FormItem>
        <Form
          className={classNames('login-form', style.form, style.form__message)}
        >
          <FormItem>
            {isSuccess && (
              <div>
                {`${t('account_activation_success')} `}
                <div>
                  <Link to={routes.signIn}>{t('Sign in')}</Link>
                </div>
              </div>
            )}
            {!isSuccess && (
              <div>
                <div className={style.form__message}>{t(msg)}</div>
                <Link to={routes.to(routes.recoveryAccess, '')}>
                  {t('Recovery access')}
                </Link>
              </div>
            )}
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default VerifyAccount;
