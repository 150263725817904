import React from 'react';
import { Layout, Button, Card, Icon, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import routes from '../../constants/routes';
import style from './style.module.scss';
import stylePage from '../pages.module.scss';

const { Header, Content } = Layout;

export default
@inject('auth', 't', 'mainStore')
@observer
class ModelPage extends React.Component {
  @observable addFormIsVisible = false;

  @observable selectedFormTemplateId = -1;

  @observable formView = {};

  @observable formValue = {};

  handleShowAddForm = () => {
    // create new Item
    this.addFormIsVisible = true;
    this.addFormIsLoading = false;
  };

  renderEmptyList() {
    const { t } = this.props;
    return (
      <div className={stylePage.emptyList}>
        <div className={stylePage.emptyList__title}>{t('list is empty')}</div>
        <div className={stylePage.emptyList__description}>
          {t('add_your_first_data_model')}
        </div>
      </div>
    );
  }

  renderItems() {
    const { selectedProject } = this.props.mainStore;
    const { dataModels } = selectedProject;

    return (
      <div className={style.cardList}>
        {Array.from(dataModels.values()).map(item => (
          <Link
            key={`${selectedProject.id}_${item.id}`}
            to={routes.to(routes.model, selectedProject.id, item.id)}
          >
            <Card key={item.id} className={style.cardItem}>
              <Icon style={{ fontSize: '48px' }} type={item.icon} />
              <div className={style.cardItem__title}>{item.name}</div>
            </Card>
          </Link>
        ))}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { selectedProject } = this.props.mainStore;

    return (
      <Layout>
        <Header className={stylePage.header}>
          <div className={style.header__title}>{t('models')}</div>
          <Divider type="vertical" />
          <div className={style.header__panel}>
            <Link to={routes.to(routes.model, selectedProject.id, 'new')}>
              <Button type="primary">{t('create new data-model')}</Button>
            </Link>
          </div>
        </Header>
        <Content className={stylePage.content}>
          <div className={stylePage.contentBodyWithoutFooter}>
            {selectedProject.dataModels.size === 0
              ? this.renderEmptyList()
              : this.renderItems()}
          </div>
        </Content>
      </Layout>
    );
  }
}
