import React from 'react';
import { InputNumber } from 'antd';
import inNumber from 'lodash/isNumber';
import classNames from 'classnames';
import style from '../DeclarativeForm/style.module.scss';
import s from './style.module.scss';

export default class BetweenNumberInput extends React.Component {
  handleOnChangeFist = newValue => {
    const { value } = this.props;
    this.handleOnChange({
      to: (value || {}).to,
      from: newValue
    });
  };

  handleOnChangeSecond = newValue => {
    const { value } = this.props;
    this.handleOnChange({
      to: newValue,
      from: (value || {}).from
    });
  };

  handleOnChange = value => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  handleOnBlur = () => {
    const { value } = this.props;
    const { from, to } = value || {};

    if (inNumber(from) && inNumber(to)) {
      if (from > to) {
        this.handleOnChange({
          from
        });
      }
    }
  };

  render() {
    const { className, id, value } = this.props;
    const { from, to } = value || {};
    return (
      <div className={classNames(className, s.minmaxInput)}>
        <InputNumber
          onBlur={this.handleOnBlur}
          value={from}
          onChange={this.handleOnChangeFist}
          className={classNames(style.field__input, style.field_short)}
          id={`${id}_from`}
          placeholder="Min"
        />
        <InputNumber
          onBlur={this.handleOnBlur}
          value={to}
          onChange={this.handleOnChangeSecond}
          className={classNames(
            style.field__input,
            s.minmaxInput__second,
            style.field_short
          )}
          id={`${id}_to`}
          placeholder="Max"
        />
      </div>
    );
  }
}
