import React from 'react';
import { Layout, Button, List, Modal, Select, Divider } from 'antd';
import { observer, inject, Observer } from 'mobx-react';
import { observable } from 'mobx';
import Input from 'antd/es/input/Input';
import style from './style.module.scss';
import IconText from '../../components/IconText';
import { USER_ROLE, RIGHT } from '../../constants';
import FormAddMember from './FormAddMember';
import FormAddNewProject from './FormAddNewProject';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import { sleep } from '../../utils';

const { Header, Content, Footer } = Layout;

export default
@inject('auth', 'mainStore', 't')
@observer
class ProjectsPage extends React.Component {
  instanceOfFormAddNewProject = React.createRef();

  @observable selectedItem = null;

  @observable selectedItemFormChangeOwner = null;

  @observable modalAddNewProjectIsVisible = false;

  @observable modalAddNewProjectIsLoading = false;

  @observable memberItem = null;

  @observable editableProject = null;

  @observable newMembers = [];

  handleOnLeave = project => {
    const { auth, t, mainStore } = this.props;

    const membershipId = Array.from(project.memberships.values()).find(
      membership => membership.user.id === auth.id
    ).id;
    Modal.confirm({
      title: t('Are you sure you want to leave this project?'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        await project.deleteMemberships(membershipId);
        mainStore.removeProjectById(project.id);
        await sleep(500);
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  handleOnOkFormChangeOwner = item => {
    this.selectedItemFormChangeOwner = null;
  };

  handleHideModalMembersList = () => {
    this.selectedItem = null;
  };

  handleShowAddNewProject = () => {
    this.modalAddNewProjectIsVisible = true;
  };

  handleOnCancelFormChangeOwner = () => {
    this.selectedItemFormChangeOwner = null;
  };

  handleShowFormMembers = item => {
    this.selectedItem = item;
  };

  handleShowFormChangeOwner = item => {
    this.selectedItemFormChangeOwner = item;
  };

  handleDeleteMember = member => {
    const { t } = this.props;
    Modal.confirm({
      title: t('Are you sure delete this member?'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        await member.remove();
        await sleep(240);
      }
    });
  };

  handleDeleteProject = project => {
    const { t } = this.props;
    Modal.confirm({
      title: t('Are you sure to delete this project?'),
      content: t(
        'This item will be deleted immediately. You can`t undo this action'
      ),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        await project.remove();
      }
    });
  };

  handlerOnSubmitModalAddNewProject = e => {
    if (this.instanceOfFormAddNewProject) {
      this.modalAddNewProjectIsLoading = true;
      const form = this.instanceOfFormAddNewProject.current;
      form.submit(e).then(() => {
        this.modalAddNewProjectIsVisible = false;
        this.modalAddNewProjectIsLoading = false;
      });
    }
  };

  handlerOnCancel = () => {
    this.modalAddNewProjectIsVisible = false;
    this.editableProject = null;
    const current = this.instanceOfFormAddNewProject.current;
    if (current.form) {
      current.form.reset();
      this.modalAddNewProjectIsVisible = false;
    }
  };

  handleEdit = project => {
    this.editableProject = {
      id: project.id,
      title: project.title,
      description: project.description
    };
    this.modalAddNewProjectIsVisible = true;
  };

  renderEmptyList() {
    return (
      <div className={style.emptyList}>
        <div className={style.emptyList__title}>Project list is empty</div>
        <div className={style.emptyList__description}>
          Before you start you need to add a project
        </div>
      </div>
    );
  }

  renderActions(item, t) {
    const actions = [];

    if (item.isSelected) {
      actions.push(
        <IconText
          type="check-square"
          text={t('Selected')}
          className={style.item__Selected}
        />
      );
    } else {
      actions.push(
        <IconText onClick={item.select} type="select" text={t('Select')} />
      );
    }

    actions.push(
      <IconText
        onClick={() => this.handleShowFormMembers(item)}
        type="team"
        text={t('Members')}
      />
    );

    if (RIGHT.PROJECT_CAN_EDIT.includes(item.role)) {
      actions.push(
        <IconText
          onClick={() => this.handleEdit(item)}
          type="edit"
          text={t('edit')}
        />
      );
    }

    if (RIGHT.PROJECT_CAN_CHANGE_OWNER.includes(item.role)) {
      // TODO(WIP!!!!)
      // actions.push(
      //   <IconText
      //     className={style.danger}
      //     onClick={() => this.handleShowFormChangeOwner(item)}
      //     type="arrow-right"
      //     text="Change owner"
      //   />
      // );
    }

    if (RIGHT.PROJECT_CAN_DELETE.includes(item.role)) {
      actions.push(
        <IconText
          className={style.danger}
          onClick={() => this.handleDeleteProject(item)}
          type="delete"
          text={t('Delete')}
        />
      );
    }

    if (RIGHT.PROJECT_CAN_LEAVE.includes(item.role)) {
      actions.push(
        <IconText
          className={style.danger}
          onClick={() => this.handleOnLeave(item)}
          type="logout"
          text={t('Leave')}
        />
      );
    }
    return actions;
  }

  renderItems() {
    const { mainStore, t } = this.props;
    const { projectsStore } = mainStore;

    return (
      <List
        itemLayout="vertical"
        dataSource={Array.from(projectsStore.values())}
        renderItem={item => {
          return (
            <Observer
              render={() => (
                <List.Item
                  key={item.title}
                  actions={this.renderActions(item, t)}
                >
                  <List.Item.Meta
                    title={item.title}
                    description={[
                      <div key="item_description">{item.description}</div>,
                      <span key="item_role">
                        {t('Role')}: {t(item.role)}{' '}
                      </span>
                    ]}
                  />
                </List.Item>
              )}
            />
          );
        }}
      />
    );
  }

  renderMemberListItem(member, canInvite) {
    const { t } = this.props;

    return (
      <Observer
        render={() => (
          <List.Item
            key={member.id}
            actions={[
              <Select
                disabled={!canInvite || member.role === USER_ROLE.OWNER}
                value={member.role}
                style={{ width: 140 }}
                onChange={member.changeRole}
              >
                {Object.keys(USER_ROLE)
                  .filter(key => USER_ROLE[key] !== USER_ROLE.ALL)
                  .map(key => {
                    return (
                      <Select.Option
                        disabled={USER_ROLE[key] === USER_ROLE.OWNER}
                        key={key}
                        value={USER_ROLE[key]}
                      >
                        {t(USER_ROLE[key])}
                      </Select.Option>
                    );
                  })}
              </Select>,
              canInvite ? (
                <IconText
                  disabled={member.role === USER_ROLE.OWNER}
                  className={style.danger}
                  onClick={() => this.handleDeleteMember(member)}
                  type="delete"
                  text={t('Delete')}
                />
              ) : null
            ]}
          >
            <List.Item.Meta
              title={
                <div className={style.nestedListItem__meta}>
                  <div>{member.user ? member.user.title : t('invitee')}</div>
                </div>
              }
              description={
                (member.user && member.user.description) || member.email
              }
            />
          </List.Item>
        )}
      />
    );
  }

  renderModalMembersList() {
    const { t } = this.props;
    const canInvite =
      this.selectedItem &&
      RIGHT.PROJECT_CAN_INVITE.includes(this.selectedItem.role);

    return (
      <Modal
        width={620}
        closable={false}
        footer={[
          <Button
            key="OK"
            type="primary"
            onClick={this.handleHideModalMembersList}
          >
            OK
          </Button>
        ]}
        visible={!!this.selectedItem}
        title={t('Members')}
      >
        {canInvite && [
          <FormAddMember key="FormAddMember" project={this.selectedItem} />,
          <Divider key="Divider" />
        ]}
        {this.selectedItem && (
          <List
            className={style.modal__list}
            dataSource={this.selectedItem.allMembers}
            renderItem={member => this.renderMemberListItem(member, canInvite)}
          />
        )}
      </Modal>
    );
  }

  renderModalChangeOwner() {
    return (
      <Modal
        closable={false}
        visible={!!this.selectedItemFormChangeOwner}
        title="Change owner"
        onOk={this.handleOnOkFormChangeOwner}
        onCancel={this.handleOnCancelFormChangeOwner}
      >
        <Input
          type="text"
          placeholder="Enter email or phone number of future owner"
        />
      </Modal>
    );
  }

  renderModalAddNewProject() {
    const { t } = this.props;
    return (
      <Modal
        onCancel={this.handlerOnCancel}
        footer={[
          <Button
            key="cancel"
            type="secondary"
            loading={this.modalAddNewProjectIsLoading}
            onClick={this.handlerOnCancel}
          >
            {t('cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.modalAddNewProjectIsLoading}
            onClick={this.handlerOnSubmitModalAddNewProject}
          >
            {this.editableProject ? t('update') : t('add')}
          </Button>
        ]}
        closable={false}
        visible={this.modalAddNewProjectIsVisible}
      >
        <FormAddNewProject
          ref={this.instanceOfFormAddNewProject}
          value={this.editableProject}
        />
      </Modal>
    );
  }

  render() {
    const { mainStore, t } = this.props;
    const { projectsStore } = mainStore;

    return (
      <Layout>
        <Header
          className={style.header}
          style={{ background: '#fff', paddingLeft: 24, paddingRight: 24 }}
        >
          <div>
            <Button onClick={this.handleShowAddNewProject} type="primary">
              {t('create new project')}
            </Button>
          </div>
        </Header>
        <Content className={style.content}>
          <div className={style.contentBody}>
            {projectsStore.size === 0
              ? this.renderEmptyList()
              : this.renderItems()}
          </div>
          {this.renderModalMembersList()}
          {this.modalAddNewProjectIsVisible && this.renderModalAddNewProject()}
          {this.renderModalChangeOwner()}
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
      </Layout>
    );
  }
}
