import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Layout, Card, Statistic, Button, Modal } from 'antd';
import { observable, toJS } from 'mobx';
import axios from 'axios';
import get from 'lodash/get';
import Pricing from '../../components/Pricing';
import IconTooltip from '../../components/IconTooltip';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import urls from '../../constants/urls';
import style from './style.module.scss';
import { handleError } from '../../utils';

const { Header, Content, Footer } = Layout;

function fmtDate(date) {
  if (!date) return '';
  return moment(date).format('DD MMMM YYYY');
}

function fmtDateRemain(date) {
  if (!date) return '';
  const a = moment(date);
  const b = moment();
  let r = a.diff(b, 'days');
  if (r === 0) {
    r = a.diff(b, 'hours');
    if (r === 0) {
      r = a.diff(b, 'minutes');
      if (r === 0) {
        r = a.diff(b, 'seconds');
        return moment.duration(r, 'seconds').format('s __');
      }
      return moment.duration(r, 'minutes').format('m __');
    }
    return moment.duration(r, 'hours').format('h __');
  }
  return moment.duration(r, 'days').format('d __');
}

export default
@inject('mainStore', 'auth', 't')
@observer
class Summary extends React.Component {
  @observable currentPwd = '';

  @observable newPwd = '';

  // eslint-disable-next-line react/destructuring-assignment
  @observable summary = this.props.data;

  @observable confirmNewPwd = '';

  @observable overviewTPisVisible = false;

  @observable canResetNow = this.computeResetPosibility();

  @observable resetIsVisible = false;

  @observable pricingIsLoading = false;

  @observable pricing = [];

  @observable currencyRates = {};

  computeResetPosibility = () => {
    const { data } = this.props;
    const { tariffPlanId } = data;
    // console.log('resetAt', resetAt);
    // console.log('endAt', endAt);
    // console.log('data', data);
    return tariffPlanId !== 'FREE';
  };

  fetchPricing = async () => {
    const { data } = await axios.get(urls.pricing);
    return data || [];
  };

  fetchCurrencyRates = async () => {
    const { data } = await axios.get(urls.currencyRates);
    return data || {};
  };

  handleChangeSubscribe = async () => {
    this.overviewTPisVisible = true;
    this.pricingIsLoading = true;
    this.pricing = await this.fetchPricing();
    this.currencyRates = await this.fetchCurrencyRates();
    this.pricingIsLoading = false;
  };

  handleResetCounters = () => {
    const { t, match } = this.props;
    const projectId = get(match, 'params.projectId');
    Modal.confirm({
      title: t('Please, confirm your action'),
      content: t('info_next_period_message'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      async onOk() {
        try {
          const { status } = await axios.put(urls.resetPlan(projectId));
          if (status === 202) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        } catch (e) {
          handleError(e);
        }
      }
    });
  };

  handleOnChoosePlan = async (plan, currency) => {
    const { match } = this.props;
    const projectId = get(match, 'params.projectId');
    this.pricingIsLoading = true;

    try {
      const response = await axios.put(urls.changePlan(projectId), {
        tariffId: plan.id,
        applyImmediately: true,
        payment: {
          duration: 1,
          // фалюта списания
          currency
        }
      });

      if (response.status === 202) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    } catch (e) {
      const { response } = e;

      if (response.status === 402) {
        const { data } = response;
        // eslint-disable-next-line no-restricted-globals
        location.href = data.url;
      } else {
        handleError(e);
        this.pricingIsLoading = false;
      }
    }
  };

  renderCounters() {
    const { t } = this.props;
    if (this.summary) {
      return [
        <Card className={style.card} title={t('Plans')} key="tp_id">
          {this.summary.tariffPlanId === 'FREE' ? (
            <Statistic
              title={
                <Button
                  onClick={this.handleChangeSubscribe}
                  className={style.doubleTitle__item__button}
                  type="link"
                >
                  {t('change')}
                </Button>
              }
              value="Free"
            />
          ) : (
            <Statistic
              title={
                <div className={style.doubleTitle}>
                  <div className={style.doubleTitle__item}>
                    {t('until')} {fmtDate(this.summary.endAt)}
                  </div>
                  <div className={style.doubleTitle__item}>
                    <Button
                      onClick={this.handleChangeSubscribe}
                      className={style.doubleTitle__item__button}
                      type="link"
                    >
                      {t('change')}
                    </Button>
                  </div>
                </div>
              }
              valueRender={() => (
                <div>{this.summary && this.summary.tariffPlanId}</div>
              )}
            />
          )}
        </Card>,
        <Card className={style.card} title={t('next_period')} key="next_period">
          <Statistic
            title={
              <div className={style.doubleTitle}>
                <div className={style.doubleTitle__item}>
                  {t('remain')}: {fmtDateRemain(this.summary.resetAt)}
                </div>
                {this.canResetNow && (
                  <div className={style.doubleTitle__item}>
                    <Button
                      onClick={this.handleResetCounters}
                      className={style.doubleTitle__item__button}
                      type="link"
                    >
                      {t('start now')}
                    </Button>
                  </div>
                )}
              </div>
            }
            value={fmtDate(this.summary.resetAt)}
          />
        </Card>,

        ...Object.keys(toJS(this.summary.counters)).map(key => {
          const title = key;
          const value = this.summary.counters[key].currentValue;
          const baseValue = this.summary.counters[key].baseValue;
          let alertInfo = null;
          if (parseFloat(value) === 0.0) {
            alertInfo = (
              <IconTooltip
                title={t('calls_are_ended')}
                iconName="exclamation-circle"
              />
            );
          }

          return (
            <Card
              className={style.card}
              title={
                <div>
                  {alertInfo} <span>{t(title)}</span>
                </div>
              }
              key={key}
            >
              <Statistic
                title={t('available')}
                value={value}
                suffix={`/ ${baseValue}`}
              />
            </Card>
          );
        }),
        ...Object.keys(toJS(this.summary.values)).map(key => {
          const title = key;
          let value = null;
          let baseValue = null;
          if (key === 'space') {
            value = `${(
              this.summary.values[key].currentValue /
              1024 /
              1024
            ).toFixed(2)} MB`;
            baseValue = `${(
              this.summary.values[key].baseValue /
              1024 /
              1024
            ).toFixed(2)} MB`;
          } else {
            value = this.summary.values[key].currentValue;
            baseValue = this.summary.values[key].baseValue;
          }
          let alertInfo = null;
          if (parseFloat(value) === baseValue) {
            alertInfo = (
              <IconTooltip
                title={t('value_is_reached')}
                iconName="exclamation-circle"
              />
            );
          }

          return (
            <Card
              className={style.card}
              title={
                <div>
                  {alertInfo} <span>{t(title)}</span>
                </div>
              }
              key={key}
            >
              <Statistic
                title={t('used')}
                value={value}
                suffix={`/ ${baseValue}`}
              />
            </Card>
          );
        })
      ];
    }
    return [
      <Card className={style.card} title={t('Plans')} key="tp_id">
        <Statistic
          title={
            <Button
              onClick={this.handleChangeSubscribe}
              className={style.doubleTitle__item__button}
              type="link"
            >
              {t('change')}
            </Button>
          }
          value="Free"
        />
      </Card>
    ];
  }

  renderModalReset() {
    return <div />;
  }

  renderModalTP() {
    return (
      <Pricing
        onCancel={() => (this.overviewTPisVisible = false)}
        pricing={toJS(this.pricing)}
        isLoading={this.pricingIsLoading}
        currencyRates={toJS(this.currencyRates)}
        overviewTPisVisible={this.overviewTPisVisible}
        onChoose={this.handleOnChoosePlan}
      />
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Layout>
        <Header className={style.header}>
          <div className={style.header__title}>{t('summary')}</div>
        </Header>
        <Content className={style.content}>
          <div className={style.contentBody}>{this.renderCounters()}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
        {this.overviewTPisVisible ? this.renderModalTP() : null}
        {this.resetIsVisible ? this.renderModalReset() : null}
      </Layout>
    );
  }
}
