import { types } from 'mobx-state-tree';
import ViewFieldModel from './viewField';
import relationField from './relationField';

export default types.model('TemplateFieldModel', {
  name: '',
  order: types.number,
  rules: types.maybe(types.array(types.string)),
  view: types.maybe(ViewFieldModel),
  relationship: types.maybeNull(relationField),
  default: ''
});
