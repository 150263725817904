import React from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import classNames from 'classnames';
import style from './style.module.scss';
import routes from '../../constants/routes';

const FormItem = Form.Item;

@withRouter
@inject('t')
@observer
class ErrorPage extends React.Component {
  getMessage = () => {
    const { location } = this.props;
    const values = queryString.parse(location.search);
    console.log('values.values', values);
    return values.msg || 'error 107';
  };

  render() {
    const { t } = this.props;
    const msg = this.getMessage();
    return (
      <div className={style.formWrapper}>
        <FormItem>
          <h3>{t('Error')}</h3>
        </FormItem>
        <Form
          className={classNames('login-form', style.form, style.form__message)}
        >
          <FormItem>{t(msg)}</FormItem>
          <FormItem>
            <Link to={routes.home}>{t('return to home')}</Link>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default ErrorPage;
