import { types, flow } from 'mobx-state-tree';
import axios from 'axios';
import urls from '../constants/urls';
import Project from './Project';
import { handleError } from '../utils';

export default types
  .model('MainStore', {
    selectedProject: types.maybeNull(types.reference(Project)),
    projectsStore: types.map(Project)
  })
  .volatile(() => ({
    //
  }))
  .views(self => ({
    getProjectById(projectId) {
      return self.projectsStore.get(projectId);
    }
  }))
  .actions(self => ({
    reset() {
      self.projectsStore = {};
      self.selectedProject = null;
    },
    // eslint-disable-next-line func-names
    addProject: flow(function*(payload) {
      const { data } = yield axios.post(urls.projects, payload);
      self.projectsStore.set(data.id, data);
    }),
    updateProject: flow(function*(payload) {
      const { data } = yield axios.put(
        `${urls.projects}/${payload.id}`,
        payload
      );
      const project = self.projectsStore.get(data.id);
      project.update(data);
    }),
    removeProjectById(projectId) {
      if (self.selectedProject && self.selectedProject.id === projectId) {
        self.selectedProject = null;
      }
      self.projectsStore.delete(projectId);
    },
    // eslint-disable-next-line func-names
    selectProject: flow(function*(selectedProject) {
      const { data } = yield axios.put(urls.extraInfo, {
        selectedProject: Object.prototype.hasOwnProperty.call(
          selectedProject,
          'id'
        )
          ? selectedProject.id
          : null
      });
      self.selectedProject = data.selectedProject;
    }),
    selectProjectById: flow(function*(selectedProjectId) {
      const { data } = yield axios.put(urls.extraInfo, {
        selectedProject: selectedProjectId
      });
      self.selectedProject = data.selectedProject;
    }),
    fetch: flow(function*() {
      const { data: projects } = yield axios.get(urls.projects);
      const res = projects.reduce((accumulator, currentValue) => {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});

      console.log('projects received', res);

      try {
        self.projectsStore = res;
      } catch (e) {
        handleError(e);
      }

      const { data: extraInfo } = yield axios.get(urls.extraInfo);
      self.selectedProject = extraInfo.selectedProject;
    })
  }));
