import React from 'react';
import { Collapse, Divider, Input, InputNumber, Select } from 'antd';
import isObject from 'lodash/isObject';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import DeclarativeForm from '../DeclarativeForm';
import DashboardItem from './components/DashboardItem';
import ModelSelector from '../ModelSelector';
import {
  PROPERTY_TYPE,
  RELATION_TYPE,
  VIEW_KIND,
  VIEW_KIND_BY_TYPE
} from '../DeclarativeForm/constants';
import getDefaultField from './getDefaultField';
import style from './style.module.scss';
import InputRule from '../InputRule';
import OptionEditorList from '../OptionEditorList';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

function unique(item, idx, self) {
  return self.indexOf(item) === idx;
}

const rules = [
  'required',
  'email',
  'alpha',
  'numeric',
  'string',
  'between:min,max',
  'max:value',
  'min:value'
];

function getRulesByType(type, excluded) {
  if (type === PROPERTY_TYPE.FILE) {
    return ['required'].filter(element => !excluded.includes(element));
  }
  return rules.filter(element => !excluded.includes(element));
}

@inject('t', 'mainStore')
@observer
class FormFieldEditor extends React.Component {
  @observable previewValue = {};

  componentDidMount() {
    const { value, name } = this.props;
    if (
      isObject(value) &&
      Object.prototype.hasOwnProperty.call(value, 'default')
    ) {
      this.previewValue = {
        [name]: value.default
      };
    }
  }

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.type || !isObject(value.view)) {
      return getDefaultField(value.name, value.order || -1);
    }
    // TODO: add validate value
    console.log('getValue()', value);
    return value;
  };

  onChangeHelp = e => {
    const { onChange } = this.props;
    const value = this.getValue();
    value.view.help = e.target.value;
    onChange({ ...value });
  };

  onChangeType = v => {
    const { onChange, name } = this.props;
    const value = this.getValue();
    if (
      value.type === PROPERTY_TYPE.RELATIONSHIP &&
      v !== PROPERTY_TYPE.RELATIONSHIP
    ) {
      value.relationship = null;
    }
    value.type = v;
    console.log('onChangeType value.type', value.type);
    const kind = VIEW_KIND_BY_TYPE[value.type][0];
    value.view.kind = kind;
    console.log('onChangeType kind', kind);
    this.previewValue = {
      [name]: value.default
    };
    onChange({ ...value });
  };

  onChangeViewKind = v => {
    const { onChange } = this.props;
    const value = this.getValue();
    value.view.kind = v;
    if (value.type === PROPERTY_TYPE.RELATIONSHIP) {
      if (value.view.kind === VIEW_KIND.LIST) {
        if (value.relationship) {
          value.relationship.type = RELATION_TYPE.MANY;
        } else {
          value.relationship = {
            type: RELATION_TYPE.MANY
          };
        }
      }
      if (value.view.kind === VIEW_KIND.SELECT) {
        if (value.relationship) {
          value.relationship.type = RELATION_TYPE.ONE;
        } else {
          value.relationship = {
            type: RELATION_TYPE.ONE
          };
        }
      }
    }
    this.previewValue = {};
    onChange({ ...value });
  };

  onChangeModel = model => {
    const { onChange } = this.props;
    const value = this.getValue();
    if (value.relationship) {
      value.relationship.modelId = model;
    } else {
      value.relationship = {
        modelId: model,
        type: RELATION_TYPE.ONE
      };
    }
    this.previewValue = {};
    onChange({ ...value });
  };

  onChangeViewItems = val => {
    const { onChange, name } = this.props;
    const value = this.getValue();
    value.view.items = val;
    if (
      this.previewValue[name] &&
      !Object.keys(val).includes(this.previewValue[name])
    ) {
      this.previewValue[name] = undefined;
    }
    onChange({ ...value });
  };

  onChangeLabel = e => {
    const { onChange } = this.props;
    const value = this.getValue();
    value.view.label = e.target.value;
    onChange({ ...value });
  };

  onChangeDescription = e => {
    const { onChange } = this.props;
    const value = this.getValue();
    value.view.description = e.target.value;
    onChange({ ...value });
  };

  onChangeOrder = newValue => {
    const { onChange } = this.props;
    const value = this.getValue();
    value.order = newValue;
    onChange({ ...value });
  };

  onChangeRules = nextRules => {
    const { onChange } = this.props;
    const value = this.getValue();
    value.rules = nextRules;
    onChange({ ...value });
  };

  onChangeDefaultValue = data => {
    this.previewValue = data;
    const { onChange, name } = this.props;
    const value = this.getValue();
    value.default = data[name];
    onChange({ ...value });
  };

  renderModelSelector() {
    const { t } = this.props;
    const value = this.getValue();

    return (
      <DashboardItem
        label={t('model')}
        description={t('model_description_message')}
      >
        <ModelSelector value={value} onChange={this.onChangeModel} />
      </DashboardItem>
    );
  }

  renderListEditor() {
    const { t } = this.props;
    const value = this.getValue();

    return (
      <DashboardItem
        style={{ paddingLeft: '16px', paddingRight: '16px' }}
        label={t('list_items')}
        description={t('add_new_items')}
      >
        <OptionEditorList
          editable
          value={value.view.items}
          onChange={this.onChangeViewItems}
        />
      </DashboardItem>
    );
  }

  render() {
    const { t, className, isNew } = this.props;

    const value = this.getValue();

    console.log('FIX', toJS(value));
    return (
      <div className={classNames(style.formFieldEditor, className)}>
        <div className={style.dashboard}>
          <DashboardItem
            label={t('label')}
            description={t('description_for_label')}
          >
            <Input
              type="text"
              className={style.selector}
              onChange={this.onChangeLabel}
              value={value.view.label}
            />
          </DashboardItem>

          <div className={style.dashboard__item}>
            <div className={style.dashboard__item__title}>{t('type')}</div>
            <div>
              <Select
                className={style.selector}
                onChange={this.onChangeType}
                value={value.type}
              >
                {Object.values(PROPERTY_TYPE)
                  .filter(unique)
                  .map(fieldType => (
                    <Option
                      key={fieldType}
                      value={fieldType}
                      className={style.select__item}
                    >
                      {t(fieldType)}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className={style.dashboard__item__description}>
              {t('type_description_message')}
            </div>
          </div>

          {value.type === PROPERTY_TYPE.RELATIONSHIP &&
            this.renderModelSelector()}

          <div className={style.dashboard__item}>
            <div className={style.dashboard__item__title}>{t('view')}</div>
            <div>
              <Select
                className={style.selector}
                onChange={this.onChangeViewKind}
                value={value.view.kind}
              >
                {Object.values(VIEW_KIND_BY_TYPE[value.type])
                  .filter(unique)
                  .map(fieldViewKind => (
                    <Option
                      key={fieldViewKind}
                      value={fieldViewKind}
                      className={style.select__item}
                    >
                      {t(fieldViewKind)}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className={style.dashboard__item__description}>
              {t('view_description_message')}
            </div>
          </div>

          <Collapse bordered={false} className={style.collapse}>
            <Panel header={t('advanced_parameters')} key="advanced_parameters">
              <div className={style.dashboard__item}>
                <div className={style.dashboard__item__title}>
                  {t('description')}
                </div>
                <div>
                  <Input
                    type="text"
                    className={style.selector}
                    onChange={this.onChangeDescription}
                    value={value.view.description}
                  />
                </div>
              </div>
              <div className={style.dashboard__item}>
                <div className={style.dashboard__item__title}>{t('help')}</div>
                <div>
                  <TextArea
                    className={style.selector}
                    onChange={this.onChangeHelp}
                    value={value.view.help}
                  />
                </div>
              </div>
              <div className={style.dashboard__item}>
                <div className={style.dashboard__item__title}>
                  {t('field_rules')}
                </div>
                <div>
                  <InputRule
                    className={style.selector}
                    items={getRulesByType(
                      value.type,
                      isNew || value.rules.includes('required')
                        ? []
                        : ['required']
                    )}
                    onChange={this.onChangeRules}
                    value={toJS(value.rules)}
                  />
                </div>
              </div>
              <div className={style.dashboard__item}>
                <div className={style.dashboard__item__title}>
                  {t('field_order')}
                </div>
                <div>
                  <InputNumber
                    className={style.selector}
                    onChange={this.onChangeOrder}
                    value={toJS(value.order)}
                  />
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>

        <Divider type="vertical" className={style.divider} />

        <div className={style.preview}>
          {/* <div className={style.preview__title}>{t('preview')}</div> */}
          <DeclarativeForm
            view={[value]}
            value={this.previewValue}
            onChange={this.onChangeDefaultValue}
          />
          {value.type === PROPERTY_TYPE.STRING &&
            value.view.kind === VIEW_KIND.SELECT &&
            this.renderListEditor()}
        </div>
      </div>
    );
  }
}

FormFieldEditor.defaultProps = {
  onChange: () => {},
  modelId: 'new'
};

export default FormFieldEditor;
