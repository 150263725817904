import React from 'react';
import classNames from 'classnames';
import { Tag, Input, Tooltip, Icon } from 'antd';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import style from './style.module.scss';

export default
@observer
class EditableTagGroup extends React.Component {
  @observable inputVisible = false;

  @observable inputValue = '';

  // eslint-disable-next-line react/destructuring-assignment
  @observable value = this.props.value || [];

  componentWillReceiveProps(nextProps) {
    this.value = nextProps.value;
  }

  handleClose = removedTag => {
    const { onChange, disabled } = this.props;
    if (disabled) {
      return;
    }
    this.value = this.value.filter(tag => tag !== removedTag);
    if (onChange) {
      onChange(this.value);
    }
  };

  showInput = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.inputVisible = true;
    setTimeout(() => {
      this.input.focus();
    });
  };

  handleInputChange = e => {
    this.inputValue = e.target.value.trim();
  };

  handleInputConfirm = () => {
    const { onChange } = this.props;
    let tags = this.value;

    if (this.inputValue && tags.indexOf(this.inputValue) === -1) {
      tags = [...tags, this.inputValue];
    }

    this.inputVisible = false;
    this.inputValue = '';
    this.value = tags;

    if (onChange) {
      onChange(this.value);
    }
  };

  saveInputRef = input => {
    this.input = input;
  };

  render() {
    const {
      inputVisible,
      inputValue,
      props: { className, disabled }
    } = this;
    return (
      <div className={classNames(className, { [style.disabled]: disabled })}>
        {this.value &&
          this.value.map(tag => {
            const isLongTag = tag.length > 40;
            const tagElem = (
              <Tag
                className={style.tag}
                key={tag}
                closable={!disabled}
                onClose={() => this.handleClose(tag)}
              >
                {isLongTag ? `${tag.slice(0, 40)}...` : tag}
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
        {inputVisible && (
          <Input
            className={classNames(style.input, style.handler, style.tag)}
            ref={this.saveInputRef}
            type="text"
            size="small"
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag
            className={classNames(style.input, style.handler, style.tag)}
            onClick={this.showInput}
            style={{ background: '#fff', borderStyle: 'dashed' }}
          >
            <Icon type="plus" /> New value
          </Tag>
        )}
      </div>
    );
  }
}
