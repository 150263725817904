import React from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { observable } from 'mobx';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import routes from '../../constants/routes';
import OAuth from '../../components/OAuth';
import style from './style.module.scss';

const FormItem = Form.Item;

@inject('auth', 'emitter', 't')
@observer
class SignIn extends React.Component {
  @observable hasError = false;

  @observable errorMessage = '';

  @observable isLoading = false;

  componentDidMount() {
    const { emitter } = this.props;
    this.keyupSubscription = emitter.addListener('keyup', event => {
      if (event.key === 'Enter') {
        this.handleLogin();
      }
    });
  }

  componentWillUnmount() {
    this.keyupSubscription.remove();
  }

  getMessage = () => {
    const { location } = this.props;
    const values = queryString.parse(location.search);
    return values.msg;
  };

  handleLogin = () => {
    const { auth } = this.props;
    const { signInForm, signIn } = auth;
    this.isLoading = true;
    signIn(signInForm).then(this.handleError);
  };

  handleError = payload => {
    this.isLoading = false;
    if (!payload) {
      this.hasError = true;
      this.errorMessage = 'error';
      return;
    }
    const { errors } = payload;
    if (errors) {
      console.log(errors);
      this.hasError = true;
      this.errorMessage = errors.join();
    }
  };

  render() {
    const { t, auth } = this.props;
    const { signInForm, isAuthenticated } = auth;
    const msg = this.getMessage();
    if (isAuthenticated()) {
      return <Redirect to="/" />;
    }
    return (
      <div className={style.formWrapper}>
        <Form
          onSubmit={e => {
            e.preventDefault();
          }}
          className={classNames('login-form', style.form)}
        >
          <FormItem>
            <h3>{t('SignIn')}</h3>
          </FormItem>
          <FormItem>
            <Input
              type="text"
              autoComplete="email"
              onChange={event => signInForm.set('email', event.target.value)}
              value={signInForm.email}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('Email')}
            />
          </FormItem>
          <FormItem>
            <Input
              autoComplete="current-password"
              onChange={event => signInForm.set('password', event.target.value)}
              value={signInForm.password}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={t('Password')}
            />
          </FormItem>
          <FormItem className={style.row}>
            <div className={style.row}>
              <Checkbox
                onChange={event =>
                  signInForm.set('remember', event.target.checked)
                }
                checked={signInForm.remember}
              >
                {t('Remember me')}
              </Checkbox>
              <Link className="login-form-forgot" to="/recovery-access/">
                {t('Forgot password')}
              </Link>
            </div>
          </FormItem>
          <FormItem>
            <div className={style.row}>
              <Button
                loading={this.isLoading}
                onClick={this.handleLogin}
                type="primary"
                htmlType="button"
                className="login-form-button"
              >
                {t('Sign in')}
              </Button>
              <div>
                {t('Or')} <Link to={routes.signUp}>{t('register now!')}</Link>
              </div>
            </div>
          </FormItem>
          {this.hasError && (
            <FormItem className={style.form__errors}>
              <p>
                {`${t(this.errorMessage)} `}
                {this.errorMessage === 'BLOCKED' && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bundle.space/support"
                  >
                    {t('support')}
                  </a>
                )}
              </p>
            </FormItem>
          )}
          {msg && (
            <FormItem className={style.form__errors}>
              <p>{`${t(msg)}`}</p>
            </FormItem>
          )}
          <FormItem>
            <OAuth />
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default SignIn;
