function Enum(any) {
  return Object.freeze(any);
}

const PROPERTY_TYPE = Enum({
  STRING: 'STRING',
  EditorJs: 'EditorJs',
  FILE: 'FILE', //  ?
  RELATIONSHIP: 'RELATIONSHIP', // foreign key
  NUMBER: 'NUMBER', // -1.455, 2e, 0.4, 42...
  // POINT: 'POINT', // {x, y}
  // Скорою в первом релизе
  // Но если нужна карта или иное положение в 2D плоскости,
  // то вернем
  BOOLEAN: 'BOOLEAN', // true | false
  ARRAY: 'ARRAY', // [value, value, value]
  RANGE: 'RANGE' // {from, to}
  // ENUM: 'ENUM'
});

const RELATION_TYPE = Enum({
  ONE: 'ONE',
  MANY: 'MANY'
});

const VIEW_KIND = Enum({
  DATE: 'DATE',
  EditorJs: 'EditorJs',
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
  LIST: 'LIST',
  // AUTOCOMPLETE: 'AUTOCOMPLETE',
  TIME: 'TIME',
  DATE_RANGE: 'DATE_RANGE',
  DATE_TIME: 'DATE_TIME',
  TIME_RANGE: 'TIME_RANGE',
  DATE_TIME_RANGE: 'DATE_TIME_RANGE',
  NUMBER: 'NUMBER',
  NUMBER_RANGE: 'NUMBER_RANGE',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  // TEXT_EDITOR: 'TEXT_EDITOR',
  SELECT: 'SELECT',
  RATE: 'RATE',
  TAG: 'TAG',
  UPLOAD: 'UPLOAD',
  IMAGE_UPLOAD: 'IMAGE_UPLOAD',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  SWITCH: 'SWITCH',
  COLOR: 'COLOR'
});

const VIEW_KIND_BY_TYPE = Enum({
  [PROPERTY_TYPE.EditorJs]: [VIEW_KIND.EditorJs],
  [PROPERTY_TYPE.STRING]: [
    VIEW_KIND.TEXT,
    // VIEW_KIND.TEXT_EDITOR,
    VIEW_KIND.TEXTAREA,
    VIEW_KIND.PHONE,
    VIEW_KIND.EMAIL,
    VIEW_KIND.SELECT,
    VIEW_KIND.DATE,
    VIEW_KIND.DATE_TIME,
    VIEW_KIND.TIME,
    VIEW_KIND.COLOR
  ],
  [PROPERTY_TYPE.NUMBER]: [
    VIEW_KIND.NUMBER,
    VIEW_KIND.RATE,
    VIEW_KIND.SELECT,
    VIEW_KIND.DATE,
    VIEW_KIND.DATE_TIME,
    VIEW_KIND.TIME
  ],
  [PROPERTY_TYPE.FILE]: [VIEW_KIND.IMAGE_UPLOAD, VIEW_KIND.UPLOAD],
  [PROPERTY_TYPE.BOOLEAN]: [
    VIEW_KIND.CHECKBOX,
    VIEW_KIND.SWITCH,
    VIEW_KIND.RADIO
  ],
  [PROPERTY_TYPE.ARRAY]: [
    VIEW_KIND.TAG
    // VIEW_KIND.MULTIPLE_SELECT, Непонятно, что тут выбирать, для отношений есть отдельный одноименный тип
  ],
  [PROPERTY_TYPE.RANGE]: [
    VIEW_KIND.DATE_TIME_RANGE,
    VIEW_KIND.DATE_RANGE,
    VIEW_KIND.NUMBER_RANGE
  ],
  [PROPERTY_TYPE.RELATIONSHIP]: [
    VIEW_KIND.SELECT,
    VIEW_KIND.LIST
    // VIEW_KIND.MULTIPLE_SELECT,
    // VIEW_KIND.AUTOCOMPLETE
  ]
});

const footerLabel = '© 2019-2020, Cadiatech.com, Inc. or its affiliates';

export {
  VIEW_KIND,
  PROPERTY_TYPE,
  VIEW_KIND_BY_TYPE,
  RELATION_TYPE,
  footerLabel
};
