import { types, flow, getParentOfType, getEnv, isAlive } from 'mobx-state-tree';
import axios from 'axios';
import _get from 'lodash/get';
import { handleError } from '../utils';
import MainStore from './MainStore';
import DataModel from './DataModel';
import UserModel from './User';
import urls from '../constants/urls';
import { toMap } from './utils';

const membershipModel = types
  .model('Membership', {
    role: types.string,
    id: types.string,
    user: UserModel
  })
  .actions(self => ({
    // eslint-disable-next-line func-names
    changeRole: flow(function*(role) {
      const project = getParentOfType(self, Project);

      try {
        const {
          data
        } = yield axios.put(
          `${urls.projects}/${project.id}/memberships/${self.id}`,
          { role }
        );
        self.role = data.role;
      } catch (e) {
        handleError(e);
      }
    }),
    remove: flow(function*() {
      try {
        const project = getParentOfType(self, Project);
        yield axios.delete(
          `${urls.projects}/${project.id}/memberships/${self.id}`
        );
        project.removeMembershipById(self.id);
      } catch (e) {
        handleError(e);
      }
    })
  }));

const InviteModel = types
  .model('InviteModel', {
    id: types.identifier,
    role: types.string,
    email: types.string
  })
  .actions(self => ({
    // eslint-disable-next-line func-names
    changeRole: flow(function*(role) {
      const project = getParentOfType(self, Project);
      self.role = role;
      const { data } = yield axios.put(
        `${urls.projects}/${project.id}/invites/${self.id}`,
        {
          role
        }
      );
      self.role = data.role;
    }),
    remove: flow(function*() {
      const project = getParentOfType(self, Project);
      yield axios.delete(`${urls.projects}/${project.id}/invites/${self.id}`);
      project.removeInviteById(self.id);
    })
  }));

const Project = types
  .model('Project', {
    id: types.identifier,
    title: types.string,
    description: types.string,
    memberships: types.map(membershipModel),
    dataModels: types.map(DataModel),
    invites: types.map(InviteModel),
    createdAt: types.Date,
    updatedAt: types.Date
  })
  .preProcessSnapshot(
    ({ createdAt, invites, memberships, updatedAt, ...rest } = {}) => ({
      createdAt: Date.parse(createdAt),
      updatedAt: Date.parse(updatedAt),
      invites: toMap(invites),
      memberships: toMap(memberships),
      ...rest
    })
  )
  .views(self => ({
    get pages() {
      return Array.from(self.dataModels.values()).filter(
        model => model.meta.isVisibleInPageEditor
      );
    },
    get onlyModels() {
      return self.dataModels.filter(model => !model.isPage);
    },
    get role() {
      const env = getEnv(self);
      return (
        Array.from(self.memberships.toJS().values()).find(m => {
          return m && m.user && m.user.id === env.auth.id;
        }) || {}
      ).role;
    },
    get isSelected() {
      if (isAlive(self)) {
        const mainStore = getParentOfType(self, MainStore);
        return (
          mainStore &&
          mainStore.selectedProject &&
          mainStore.selectedProject.id &&
          mainStore.selectedProject.id === self.id
        );
      }
      return false;
    },
    get allMembers() {
      return [
        ...Array.from(self.memberships.values()),
        ...Array.from(self.invites.values())
      ];
    }
  }))
  .actions(self => ({
    // afterAttach() {
    //     onPatch(self, (p) => {
    //         console.log('applyPatch', p);
    //         applyPatch(self, p)
    //     });
    // },
    fetchSummary: flow(function*() {
      const { data } = yield axios.get(`${urls.projects}/${self.id}/summary`);
      return data;
    }),
    update(data) {
      self.title = data.title;
      self.description = data.description;
      self.updatedAt = data.updatedAt ? Date.parse(data.updatedAt) : Date();
    },
    fetchDataModel: flow(function*(modelId) {
      try {
        const { data } = yield axios.get(`${urls.models(self.id)}/${modelId}`);
        self.dataModels.set(data.id, data);
        return self.dataModels.get(data.id);
      } catch (e) {
        handleError(e);
      }
    }),
    fetchDataModels: flow(function*(params = {}) {
      try {
        const { data } = yield axios.get(urls.models(self.id), {
          params
        });
        self.dataModels = toMap(data);
      } catch (e) {
        handleError(e);
      }
    }),
    createDataModel: flow(function*(model = {}) {
      const { data } = yield axios.post(urls.models(self.id), model);
      self.dataModels.set(data.id, data);
      return self.dataModels.get(data.id);
    }),
    updateDataModel: flow(function*(nextUpdate) {
      const { data } = yield axios.put(
        `${urls.models(self.id)}/${nextUpdate.id}`,
        nextUpdate
      );
      self.dataModels.set(data.id, data);
      return self.dataModels.get(data.id);
    }),
    deleteDataModel: flow(function*(modelId) {
      yield axios.delete(`${urls.models(self.id)}/${modelId}`);
      self.dataModels.delete(modelId);
    }),
    select: flow(function*() {
      const mainStore = getParentOfType(self, MainStore);
      yield mainStore.selectProject(self);
    }),
    addMember: flow(function*(member) {
      try {
        const { data: membership } = yield axios.post(
          `${urls.projects}/${self.id}/memberships`,
          member
        );
        if (membership) {
          self.memberships.set(membership.id, membership);
        }
      } catch (e) {
        if (_get(e, 'response.status', -1) === 422) {
          yield self.createInviteToProject(member);
        } else {
          handleError(e);
        }
      }
    }),
    createInviteToProject: flow(function*(payload) {
      try {
        console.log('payload', payload);
        const { data: invite } = yield axios.post(
          `${urls.projects}/${self.id}/invites`,
          payload
        );
        if (invite) {
          self.invites.set(invite.id, invite);
        }
      } catch (e) {
        handleError(e);
      }
    }),
    deleteMemberships: flow(function*(membershipsId) {
      try {
        yield axios.delete(
          `${urls.projects}/${self.id}/memberships/${membershipsId}`
        );
      } catch (e) {
        handleError(e);
      }

      self.memberships.delete(membershipsId);
    }),
    remove: flow(function*() {
      yield axios.delete(`${urls.projects}/${self.id}`);
      const mainStore = getParentOfType(self, MainStore);
      mainStore.removeProjectById(self.id);
    }),
    removeInviteById(id) {
      self.invites.delete(id);
    },
    removeMembershipById(id) {
      self.memberships.delete(id);
    }
  }));

export default Project;
