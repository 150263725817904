import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Input, Modal, Select } from 'antd';
import style from './style.module.scss';

const divider = condition => {
  if (condition) return ':';
  return '';
};

export default
@inject('t')
@observer
class InputRule extends React.Component {
  @observable ruleCurrentValue = null;

  @observable ruleName = null;

  @observable ruleValueName = null;

  @observable hasEditRuleCurrentValue = false;

  editRuleValue = (event, currentValue, ruleName, keyName) => {
    event.stopPropagation();
    this.ruleName = ruleName;
    this.ruleValueName = keyName;

    if (keyName === currentValue) {
      this.ruleCurrentValue = '';
    } else {
      this.ruleCurrentValue = currentValue;
    }
    this.hasEditRuleCurrentValue = true;
  };

  onChangeRuleValue = e => {
    const { value } = e.target;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    // eslint-disable-next-line no-restricted-globals
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.ruleCurrentValue = value;
    }
  };

  handleChange = nextValue => {
    const { onChange } = this.props;

    if (onChange) {
      const value = Object.keys(nextValue)
        .filter(rule => nextValue[rule])
        .map(rule => this.renderRule(rule, nextValue[rule]));
      onChange(value);
    }
  };

  handleOnApplyValue = allValues => {
    allValues[this.ruleName][this.ruleValueName] = this.ruleCurrentValue;
    this.ruleValueName = '';
    this.hasEditRuleCurrentValue = true;
    this.hasEditRuleCurrentValue = false;
    this.handleChange(allValues);
  };

  hideModalValuesSetter = () => {
    this.hasEditRuleCurrentValue = false;
  };

  renderRule(rule, values) {
    return (
      rule +
      divider(Object.keys(values).length > 0) +
      Object.keys(values)
        .map(key => {
          const val = values[key];
          if (val) {
            return val;
          }
          return key;
        })
        .join()
    );
  }

  renderRuleItem(ruleName, initialValue, currentValue = {}) {
    const values = [];
    Object.keys(initialValue).forEach((keyValue, index, arr) => {
      const isLast = index === arr.length - 1;
      const value = currentValue[keyValue] || keyValue;
      values.push(
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={value}
          className={style.editableLink}
          onClick={event =>
            this.editRuleValue(
              event,
              currentValue[keyValue],
              ruleName,
              keyValue
            )
          }
        >
          {value}
          {!isLast ? ',' : ''}
        </div>
      );
    });

    return (
      <div>
        {ruleName}
        {divider(values.length > 0)}
        {values}
      </div>
    );
  }

  render() {
    const { items, value, t } = this.props;

    const allRules = items.reduce((accumulator, currentValue) => {
      const parts = currentValue.split(':');
      const values = {};
      if (parts.length > 1) {
        parts[1].split(',').forEach(val => {
          values[val] = '';
        });
      }
      accumulator[parts[0]] = values;
      return accumulator;
    }, {});

    const allValues = (value || []).reduce((accumulator, currentValue) => {
      const parts = currentValue.split(':');
      const values = {};
      const ruleName = parts[0];

      const keys = Object.keys(allRules[ruleName]);
      if (parts.length > 1) {
        const vals = parts[1].split(',');
        keys.forEach((key, keyPosition) => {
          values[key] = vals[keyPosition];
        });
      }
      accumulator[parts[0]] = values;
      return accumulator;
    }, {});

    const onSelect = valueXX => {
      valueXX.forEach(ruleName => {
        if (!Object.prototype.hasOwnProperty.call(allValues, ruleName)) {
          allValues[ruleName] = allRules[ruleName];
        }
      });
      Object.keys(allValues).forEach(valueKey => {
        if (!valueXX.includes(valueKey)) {
          delete allValues[valueKey];
        }
      });
      this.handleChange(allValues);
    };

    return [
      <Select
        key="input_rule_select"
        value={Object.keys(allValues)}
        mode="tags"
        size="small"
        optionLabelProp="label"
        className={style.selector}
        onChange={onSelect}
      >
        {Object.keys(allRules).map(ruleName => {
          return (
            <Select.Option
              label={this.renderRuleItem(
                ruleName,
                allRules[ruleName],
                allValues[ruleName]
              )}
              value={ruleName}
              key={ruleName}
            >
              {this.renderRule(ruleName, allRules[ruleName])}
            </Select.Option>
          );
        })}
      </Select>,
      <Modal
        key="input_rule_modal"
        footer={
          <Button onClick={() => this.handleOnApplyValue(allValues)}>
            {t('Apply')}
          </Button>
        }
        closable={false}
        visible={this.hasEditRuleCurrentValue}
        onOk={this.hideModalValuesSetter}
        onCancel={this.hideModalValuesSetter}
      >
        <Input
          addonBefore={this.ruleValueName}
          onChange={this.onChangeRuleValue}
          value={this.ruleCurrentValue}
        />
      </Modal>
    ];
  }
}
