import React from 'react';
import LanguageSelector from "../LanguageSelector";
import style from './Layouts.module.scss';


export default class SimpleLayout extends React.Component {
  render() {
    return (
      <div className={style.simpleLayout}>
        <div className={style.simpleLayout__Header}>
          <LanguageSelector />
        </div>
        <div className={style.simpleLayout__Body}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
