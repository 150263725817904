import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { List, Button, Icon, Modal, Radio, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import IconWhatsapp from '../svg/Icons/Whatsapp';
import IconTelegram from '../svg/Icons/Telegram';
import IconSMS from '../svg/Icons/SMS';
import IconEmail from '../svg/Icons/Email';
import ListHeader from '../ListHeader';
import style from './style.module.scss';
import EditableTagGroup from '../EditableTagGroup';

const RadioGroup = Radio.Group;

export default
@inject('t')
@observer
class DeliveryEditor extends React.Component {
  @observable configIsVisible = false;

  @observable deliveryItemPosition = null;

  @observable deliveryName = '';

  @observable deliveryTransportType = 'email';

  @observable deliveryDestinationList = [];

  // eslint-disable-next-line react/destructuring-assignment
  @observable value = this.props.value || [];

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.value = nextProps.value;
  }

  handleOnShowDeliveryItemConfig = (item, position) => {
    if (_.isNumber(position)) {
      // edit
      this.deliveryItemPosition = position;
      this.deliveryName = item.name;
      this.deliveryTransportType = item.transport;
      this.deliveryDestinationList = item.destinations;
    } else {
      // new
      this.deliveryItemPosition = null;
      this.deliveryName = '';
      this.deliveryTransportType = 'email';
      this.deliveryDestinationList = [];
    }
    this.configIsVisible = true;
  };

  handleChangeDeliveryTransport = value => {
    this.deliveryTransportType = value;
  };

  handleChangeDestinationList = value => {
    this.deliveryDestinationList = value;
  };

  handleOk = () => {
    const { onChange } = this.props;
    if (!_.isNumber(this.deliveryItemPosition)) {
      // new
      this.value.push({
        name: this.deliveryName,
        transport: this.deliveryTransportType,
        destinations: this.deliveryDestinationList
      });

      if (onChange) {
        onChange(this.value);
      }
    } else {
      // edit
      const item = toJS(this.value.splice(this.deliveryItemPosition, 1));
      this.value.splice(this.deliveryItemPosition, 0, {
        ...item[0],
        name: this.deliveryName,
        transport: this.deliveryTransportType,
        destinations: this.deliveryDestinationList
      });
      if (onChange) {
        onChange(this.value);
      }
    }

    this.resetModalForm();
  };

  handleDeleteDelivery = () => {
    const { onChange } = this.props;
    if (this.deliveryItemPosition !== -1) {
      this.value.splice(this.deliveryItemPosition, 1);
      if (onChange) {
        onChange(this.value);
      }
    }
    this.resetModalForm();
  };

  handleCancel = () => {
    this.resetModalForm();
  };

  resetModalForm() {
    this.deliveryItemPosition = null;
    this.deliveryName = '';
    this.deliveryTransportType = 'email';
    this.deliveryDestinationList = [];
    this.configIsVisible = false;
  }

  renderIconByTransportType(type) {
    if (type === 'email')
      return <IconEmail className={style.radioValue__icon} />;
    if (type === 'sms') return <IconSMS className={style.radioValue__icon} />;
    if (type === 'telegram')
      return <IconTelegram className={style.radioValue__icon} />;
    if (type === 'whatsapp')
      return <IconWhatsapp className={style.radioValue__icon} />;
    return [];
  }

  renderModal() {
    const { t } = this.props;
    return (
      <Modal
        visible={this.configIsVisible}
        title=""
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={
          <div className={style.footerBody}>
            <div>
              {this.deliveryItemPosition ? (
                <Button type="danger" onClick={this.handleDeleteDelivery}>
                  {t('delete')}
                  <Icon type="delete" theme="outlined" />
                </Button>
              ) : null}
            </div>
            <div>
              <Button key="back" onClick={this.handleCancel}>
                {t('cancel')}
              </Button>
              <Button key="submit" type="primary" onClick={this.handleOk}>
                {t('ok')}
              </Button>
            </div>
          </div>
        }
      >
        <div className={style.modalItem}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={style.label}>{t('Name')}</label>
          <Input
            type="name"
            value={this.deliveryName}
            onChange={e => (this.deliveryName = e.target.value)}
          />
        </div>

        <div className={style.modalItem}>
          <label className={style.label}>{t('Transport')}</label>
          <RadioGroup
            value={this.deliveryTransportType}
            className={style.columnList}
            onChange={this.handleChangeDeliveryTransport}
          >
            <Radio className={style.row} value="email">
              <div className={style.radioValue}>
                <IconEmail className={style.radioValue__icon} />
                <span className={style.radioValue__text}>Email</span>
              </div>
            </Radio>
            <Radio className={style.row} disabled value="sms">
              <div className={style.radioValue}>
                <IconSMS className={style.radioValue__icon} />
                <span className={style.radioValue__text}>SMS</span>
              </div>
            </Radio>
            <Radio className={style.row} disabled value="telegram">
              <div className={style.radioValue}>
                <IconTelegram className={style.radioValue__icon} />
                <span className={style.radioValue__text}>Telegram</span>
              </div>
            </Radio>
            <Radio className={style.row} disabled value="whatsapp">
              <div className={style.radioValue}>
                <IconWhatsapp className={style.radioValue__icon} />
                <span className={style.radioValue__text}>Whatsapp</span>
              </div>
            </Radio>
          </RadioGroup>
        </div>

        <div className={style.modalItem}>
          <label className={style.label}>{t('Destination list')}</label>
          <EditableTagGroup
            className={style.inputItem}
            value={this.deliveryDestinationList}
            onChange={this.handleChangeDestinationList}
          />
        </div>
      </Modal>
    );
  }

  render() {
    const { className, t } = this.props;
    return (
      <div className={classNames(className)}>
        <List
          className={style.list}
          key={this.value.length}
          size="small"
          header={
            <ListHeader
              title={t('Delivery list')}
              onClick={() => this.handleOnShowDeliveryItemConfig()}
            />
          }
          dataSource={this.value}
          renderItem={(item, index) => (
            <List.Item key={`${item.name}__${index}`}>
              <div className={style.content}>
                <div className={style.radioValue}>
                  {this.renderIconByTransportType(item.transport)}
                  <span className={style.radioValue__text}>{item.name}</span>
                </div>
                <Button
                  className={style.handler}
                  onClick={() =>
                    this.handleOnShowDeliveryItemConfig(item, index)
                  }
                >
                  <Icon type="setting" />
                </Button>
              </div>
            </List.Item>
          )}
        />
        {this.renderModal()}
      </div>
    );
  }
}
