import React from 'react';
import { Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Button } from 'antd';
import { observable } from 'mobx';
import { onPatch } from 'mobx-state-tree';
import classNames from 'classnames';
import routes from '../../constants/routes';
import style from './style.module.scss';
import OAuth from '../../components/OAuth';

const FormItem = Form.Item;

@inject('auth', 't', 'i18n')
@observer
class SignUp extends React.Component {
  @observable status = '';

  @observable isSuccess = false;

  @observable isLoading = false;

  @observable hasError = false;

  @observable errorMessage = '';

  componentDidMount() {
    const { auth } = this.props;
    const { signUpForm } = auth;
    this.disposer = onPatch(signUpForm, () => (this.hasError = false));
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
      this.disposer = undefined;
    }
  }

  handleOnClickSignUp = () => {
    const { auth, i18n } = this.props;
    const { signUpForm, signUp } = auth;
    this.isLoading = true;
    signUp(signUpForm, i18n.language).then(data => {
      this.isLoading = false;
      if (!Object.prototype.hasOwnProperty.call(data, 'errors') && data.id) {
        this.isSuccess = true;
        this.status = data.status || 'VERIFIED';
      } else {
        console.log(data.errors);
        this.hasError = true;
        this.errorMessage = data.errors;
      }
    });
  };

  render() {
    const { t, auth } = this.props;
    const { signUpForm, isAuthenticated } = auth;
    if (isAuthenticated()) {
      return <Redirect to={routes.home} />;
    }

    if (this.isSuccess && this.status === 'VERIFIED') {
      return (
        <div className={style.formWrapper}>
          <FormItem>
            <h3>{t('Create account')}</h3>
          </FormItem>
          <Form
            className={classNames(
              'login-form',
              style.form,
              style.form__message
            )}
          >
            <FormItem>
              {t('You registered. Now you can to')}{' '}
              <Link to={routes.signIn}>{t('sign in')}</Link>
            </FormItem>
          </Form>
        </div>
      );
    }
    if (this.isSuccess && this.status === 'AWAIT_ACTIVATION') {
      return (
        <div className={style.formWrapper}>
          <FormItem>
            <h3>{t('Create account')}</h3>
          </FormItem>
          <Form
            className={classNames(
              'login-form',
              style.form,
              style.form__message
            )}
          >
            <FormItem>{t('registered_success_await_activation')}</FormItem>
          </Form>
        </div>
      );
    }

    return (
      <div className={style.formWrapper}>
        <Form className={classNames('login-form', style.form)}>
          <FormItem className={style.form__message}>
            <h3>{t('Create account')}</h3>
          </FormItem>
          <FormItem>
            <Input
              type="email"
              onChange={event => signUpForm.set('email', event.target.value)}
              value={signUpForm.email}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('Email')}
              autoComplete="email"
            />
          </FormItem>
          <FormItem>
            <Input
              autoComplete="new-password"
              onChange={event => signUpForm.set('password', event.target.value)}
              value={signUpForm.password}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={t('Password')}
            />
          </FormItem>
          <FormItem>
            <Input
              autoComplete="new-password"
              onChange={event =>
                signUpForm.set('confirmPassword', event.target.value)
              }
              value={signUpForm.confirmPassword}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={t('Confirm password')}
            />
          </FormItem>
          <FormItem>
            <div className={style.row}>
              <Button
                loading={this.isLoading}
                onClick={this.handleOnClickSignUp}
                type="primary"
                htmlType="button"
                className="login-form-button"
              >
                {t('Sign up')}
              </Button>
              <div>
                {t('Or')} <Link to={routes.signIn}>{t('Sign in')}</Link>
              </div>
            </div>
          </FormItem>
          {this.hasError && (
            <FormItem className={style.form__errors}>
              <p>{t(this.errorMessage)}</p>
            </FormItem>
          )}
          <FormItem>
            <OAuth />
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default SignUp;
