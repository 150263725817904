import { VIEW_KIND, PROPERTY_TYPE } from './constants';

const rulesOfRules = [];

const viewRules = {
  kind: ['required', { in: Object.values(VIEW_KIND) }],
  items: [{ required_if: ['kind', VIEW_KIND.SELECT] }]
};

const rules = {
  type: ['required', { in: Object.values(PROPERTY_TYPE) }],
  view: viewRules
};

export default rules;
