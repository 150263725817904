import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Spin } from 'antd';
import Page403 from '../pages/403';
import Page404 from '../pages/404';
import style from './App/App.module.css';

function hocLoader(WrappedComponent, loader) {
  return class HocLoaderWrapper extends React.Component {
    state = {
      error: null,
      isLoaded: false,
      loadedProps: {}
    };

    constructor(props) {
      super(props);
      loader(props)
        .then(loadedProps => {
          this.setState({
            loadedProps,
            isLoaded: true
          });
        })
        .catch(error => {
          if (error.status === 404 || error.status === 403) {
            return this.setState({ error });
          }
          throw error;
        });
    }

    render() {
      const { error, isLoaded, loadedProps } = this.state;
      if (error && error.status && error.status === 404) {
        return <Page404 dark />;
      }
      if (error && error.status && error.status === 403) {
        return <Page403 dark />;
      }
      if (isLoaded) {
        return <WrappedComponent {...this.props} {...loadedProps} />;
      }
      return <Spin size="large" className={style.spinner} />;
    }
  };
}

export default
@inject('auth')
class ExtRoute extends React.Component {
  result = null;

  static defaultProps = {
    isPublic: true,
    Component: null,
    loader: null,
    layout: null
  };

  renderOnRoute = routeProps => {
    const {
      auth,
      component: Component,
      loader, // async function({location, match, history}) { return params };
      layout: Layout,
      isPublic
    } = this.props;

    this.result = <Component {...routeProps} />;

    if (!isPublic) {
      if (!auth.isAuthenticated()) {
        // with redirect
        this.result = (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: routeProps.location }
            }}
          />
        );

        // without redirect
        // this.result = (
        //     <SimpleLayout>
        //         <SignInPage />
        //     </SimpleLayout>
        // );
        return this.result;
      }
    }

    if (loader) {
      const WrappedComponent = hocLoader(Component, loader);
      this.result = <WrappedComponent {...routeProps} />;
    }

    if (Layout) {
      return <Layout>{this.result}</Layout>;
    }

    return this.result;
  };

  render() {
    const {
            component, // eslint-disable-line
      ...rest
    } = this.props;

    return <Route {...rest} render={this.renderOnRoute} />;
  }
}
