import React from 'react';
import { Layout, Button, List, Divider, Modal, Tag } from 'antd';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import style from './style.module.scss';
import routes from '../../constants/routes';
import SingleItem from './SingleItem';
import MultiItem from './MultiItem';
import { RIGHT } from '../../constants';
import ApiInfo from '../../components/ApiInfo';

const { Header, Content } = Layout;

export default
@inject('mainStore', 'auth', 't')
@observer
class Pages extends React.Component {
  handleOnClickEdit = item => {
    const { mainStore, history } = this.props;
    history.push(routes.to(routes.page, mainStore.selectedProject.id, item.id));
  };

  // eslint-disable-next-line no-unused-vars
  handleOnClickInfo = (item, dataItem) => {
    const { t } = this.props;
    const { token } = item;

    Modal.info({
      width: '900px',
      content: (
        <>
          <div>
            {t('public token')} <Tag color="#108ee9">{token}</Tag>
          </div>

          {item && !item.meta.isSingleton && (
            <div className={style.apiItem}>
              {t('Get all items')}
              <ApiInfo
                api={`${window.location.origin}/api/public/models/${token}/data`}
                type="GET"
              />
            </div>
          )}

          {dataItem && dataItem.id && (
            <div className={style.apiItem}>
              {t('Get the item')}
              <ApiInfo
                api={`${window.location.origin}/api/public/models/${token}/data/${dataItem.id}`}
                type="GET"
              />
            </div>
          )}
        </>
      )
    });
  };

  renderItems() {
    const { mainStore } = this.props;

    return (
      <List
        className={style.list}
        size="small"
        itemLayout="vertical"
        dataSource={mainStore.selectedProject.pages}
        renderItem={item =>
          !item.meta.isSingleton ? (
            <MultiItem
              key={item.id}
              item={item}
              onClickInfo={this.handleOnClickInfo}
              onClickEdit={this.handleOnClickEdit}
            />
          ) : (
            <SingleItem
              key={item.id}
              item={item}
              onClickInfo={this.handleOnClickInfo}
              onClickEdit={this.handleOnClickEdit}
            />
          )
        }
      />
    );
  }

  render() {
    const { t, mainStore, match } = this.props;
    const { projectsStore } = mainStore;
    const projectId = get(match, 'params.projectId');
    const role = projectsStore.get(projectId).role;

    return (
      <Layout>
        <Header className={style.header}>
          <div className={style.header__title}>{t('pages')}</div>
          <Divider type="vertical" />
          <div className={style.header__panel}>
            {RIGHT.MODEL_CAN_BE_CREATE.includes(role) && (
              <Link
                to={routes.to(routes.page, mainStore.selectedProject.id, 'new')}
              >
                <Button type="primary">{t('create page')}</Button>
              </Link>
            )}
          </div>
        </Header>

        <Content className={style.content}>
          <div className={style.contentBodyWithoutFooter}>
            {this.renderItems()}
          </div>
        </Content>
      </Layout>
    );
  }
}
