import React from 'react';
import {
  Layout,
  Button,
  Modal,
  List,
  Select,
  Divider,
  Form,
  Input
} from 'antd';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import moment from 'moment';
import style from './style.module.scss';
import IconText from '../../components/IconText';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import { handleError } from '../../utils';
import { RIGHT } from '../../constants';
import HookForm from './HookForm';
import Spacer from '../../components/Spacer';

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;

export default
@withRouter
@inject('mainStore', 'hooksStore', 'auth', 't')
@observer
class Hooks extends React.Component {
  @observable isLoading = false;

  @observable hookEditorIsVisible = false;

  @observable hookDetailIsVisible = false;

  @observable statusDescription = '';

  componentDidMount() {
    this.fetchAll().then(() => '');
  }

  fetchAll = async () => {
    const { match, hooksStore } = this.props;
    const projectId = get(match, 'params.projectId');

    // const limit = this.pagination.pageSize;
    // const offset = (this.pagination.current - 1) * limit;
    // const end = offset + limit;

    try {
      this.isLoading = true;
      await hooksStore.fetchAll(projectId);
      // this.pagination.total = files.length;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  };

  handleDeleteForm = item => {
    const { match, hooksStore, t } = this.props;
    const projectId = get(match, 'params.projectId');
    Modal.confirm({
      title: t('Are you sure to delete this hook?'),
      content: t(
        'This item will be deleted immediately. You can`t undo this action'
      ),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      async onOk() {
        try {
          await hooksStore.delete(projectId, item.id);
        } catch (e) {
          handleError(e);
        }
      }
    });
  };

  handleDetailStatus = item => {
    this.statusDescription = item.statusDescription;
    this.hookDetailIsVisible = true;
  };

  handleEnableHook = async itemId => {
    const { match, hooksStore } = this.props;
    const projectId = get(match, 'params.projectId');

    try {
      this.isLoading = true;
      await hooksStore.handleEnableHook(projectId, itemId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  };

  handleDisableHook = async itemId => {
    const { match, hooksStore } = this.props;
    const projectId = get(match, 'params.projectId');

    try {
      this.isLoading = true;
      await hooksStore.handleDisableHook(projectId, itemId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  };

  handleEditHook = item => {
    const { t } = this.props;

    this.form = new HookForm(
      toJS(item),
      t,
      formValues => {
        return this.handleHookEditorUpdate(formValues, item.id);
      },
      e => {
        console.log(e);
      }
    );
    this.hookEditorIsVisible = true;
  };

  handleCreateNewHook = () => {
    const { t } = this.props;
    this.form = new HookForm(
      {
        id: 'new',
        name: '',
        action: 'DataChanged',
        url: '',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        body: ''
      },
      t,
      formValues => {
        return this.handleHookEditorCreate(formValues);
      },
      e => {
        console.log(e);
      }
    );

    this.hookEditorIsVisible = true;
  };

  handleOnCloseHookDetail = () => {
    this.hookDetailIsVisible = false;
  };

  handleHookEditorUpdate = async (formValues, itemId) => {
    const { match, hooksStore } = this.props;
    const projectId = get(match, 'params.projectId');

    try {
      await hooksStore.update(projectId, itemId, formValues);
      this.hookEditorIsVisible = false;
    } catch (e) {
      handleError(e);
    }
  };

  handleHookEditorCreate = async formValues => {
    const { match, hooksStore } = this.props;
    const projectId = get(match, 'params.projectId');

    try {
      await hooksStore.create(projectId, formValues);
      this.hookEditorIsVisible = false;
    } catch (e) {
      handleError(e);
    }
  };

  onCancelHookEditor = () => {
    this.hookEditorIsVisible = false;
  };

  renderHookDetail() {
    return (
      <Modal
        closable
        width={820}
        visible={this.hookDetailIsVisible}
        title={null}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOnCloseHookDetail}
          >
            OK
          </Button>
        ]}
        onOk={this.handleOnCloseHookDetail}
      >
        <pre>{this.statusDescription}</pre>
      </Modal>
    );
  }

  renderHookEditor() {
    if (!this.hookEditorIsVisible) return null;

    const { t } = this.props;
    const form = this.form;
    const isNew = this.form.initialValue && this.form.initialValue.id === 'new';
    return (
      <Modal
        width={1020}
        visible={this.hookEditorIsVisible}
        title={null}
        closable={false}
        footer={[
          <Button icon="rollback" key="back" onClick={this.onCancelHookEditor}>
            {t('cancel')}
          </Button>,
          !isNew ? (
            <Button
              key="submit"
              type="primary"
              icon="save"
              loading={this.addFormIsLoading}
              onClick={form.onSubmit}
            >
              {t('update')}
            </Button>
          ) : (
            <Button
              key="submit"
              type="primary"
              icon="check"
              loading={this.addFormIsLoading}
              onClick={form.onSubmit}
            >
              {t('create')}
            </Button>
          )
        ]}
      >
        <Form>
          <div className={style.formItem}>
            <div className={style.formItem__title}>
              <label htmlFor={form.$('name').id}>{form.$('name').label}</label>
              <div className={style.formItem__error}>
                {form.$('name').error}
              </div>
            </div>
            <Input {...form.$('name').bind()} />
          </div>

          <Spacer height={16} />

          <div className={style.formItem}>
            <div className={style.formItem__title}>
              <label htmlFor={form.$('action').id}>
                {form.$('action').label}
              </label>
              <div className={style.formItem__error}>
                {form.$('action').error}
              </div>
            </div>
            <Select {...form.$('action').bind()} mode="default">
              <Option key="DataChanged" value="DataChanged">
                {t('DataChanged')}
              </Option>
            </Select>
          </div>

          <Spacer height={16} />

          <div className={style.formItem}>
            <div className={style.formItem__title}>
              <label htmlFor={form.$('url').id}>{form.$('url').label}</label>
              <div className={style.formItem__error}>{form.$('url').error}</div>
            </div>
            <Input {...form.$('url').bind()} />
          </div>

          <Spacer height={16} />

          <div className={style.formItem}>
            <div className={style.formItem__title}>
              <label htmlFor={form.$('method').id}>
                {form.$('method').label}
              </label>
              <div className={style.formItem__error}>
                {form.$('method').error}
              </div>
            </div>
            <Select {...form.$('method').bind()} mode="default">
              <Option key="POST" value="POST">
                POST
              </Option>
              <Option key="GET" value="GET">
                GET
              </Option>
            </Select>
          </div>

          {form.$('method').get('value') === 'POST' && [
            <Spacer height={16} />,

            <div className={style.formItem}>
              <div className={style.formItem__title}>
                <label htmlFor={form.$('contentType').id}>
                  {form.$('contentType').label}
                </label>
                <div className={style.formItem__error}>
                  {form.$('contentType').error}
                </div>
              </div>
              <Select {...form.$('contentType').bind()} mode="default">
                <Option
                  key="application/x-www-form-urlencoded"
                  value="application/x-www-form-urlencoded"
                >
                  application/x-www-form-urlencoded
                </Option>
                <Option key="application/json" value="application/json">
                  application/json
                </Option>
                <Option key="application/xml" value="application/xml">
                  application/xml
                </Option>
                <Option key="text/plain" value="text/plain">
                  text/plain
                </Option>
              </Select>
            </div>,

            <Spacer height={16} />,

            <div className={style.formItem}>
              <div className={style.formItem__title}>
                <label htmlFor={form.$('body').id}>
                  {form.$('body').label}
                </label>
                <div className={style.formItem__error}>
                  {form.$('body').error}
                </div>
              </div>
              <TextArea
                {...form.$('body').bind()}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </div>
          ]}
        </Form>
      </Modal>
    );
  }

  renderEmptyList() {
    const { t } = this.props;
    return (
      <div className={style.emptyList}>
        <div className={style.emptyList__title}>{t('Hooks list is empty')}</div>
        <div className={style.emptyList__description}>
          {t('Add hook on your actions')}
        </div>
      </div>
    );
  }

  renderItems() {
    const { hooksStore, t, match, mainStore } = this.props;
    const projectId = get(match, 'params.projectId');
    const project = mainStore.projectsStore.get(projectId);
    const role = project.role;

    return (
      <List
        loading={this.isLoading}
        itemLayout="vertical"
        size="medium"
        dataSource={hooksStore.items.values()}
        renderItem={item => (
          <List.Item
            key={item.id}
            actions={
              RIGHT.HOOK_CAN_BE_UPDATE.includes(role)
                ? [
                    <IconText
                      onClick={e => this.handleDetailStatus(item, e)}
                      type="info-circle"
                      text={t('Status detail')}
                    />,
                    item.isDisabled ? (
                      <IconText
                        onClick={e => this.handleEnableHook(item.id, e)}
                        type="play-circle"
                        text={t('Enable hook')}
                      />
                    ) : (
                      <IconText
                        onClick={e => this.handleDisableHook(item.id, e)}
                        type="pause-circle"
                        text={t('Disable hook')}
                      />
                    ),
                    <IconText
                      onClick={e => this.handleEditHook(item, e)}
                      type="edit"
                      text={t('edit')}
                    />,
                    <IconText
                      onClick={e => this.handleDeleteForm(item, e)}
                      type="delete"
                      text={t('delete')}
                    />
                  ]
                : [
                    <IconText
                      onClick={e => this.handleDetailStatus(item, e)}
                      type="info-circle"
                      text={t('Status detail')}
                    />
                  ]
            }
            extra={null}
          >
            <List.Item.Meta
              className={style.itemHook}
              title={item.name || item.id}
              description={`${t('last event date')}: ${moment(
                item.lastAction
              ).format('YYYY/MM/DD HH:mm') || '-'}`}
            />
            {`${t('Status')}: ${t(item.status)}`}
          </List.Item>
        )}
      />
    );
  }

  render() {
    const { match, mainStore, hooksStore } = this.props;
    const { t } = this.props;
    const projectId = get(match, 'params.projectId');
    const project = mainStore.projectsStore.get(projectId);
    const role = project.role;

    return (
      <Layout>
        <Header className={style.header}>
          <div className={style.header__title}>{t('Hooks')}</div>
          <Divider type="vertical" />
          {RIGHT.HOOK_CAN_BE_CREATE.includes(role) && (
            <Button onClick={this.handleCreateNewHook} type="primary">
              {t('Add hook')}
            </Button>
          )}
        </Header>
        <Content className={style.content}>
          <div className={style.contentBody}>
            {hooksStore.items.size === 0
              ? this.renderEmptyList()
              : this.renderItems()}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
        {this.renderHookEditor()}
        {this.renderHookDetail()}
      </Layout>
    );
  }
}
