import React from 'react';
import style from './style.module.scss';

export default function LogoButton (props) {
  return (
    <div className={style.button} onClick={props.onClick}>
      <img className={style.logo} src={props.logo} />
    </div>
  );
}
