import React from 'react';
import { observer, inject } from 'mobx-react';
import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { Form, Input } from 'antd';
import style from './style.module.scss';

const { TextArea } = Input;

@inject('mainStore', 't')
@observer
class FormAddNewProject extends React.Component {
  p = {};

  constructor(props) {
    super(props);
    const { t, value } = props;
    const self = this;
    const plugins = { dvr: validatorjs };
    const fields = [
      {
        name: 'title',
        label: t('Name'),
        placeholder: t('Enter name of new project'),
        rules: 'required|string',
        value: (value && value.title) || ''
      },
      {
        name: 'description',
        label: t('Description'),
        placeholder: t('Describe your project'),
        rules: '',
        value: (value && value.description) || ''
      }
    ];

    const options = {
      showErrorsOnReset: false,
      validateOnBlur: false
    };

    const hooks = {
      onSuccess(form) {
        if (value) {
          return self.props.mainStore
            .updateProject({ ...form.values(), id: value.id })
            .then(() => {
              self.form.reset();
              self.p.resolve();
            });
        }
        return self.props.mainStore.addProject(form.values()).then(() => {
          self.form.reset();
          self.p.resolve();
        });
      },
      onError(form) {
        // get all form errors
        console.log('All form errors', form.errors());
      }
    };
    this.form = new MobxReactForm({ fields }, { plugins, hooks, options });
  }

  submit = async e => {
    return new Promise((resolve, reject) => {
      this.p = { resolve, reject };
      this.form.onSubmit(e);
    });
  };

  render() {
    const { form } = this;
    return (
      <Form>
        <div className={style.formItem}>
          <div className={style.formItem__title}>
            <label htmlFor={form.$('title').id}>{form.$('title').label}</label>
            <div className={style.formItem__error}>{form.$('title').error}</div>
          </div>
          <Input {...form.$('title').bind()} />
        </div>
        <div className={style.formItem}>
          <div className={style.formItem__title}>
            <label htmlFor={form.$('description').id}>
              {form.$('description').label}
            </label>
            <div className={style.formItem__error}>
              {form.$('description').error}
            </div>
          </div>
          <TextArea
            {...form.$('description').bind()}
            autosize={{ minRows: 3, maxRows: 6 }}
          />
        </div>
      </Form>
    );
  }
}

export default FormAddNewProject;
