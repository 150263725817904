import React from 'react';
import { Layout, Button, Table, Modal, Progress } from 'antd';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import get from 'lodash/get';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import routes from '../../constants/routes';
import NoData from '../../components/NoData';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import { handleError, handleReferenceException } from '../../utils';
import UploadFile from '../../components/UploadFile';
import DependencyList from '../../components/DependencyList';
import stylePage from '../pages.module.scss';
import style from './style.module.scss';

const { Header, Content, Footer } = Layout;

export default
@inject('auth', 't', 'mainStore', 'filesStore')
@withRouter
@observer
class FIleExplorer extends React.Component {
  @observable files = [];

  @observable selectedFormTemplateId = -1;

  @observable info = {
    used: 0,
    total: 0
  };

  @observable filters = {};

  @observable sorter = {};

  @observable pagination = {
    pageSize: 10,
    total: 0,
    current: 1
  };

  @observable isLoading = false;

  @observable uploaderIsVisible = true;

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll = async () => {
    const { match, history, filesStore } = this.props;
    const projectId = get(match, 'params.projectId');

    const limit = this.pagination.pageSize;
    const offset = (this.pagination.current - 1) * limit;
    const end = offset + limit;

    try {
      this.isLoading = true;
      const files = await filesStore.fetchAll(projectId);
      this.info = await filesStore.fetchInfo(projectId);
      this.pagination.total = files.length;
      this.files = files.slice(offset, Math.min(end, filesStore.items.length));
    } catch (e) {
      if (e.response && e.response.status === 402) {
        handleError(e, () => {
          history.push(routes.to(routes.summary, projectId));
        });
      } else {
        handleError(e);
      }
    } finally {
      this.isLoading = false;
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { filesStore } = this.props;

    this.pagination = pagination;
    this.filters = filters;
    this.sorter = sorter;

    const limit = pagination.pageSize;
    const offset = (pagination.current - 1) * limit;
    const end = offset + limit;
    this.files = [...filesStore.items].slice(
      offset,
      Math.min(end, filesStore.items.length)
    );
  };

  handleOnDeleteDataItem = record => {
    const { t } = this.props;
    const { filesStore, match } = this.props;
    const projectId = get(match, 'params.projectId');

    Modal.confirm({
      title: t('Are you sure to delete this data item?'),
      style: { marginTop: '172px' },
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        try {
          this.isLoading = true;

          const isLast = this.files.length === 1;

          await filesStore.remove(projectId, record.id);
          this.info = await filesStore.fetchInfo(projectId);

          if (isLast) {
            this.setLastPage();
          } else {
            const idx = this.files.findIndex(it => it.id === record.id);
            this.files.splice(idx, 1);
          }
        } catch (e) {
          if (e.response && e.response.status === 409) {
            handleReferenceException(e, match);
          } else {
            handleError(e);
          }
        } finally {
          this.isLoading = false;
        }
      }
    });
  };

  setLastPage = () => {
    const { filesStore } = this.props;

    this.pagination.total = filesStore.items.length;
    const lastPage = Math.ceil(
      this.pagination.total / this.pagination.pageSize
    );
    const limit = this.pagination.pageSize;
    const offset = (lastPage - 1) * limit;
    const end = offset + limit;
    const delCount = Math.min(end, filesStore.items.length);

    this.files = [...filesStore.items].slice(offset, delCount);
    this.pagination = {
      total: filesStore.items.length,
      current: lastPage,
      pageSize: this.pagination.pageSize
    };
  };

  handleOnInfoItem = async file => {
    const { t } = this.props;
    const { filesStore, match } = this.props;
    const projectId = get(match, 'params.projectId');

    const dependencyList = await filesStore.fetchDependencies(
      projectId,
      file.id
    );

    Modal.info({
      title: file.name,
      content: (
        <div>
          <pre>
            <a href={file.URI}>{t('Link to file')}</a>
            <CopyToClipboard text={file.URI}>
              <Button className={style.handler} icon="copy" />
            </CopyToClipboard>
          </pre>
          <DependencyList t={t} match={match} dependencyList={dependencyList} />
        </div>
      ),
      okText: t('OK'),
      okType: 'ghost'
    });
  };

  handleOnClick = file => {
    console.log(file);
  };

  handleOnChangeFileList = async changedFileList => {
    const { match, filesStore } = this.props;
    const projectId = get(match, 'params.projectId');

    if (changedFileList.length) {
      filesStore.add(changedFileList[0]);
    }

    try {
      this.info = await filesStore.fetchInfo(projectId);
    } catch (e) {
      handleError(e);
    }

    this.setLastPage();
  };

  handleOnUploadError = (error, response) => {
    handleError(response);
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t('name'),
        width: 'auto',
        render: (text, record) => {
          return record.name;
        }
      },
      {
        title: t('Size'),
        width: 'auto',
        render: (text, record) => {
          return `${(record.size / 1024 / 1024).toFixed(2)} MB`;
        }
      },
      {
        title: t('type'),
        width: 'auto',
        render: (text, record) => {
          return `${record.type}`;
        }
      },
      {
        title: t(''),
        width: 'auto',
        render: (text, record, index) => {
          return (
            <div className={style.actionCell}>
              <Button
                icon="question"
                type="info"
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.handleOnInfoItem(record, index);
                }}
              />
              <div style={{ width: '12px' }} />
              <Button
                icon="delete"
                type="danger"
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.handleOnDeleteDataItem(record, index);
                }}
              />
            </div>
          );
        }
      }
    ];
  };

  renderEmptyList() {
    const { t } = this.props;
    return (
      <div className={stylePage.emptyList}>
        <NoData noDefaultLabel>{t('No files')}</NoData>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const percent = (
      ((this.info.used || 0) / (this.info.total || 1)) *
      100
    ).toFixed(2);
    const total = ((this.info.total || 0) / 1024 / 1024).toFixed(2);
    const used = ((this.info.used || 0) / 1024 / 1024).toFixed(2);

    return (
      <Layout>
        <Header className={stylePage.headerWithSide}>
          <div className={stylePage.header__leftSide}>
            <div className={style.header__title}>{t('Files')}</div>
          </div>
          <div className={stylePage.header__rightSide}>
            <UploadFile
              onError={this.handleOnUploadError}
              buttonType="primary"
              showUploadingOnly
              className={style.uploader}
              disabled={this.info.total <= this.info.used}
              value={this.files}
              onChange={this.handleOnChangeFileList}
              listType="text"
            />
          </div>
        </Header>
        <Content className={stylePage.content}>
          <div className={stylePage.contentBody}>
            {t('Usage disk space')} ({used} / {total} MB)
            <div className={style.progressWrapper}>
              <Progress percent={percent} showInfo size="small" />
            </div>
            <div className={style.divider} />
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => this.handleOnClick(record, rowIndex)
                };
              }}
              bordered
              rowKey="id"
              columns={this.getColumns()}
              className={style.table}
              loading={this.isLoading}
              pagination={this.pagination}
              onChange={this.handleTableChange}
              dataSource={this.files}
            />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
      </Layout>
    );
  }
}
