import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Layout,
  Select,
  Card,
  Input,
  Icon,
  Form,
  Modal,
  Divider
} from 'antd';
import { observable } from 'mobx';
import style from './style.module.scss';
import LanguageSelector from '../../components/LanguageSelector';
import { footerLabel } from '../../components/DeclarativeForm/constants';
import { handleError } from '../../utils';
import routes from '../../constants/routes';

const { Header, Content, Footer } = Layout;
const FormItem = Form.Item;

export default
@inject('mainStore', 'auth', 't')
@observer
class Profile extends React.Component {
  @observable currentPwd = '';

  @observable newPwd = '';

  @observable confirmNewPwd = '';

  @observable isPasswordUpdatings = false;

  handleOnChangeCurrentPassword = e => {
    this.currentPwd = e.target.value;
  };

  handleOnChangeNewPassword = e => {
    this.newPwd = e.target.value;
  };

  handleOnChangeConfirmNewPwd = e => {
    this.confirmNewPwd = e.target.value;
  };

  handleOnChangePassword = () => {
    const { auth, t } = this.props;
    const self = this;
    Modal.confirm({
      title: t('text_confirm_change_password'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        self.isPasswordUpdatings = true;
        try {
          await auth.updatePassword({
            currentPassword: self.currentPwd,
            newPassword: self.newPwd,
            confirmPassword: self.confirmNewPwd
          });
        } catch (e) {
          handleError(e);
        }

        self.currentPwd = '';
        self.newPwd = '';
        self.confirmNewPwd = '';
        self.isPasswordUpdatings = false;
      }
    });
  };

  handleOnDelete = () => {
    const { auth, t, history } = this.props;
    Modal.confirm({
      title: t('text_confirm_delete_account'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        try {
          await auth.deleteProfile();
          history.go(routes.signIn);
        } catch (e) {
          handleError(e);
        }
      }
    });
  };

  renderDeleteAccount() {
    const { t } = this.props;
    return (
      <Card
        title={t('deleting_account')}
        bordered={false}
        className={style.card}
      >
        <Button htmlType="button" type="danger" onClick={this.handleOnDelete}>
          {t('button_title_delete_account')}
        </Button>
      </Card>
    );
  }

  render() {
    const { t, auth } = this.props;
    return (
      <Layout>
        <Header className={style.header}>
          <div>
            {t('profile')} <Divider type="vertical" /> {auth.email}
          </div>
        </Header>
        <Content className={style.content}>
          <div className={style.contentBody}>
            <Card title={t('language')} bordered={false} className={style.card}>
              <LanguageSelector
                onChange={language =>
                  auth.updateProfile({ language: language.toLowerCase() })
                }
              />
            </Card>

            <Card
              title={t('change_password')}
              bordered={false}
              className={style.card}
            >
              <form className={style.form}>
                <FormItem>
                  <Input
                    autoComplete="current-password"
                    prefix={
                      <Icon
                        type="unlock"
                        style={{ color: 'rgba(0,0,0,.25)' }}
                      />
                    }
                    value={this.currentPwd}
                    placeholder={t('current_password')}
                    type="password"
                    onChange={this.handleOnChangeCurrentPassword}
                  />
                </FormItem>
                <FormItem>
                  <Input
                    autoComplete="new-password"
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    value={this.newPwd}
                    placeholder={t('new_password')}
                    type="password"
                    onChange={this.handleOnChangeNewPassword}
                  />
                </FormItem>
                <FormItem>
                  <Input
                    autoComplete="new-password"
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    value={this.confirmNewPwd}
                    placeholder={t('confirm_new_password')}
                    type="password"
                    onChange={this.handleOnChangeConfirmNewPwd}
                  />
                </FormItem>

                <FormItem>
                  <Button
                    disabled={
                      this.confirmNewPwd.length < 3 ||
                      this.newPwd.length < 3 ||
                      this.currentPwd.length < 3
                    }
                    loading={this.isPasswordUpdatings}
                    onClick={this.handleOnChangePassword}
                    htmlType="button"
                    type="primary"
                  >
                    {t('change_password')}
                  </Button>
                </FormItem>
              </form>
            </Card>

            <Card title={t('theme')} bordered={false} className={style.card}>
              <Select
                disabled
                onChange={theme => auth.updateProfile({ theme })}
                value={auth.theme}
              >
                <Select.Option value="light">Light</Select.Option>
                <Select.Option value="dark">Dark</Select.Option>
              </Select>
            </Card>
            {this.renderDeleteAccount()}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
      </Layout>
    );
  }
}
