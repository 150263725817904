import React from 'react';
import { Layout, Spin, Button, Divider, Modal, Icon, Tag } from 'antd';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import axios from 'axios';
import { withRouter, Link, Prompt } from 'react-router-dom';
import get from 'lodash/get';
import nanoid from 'nanoid';
import debounce from 'lodash/debounce';
import { observer, inject, Observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import AceEditor from 'react-ace';
import urls from '../../constants/urls';
import EditableTitle from '../../components/EditableTitle';
import ModelMetaEditor from '../../components/ModelMetaEditor';
import IconSelector from '../../components/IconSelector';
import DeclarativeForm from '../../components/DeclarativeForm';

import stylePage from '../pages.module.scss';
import style from './style.module.scss';

import routes from '../../constants/routes';
import getDefaultField from '../../components/FormFieldEditor/getDefaultField';
import FormFieldEditor from '../../components/FormFieldEditor';
import {
  footerLabel,
  PROPERTY_TYPE,
  VIEW_KIND
} from '../../components/DeclarativeForm/constants';
import { handleError, handleReferenceException } from '../../utils';
import 'ace-builds/src-noconflict/mode-json5';
import 'ace-builds/src-noconflict/theme-dracula';
import ApiInfo from '../../components/ApiInfo';

const { Header, Content, Footer } = Layout;
const iconStyle = { fontSize: '22px', marginLeft: '8px' };

export default
@withRouter
@inject('auth', 'mainStore', 't')
@observer
class ModelPage extends React.Component {
  origin = {};

  @observable isDirty = false;

  @observable designIsEnabled = true;

  @observable apiInfoIsVisible = false;

  @observable isLoading = false;

  @observable previewIsVisible = false;

  @observable isVisibleFieldEditor = false;

  // data model
  @observable formData = {};

  // eslint-disable-next-line react/destructuring-assignment
  @observable modelName = this.props.t('new page');

  @observable modelToken = 'n/a';

  @observable modelIcon = '';

  @observable schema = [];

  @observable meta = {};

  @observable allFieldNames = [];

  @observable sourceCodeValue = '{}';

  // field - property
  @observable field = {};

  @observable fieldName = '';

  componentDidMount() {
    this.setModel();
    this.updateOrigin();
  }

  setModel() {
    const { mainStore, match, t } = this.props;
    const id = get(match, 'params.modelId', 'new');
    this.formData = {};
    if (id === 'new') {
      this.modelName = t('new model');
      this.modelIcon = 'file';
      this.schema = [
        {
          name: 'title',
          order: -2,
          type: PROPERTY_TYPE.STRING,
          rules: ['required'],
          relationship: null,
          view: {
            kind: VIEW_KIND.TEXT,
            label: t('title'),
            help: t('title_help'),
            default: '',
            description: '',
            title: '',
            placeholder: t('title'),
            items: null
          },
          default: ''
        }
      ];
      this.meta = {
        isSingleton: false,
        isVisibleInPageEditor: false
      };
    } else {
      const model = mainStore.selectedProject.dataModels.get(id);
      if (model) {
        this.meta = toJS(model.meta);
        this.schema = toJS(model.schema);
        this.modelName = model.name;
        this.modelToken = model.token;
        this.modelIcon = model.icon;
      }
    }
    this.allFieldNames = this.schema.map(userModelProp => userModelProp.name);
  }

  updateDiff = () => {
    const currentValue = {
      modelName: toJS(this.modelName),
      modelIcon: toJS(this.modelIcon),
      meta: toJS(this.meta),
      schema: toJS(this.schema),
      modelToken: toJS(this.modelToken),
      allFieldNames: toJS(this.allFieldNames)
    };
    this.isDirty = !isEqual(this.origin, currentValue);
  };

  updateOrigin = () => {
    this.origin = {
      modelName: toJS(this.modelName),
      modelIcon: toJS(this.modelIcon),
      meta: toJS(this.meta),
      schema: toJS(this.schema),
      modelToken: toJS(this.modelToken),
      allFieldNames: toJS(this.allFieldNames)
    };
  };

  handleOnChangeDataDeclarativeForm = nextValue => {
    this.formData = nextValue;
  };

  handleOnChangeSourceCode = value => {
    this.sourceCodeValue = value;
  };

  // eslint-disable-next-line react/sort-comp
  debouncedHandleOnChangeSourceCode = debounce(
    this.handleOnChangeSourceCode,
    400,
    {
      maxWait: 1500
    }
  );

  handleOnChangeModelIcon = v => {
    this.modelIcon = v;
    this.updateDiff();
  };

  handleOnChangeModelName = v => {
    this.modelName = v;
    this.updateDiff();
  };

  handlerOnChangeEditorMode = v => {
    this.designIsEnabled = v;
    if (this.designIsEnabled) {
      this.schema = JSON.parse(this.sourceCodeValue);
    } else {
      this.sourceCodeValue = JSON.stringify(this.schema, null, 2);
    }
  };

  handleOnCreateNewFormField = () => {
    // eslint-disable-next-line prefer-spread
    const maxOrder = Math.max.apply(
      Math,
      this.schema.map(function(o) {
        return o.order;
      })
    );
    this.fieldName = nanoid(12);
    this.field = getDefaultField(this.fieldName, maxOrder + 1);
    this.isVisibleFieldEditor = true;
  };

  handleClosePreview = () => {
    this.previewIsVisible = false;
  };

  handleOnDelete = () => {
    const self = this;
    const { t, mainStore, match } = this.props;
    const modal = Modal.confirm({
      title: t('Are you sure delete this model?'),
      content: t(
        'This item will be deleted immediately. You can`t undo this action'
      ),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      async onOk() {
        const id = get(match, 'params.modelId', 'new');
        try {
          await mainStore.selectedProject.deleteDataModel(id);
          self.isDirty = false;
          self.props.history.push(
            routes.to(routes.models, mainStore.selectedProject.id)
          );
        } catch (e) {
          modal.destroy();
          if (e.response && e.response.status === 409) {
            handleReferenceException(e, match);
          } else {
            handleError(e);
          }
        }
      }
    });
  };

  handleOnSaveModel = async () => {
    this.previewIsVisible = false;
    this.isLoading = true;

    const { mainStore, match, history } = this.props;
    const id = get(match, 'params.modelId', 'new');
    try {
      if (id === 'new') {
        const savedModel = await mainStore.selectedProject.createDataModel({
          name: this.modelName,
          alias: this.modelName,
          icon: this.modelIcon,
          schema: this.schema,
          meta: toJS(this.meta)
        });
        this.isDirty = false;
        history.push(
          routes.to(routes.model, mainStore.selectedProject.id, savedModel.id)
        );
      } else {
        const savedModel = await mainStore.selectedProject.updateDataModel({
          id,
          name: this.modelName,
          alias: this.modelName,
          icon: this.modelIcon,
          meta: toJS(this.meta),
          schema: this.schema
        });

        this.modelName = savedModel.name;
        this.schema = savedModel.schema;
        this.modelToken = savedModel.token;
        this.modelIcon = savedModel.icon;
        this.allFieldNames = this.schema.map(
          userModelProp => userModelProp.name
        );
        this.isLoading = false;

        this.updateOrigin();
        this.updateDiff();
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  };

  handleCancelEditField = () => {
    const self = this;
    const { t } = this.props;

    Modal.confirm({
      title: t('Are you sure cancel this changes?'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        self.fieldName = '';
        self.field = {};
        self.isVisibleFieldEditor = false;
      }
    });
  };

  handleFinishEditField = () => {
    const fieldName = this.fieldName;
    const index = this.schema.findIndex(
      userProp => userProp.name === fieldName
    );
    if (index === -1) {
      this.schema.push(toJS(this.field));
    } else {
      this.schema[index] = toJS(this.field);
    }
    this.updateDiff();
    this.field = {};
    this.fieldName = '';
    this.isVisibleFieldEditor = false;
  };

  onChangeField = nextFieldValue => {
    this.field = nextFieldValue;
  };

  deleteField = fieldName => {
    const index = this.schema.findIndex(
      userProp => userProp.name === fieldName
    );
    if (index !== -1) {
      this.schema.splice(index, 1);
    }
    this.updateDiff();
  };

  editField = fieldName => {
    const index = this.schema.findIndex(
      userProp => userProp.name === fieldName
    );
    this.fieldName = fieldName;
    this.field = toJS(this.schema[index]);
    this.isVisibleFieldEditor = true;
  };

  handleChangeFieldName = (nextValue, oldValue) => {
    const { t, match } = this.props;
    const projectId = get(match, 'params.projectId');
    const modelId = get(match, 'params.modelId', 'new');

    if(oldValue === nextValue) return;

    if (modelId === 'new' || !this.allFieldNames.includes(oldValue)) {
      if (nextValue !== oldValue) {
        const index = this.schema.findIndex(
          userProp => userProp.name === oldValue
        );
        this.schema[index].name = nextValue;
        this.updateDiff();
      }
    } else {
      this.isLoading = true;

      const modal = Modal.warn({
        // Processing operation
        title: t('Renaming data items...'),
        className: style.modalInfinityProgress,
        okButtonProps: { style: { display: 'none' } },
        content: <Spin size="large" />
      });

      axios
        .put(`${urls.models(projectId)}/${modelId}/schema/${oldValue}`, {
          name: nextValue
        })
        .then(() => {
          const index = this.schema.findIndex(
            userProp => userProp.name === oldValue
          );
          this.schema[index].name = nextValue;
        })
        .catch(e => {
          handleError(e);
        })
        .finally(() => {
          this.isLoading = false;
          modal.destroy();
        });
    }
  };

  handleOnChangeData = nextValue => {
    this.formData = nextValue;
  };

  handleOnChangeMeta = nextValue => {
    this.meta = nextValue;
    this.updateDiff();
  };

  renderModalApiInfo() {
    const { t } = this.props;
    return (
      <Modal
        width="720px"
        onClick={event => event.stopPropagation()}
        title={t('API')}
        visible={this.apiInfoIsVisible}
        onCancel={() => {
          this.apiInfoIsVisible = false;
        }}
        closable
        footer={null}
      >
        <div>
          {t('public token')}: <Tag color="#108ee9">{this.modelToken}</Tag>
        </div>

        <div className={style.apiItem}>
          {t('Get all items')}
          <ApiInfo
            api={`${window.location.origin}/api/public/models/${this.modelToken}/data`}
            type="GET"
          />
        </div>
      </Modal>
    );
  }

  renderModalEditField() {
    const { t } = this.props;
    const { match } = this.props;
    const id = get(match, 'params.modelId', 'new');

    return (
      <Modal
        onClick={event => event.stopPropagation()}
        width={980}
        wrapClassName={style.wrapModalField}
        className={style.modalField}
        title={t('input_fields_editor')}
        visible={this.isVisibleFieldEditor}
        onCancel={this.handleCancelEditField}
        closable
        footer={[
          <div key="footer" className={style.footerModel}>
            <div key="left-side" />
            <div key="right-side">
              <Button key="cancel" onClick={this.handleCancelEditField}>
                {t('cancel')}
              </Button>
              <Button key="save" onClick={this.handleFinishEditField}>
                {t('apply')}
              </Button>
            </div>
          </div>
        ]}
      >
        <Observer
          render={() => (
            <FormFieldEditor
              isNew={id === 'new'}
              name={this.fieldName}
              value={this.field}
              onChange={this.onChangeField}
            />
          )}
        />
      </Modal>
    );
  }

  renderHeader() {
    const { mainStore, match, t } = this.props;
    const { selectedProject } = mainStore;
    const id = get(match, 'params.modelId', 'new');
    return (
      <Header className={stylePage.headerWithSide}>
        <div className={stylePage.header__leftSide}>
          <Link to={routes.to(routes.models, selectedProject.id)}>
            <Button shape="circle" icon="arrow-left" />
          </Link>

          <Divider type="vertical" />

          <div className={style.header__title}>{t('model editor')}</div>

          <Divider type="vertical" />

          <Button
            htmlType="button"
            className={stylePage.header__item}
            type="primary"
            loading={this.isLoading}
            disabled={this.isLoading || !this.isDirty}
            onClick={this.handleOnSaveModel}
            icon="save"
          >
            {t('save')}
          </Button>

          <ModelMetaEditor
            className={stylePage.header__item}
            value={this.meta}
            onChange={this.handleOnChangeMeta}
          />

          <Button
            className={stylePage.header__item}
            icon="delete"
            disabled={this.isLoading || id === 'new'}
            type="danger"
            onClick={this.handleOnDelete}
          />

          <Button
            className={classNames(
              stylePage.header__item__info,
              stylePage.header__item
            )}
            icon="api"
            type="primary"
            disabled={this.isLoading || id === 'new'}
            onClick={() => (this.apiInfoIsVisible = true)}
          >
            {t('API')}
          </Button>
        </div>
        <div className={stylePage.header__rightSide}>
          <div className={stylePage.header__item}>
            <Button
              onClick={this.handleOnCreateNewFormField}
              type="primary"
              icon="plus-circle"
            >
              {t('add a new field')}
            </Button>
          </div>
          {/* <div className={stylePage.header__item}> */}
          {/*  {t('design')} */}
          {/*  &#160;&#160; */}
          {/*  <Switch */}
          {/*    className={style.switch} */}
          {/*    checked={this.designIsEnabled} */}
          {/*    onChange={this.handlerOnChangeEditorMode} */}
          {/*  /> */}
          {/* </div> */}
        </div>
      </Header>
    );
  }

  renderDesign() {
    const { t } = this.props;

    return [
      <div key="info_text" className={style.infoHeader}>
        <div className={style.infoHeader__col1__title}>
          {t('field_name')} (JSON)
        </div>
        <div className={style.infoHeader__col2}>{t('preview_form_title')}</div>
      </div>,
      <div key="design" className={style.design}>
        <div className={style.design__form}>
          <DeclarativeForm
            isPreview="true"
            view={toJS(this.schema)}
            value={this.formData}
            onChange={this.handleOnChangeData}
            fieldClassName={style.form__preview__item}
            renderPreField={fieldName => {
              return (
                <div className={style.infoHeader__col1}>
                  <EditableTitle
                    onChange={this.handleChangeFieldName}
                    value={fieldName}
                    disabled={fieldName === 'title'}
                  />
                </div>
              );
            }}
            renderPostField={fieldName => {
              if (fieldName === 'title')
                return (
                  <div
                    key={`PostField_${fieldName}`}
                    className={style.design__control}
                  />
                );
              return (
                <div
                  key={`PostField_${fieldName}`}
                  className={style.design__control}
                >
                  <Icon
                    className={style.control__Item}
                    style={iconStyle}
                    type="setting"
                    onClick={() => this.editField(fieldName)}
                  />
                  <Icon
                    className={style.control__Item}
                    style={iconStyle}
                    type="close"
                    onClick={() => this.deleteField(fieldName)}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    ];
  }

  renderSourceCodeEditor() {
    return (
      <AceEditor
        mode="json"
        fontSize={18}
        tabSize={2}
        width="100%"
        height="calc(100% - 56px)"
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true
        }}
        value={this.sourceCodeValue}
        theme="dracula"
        // theme="tomorrow_night_eighties"
        onChange={this.debouncedHandleOnChangeSourceCode}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
      />
    );
  }

  renderPreview() {
    const { t } = this.props;
    return (
      <Modal
        onClick={event => event.stopPropagation()}
        width={820}
        title={t('preview')}
        visible={this.previewIsVisible}
        onCancel={this.handleClosePreview}
        footer={[
          <Button key="back" onClick={this.handleClosePreview}>
            {t('back')}
          </Button>
        ]}
      >
        <DeclarativeForm
          view={this.schema}
          value={this.formData}
          onChange={this.handleOnChangeDataDeclarativeForm}
        />
      </Modal>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Layout>
        {this.previewIsVisible ? this.renderPreview() : null}
        {this.renderHeader()}
        <Prompt
          when={this.isDirty}
          message={t(
            'You have unsaved changes. Are you sure you want to leave?'
          )}
        />
        <Content className={stylePage.content}>
          <div className={stylePage.contentBody}>
            <div className={style.subHeader}>
              <IconSelector
                className={style.subHeader__item}
                value={this.modelIcon}
                onChange={this.handleOnChangeModelIcon}
              />
              <EditableTitle
                value={this.modelName}
                onChange={this.handleOnChangeModelName}
              />
            </div>
            {this.designIsEnabled
              ? this.renderDesign()
              : this.renderSourceCodeEditor()}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
        {this.isVisibleFieldEditor ? this.renderModalEditField() : null}
        {this.apiInfoIsVisible ? this.renderModalApiInfo() : null}
      </Layout>
    );
  }
}
