import React from 'react';
import { Modal } from 'antd';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import _get from 'lodash/get';
import isString from 'lodash/isString';
import {
  RELATION_TYPE,
  VIEW_KIND
} from './components/DeclarativeForm/constants';
import DependencyList from './components/DependencyList';

async function sleep(timeout, value) {
  return new Promise(resolve => setTimeout(() => resolve(value), timeout));
}

// eslint-disable-next-line no-unused-vars
function handleReferenceException(e, match, callback = () => {}) {
  const text = _get(e, 'response.data.errors', [
    e.reason || e.message || e.toString()
  ])
    .map(window.t)
    .join('\n');

  Modal.warn({
    title: window.t('warn'),
    content: (
      // eslint-disable-next-line react/jsx-filename-extension
      <pre
        readOnly
        style={{
          fontFamily: 'inherit',
          border: 'none',
          backgroundColor: 'transparent',
          whiteSpace: 'pre-wrap',
          width: '100%',
          margin: 0
        }}
      >
        {text}
        <DependencyList
          t={window.t}
          match={match}
          dependencyList={e.response.data.payload}
          hideLabel
        />
      </pre>
    )
  });
}

function handleError(e, callback = () => {}) {
  const error = _get(e, 'response.data.errors', e.reason || e.message || e);

  if (e.response && e.response.status === 404) {
    window.location = `/404?message=${window.t(error)}`;
    return;
  }

  if (isArray(error)) {
    errorBox(error.join('\n'), callback);
  } else if (isObject(error)) {
    if (Object.keys(error).length === 1 && isArray(error.errors)) {
      errorBox(error.errors.join('\n'), callback);
      return;
    }
    let msg = '';
    Object.keys(error).forEach(key => {
      msg += `${key} - ${error[key].join('\n')}\n`;
    });
    errorBox(msg, callback);
  } else if (isString(error)) {
    errorBox(error, callback);
  }
}

function errorBox(text, callback = () => {}) {
  Modal.error({
    onOk() {
      callback();
    },
    onCancel() {
      callback();
    },
    title: window.t('error'),
    content: (
      // eslint-disable-next-line react/jsx-filename-extension
      <pre
        readOnly
        style={{
          fontFamily: 'inherit',
          border: 'none',
          backgroundColor: 'transparent',
          whiteSpace: 'pre-wrap',
          width: '100%',
          margin: 0
        }}
      >
        {window.t(text)}
      </pre>
    )
  });
}

function strip(html) {
  // eslint-disable-next-line no-undef
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

function infoBox(text) {
  Modal.info({
    title: window.t('error'),
    content: window.t(text)
  });
}

function warnBox(text) {
  Modal.warn({
    title: window.t('error'),
    content: window.t(text)
  });
}

function getRelationshipType(field) {
  if (Object.keys(RELATION_TYPE).includes(field.type)) {
    return field.type;
  }
  if (field.kind === VIEW_KIND.SELECT) {
    return RELATION_TYPE.ONE;
  }

  if (field.kind === VIEW_KIND.LIST) {
    return RELATION_TYPE.MANY;
  }

  throw Error('bad field');
}

export {
  sleep,
  strip,
  errorBox,
  handleError,
  handleReferenceException,
  infoBox,
  warnBox,
  getRelationshipType
};
