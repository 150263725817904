import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import urls from '../constants/urls';
import { handleError } from '../utils';

const File = types.model('File', {
  id: types.identifier,
  createdAt: types.Date,
  updatedAt: types.Date,
  name: types.string,
  type: types.string,
  size: types.number,
  URI: types.string,
  thumbUrl: types.maybeNull(types.string)
});

const FixedFile = types.snapshotProcessor(File, {
  // from snapshot to instance
  preProcessor({ createdAt, updatedAt, ...backendItem }) {
    return {
      ...backendItem,
      createdAt: Date.parse(createdAt),
      updatedAt: Date.parse(updatedAt)
    };
  }
  // // from instance to snapshot
  // postProcessor({ createdAt, updatedAt, ...item }) {
  //   return {
  //     ...item,
  //     createdAt: createdAt.toISOString(),
  //     updatedAt: updatedAt.toISOString()
  //   };
  // }
});

export default types
  .model('FileStore', {
    items: types.array(FixedFile)
  })
  .actions(self => ({
    add(item) {
      self.items.push(item);
    },
    remove: flow(function*(projectId, fileId) {
      yield axios.delete(`${urls.projects}/${projectId}/files/${fileId}`);
      const index = self.items.findIndex(item => {
        return item.id === fileId;
      });
      if (index !== -1) {
        self.items.splice(index, 1);
      }
      return self.items;
    }),
    fetchInfo: flow(function*(projectId) {
      const { data } = yield axios.get(
        `${urls.projects}/${projectId}/files/info`
      );
      return data || [];
    }),
    fetchAll: flow(function*(projectId) {
      self.items.splice(0, self.items.length);
      try {
        const { data } = yield axios.get(`${urls.projects}/${projectId}/files`);
        self.items = data;
        return self.items;
      } catch (e) {
        handleError(e);
      }
    }),
    fetchDependencies: flow(function*(projectId, fileId) {
      try {
        const { data } = yield axios.get(
          `${urls.projects}/${projectId}/files/${fileId}/dependencies`
        );
        return data;
      } catch (e) {
        handleError(e);
      }
    })
  }));
