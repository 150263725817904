import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import axios from 'axios';
import { handleError } from '../../utils';
import style from './style.module.scss';
import urls from '../../constants/urls';
import routes from '../../constants/routes';

export default
@inject('mainStore', 'auth', 't')
@observer
@withRouter
class Summary extends React.Component {
  @observable status = 'pending';

  componentDidMount() {
    this.fetchStatus();
  }

  fetchStatus = () => {
    const { match, history } = this.props;

    const uuid = get(match, 'params.uuid', '');
    const projectId = get(match, 'params.projectId', '');

    const id = setInterval(async () => {
      try {
        const { data } = await axios.get(urls.processPayment(projectId, uuid));
        if (data.status === 'pending') {
          return;
        }

        if (data.status === 'succeeded') {
          clearInterval(id);
          history.push(routes.to(routes.summary, projectId));
        }

        if (data.status === 'canceled') {
          clearInterval(id);
          Modal.error({
            onOk() {
              history.push(routes.to(routes.summary, projectId));
            },
            onCancel() {
              history.push(routes.to(routes.summary, projectId));
            },
            title: window.t('error'),
            content: (
              // eslint-disable-next-line react/jsx-filename-extension
              <pre
                readOnly
                style={{
                  fontFamily: 'inherit',
                  border: 'none',
                  backgroundColor: 'transparent',
                  whiteSpace: 'pre-wrap',
                  width: '100%',
                  margin: 0
                }}
              >
                {window.t(`payment_is_${data.status}`)}
              </pre>
            )
          });
        }
      } catch (e) {
        handleError(e);
        clearInterval(id);
      }
    }, 2000);
  };

  render() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const message = params.get('message');

    return (
      <div className={style.page}>
        <div>
          <Spin size="large" />
        </div>
        <div className={style.info__title}>{message}</div>
      </div>
    );
  }
}
