import React from 'react';
import get from 'lodash/get';
import { observer, inject } from 'mobx-react';
import routes from '../../constants/routes';

const DependencyList = inject()(
  observer(props => {
    const { hideLabel, dependencyList, match, t } = props;
    const projectId = get(match, 'params.projectId');

    if (dependencyList.length === 0 && !hideLabel) {
      // eslint-disable-next-line react/jsx-filename-extension
      return <div>{t('Not used in data')}</div>;
    }

    return (
      <div>
        {!hideLabel && <div>{t('Used in')}:</div>}
        {dependencyList.map(item => (
          <div>
            {item.id ? (
              <a
                href={routes.to(
                  routes.dataRecord,
                  projectId,
                  item.modelId,
                  item.id
                )}
              >
                {item.title}
              </a>
            ) : (
              <a href={routes.to(routes.model, projectId, item.modelId)}>
                {item.title}
              </a>
            )}
          </div>
        ))}
      </div>
    );
  })
);

export default DependencyList;
