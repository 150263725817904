import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

export default class HookForm extends Form {
  constructor(initialValue, t, onSuccess, onError) {
    super();

    this.onSuccess = onSuccess;
    this.onError = onError;
    this.initialValue = initialValue;

    this.$('name').set('label', t('Name'));
    this.$('name').set('placeholder', t('Enter what this hook will do'));
    this.$('name').set('value', (initialValue && initialValue.name) || '');

    this.$('action').set('label', t('Action'));
    this.$('action').set('placeholder', t('Choose event'));
    this.$('action').set('value', (initialValue && initialValue.action) || '');

    this.$('method').set('label', t('Method'));
    this.$('method').set('placeholder', t('Http method'));
    this.$('method').set('value', (initialValue && initialValue.method) || '');

    this.$('contentType').set('label', t('ContentType'));
    this.$('contentType').set('placeholder', t('Http contentType'));
    this.$('contentType').set(
      'value',
      (initialValue && initialValue.contentType) || ''
    );

    this.$('url').set('label', t('url'));
    this.$('url').set('placeholder', t('Http url'));
    this.$('url').set('value', (initialValue && initialValue.url) || '');

    this.$('body').set('label', t('body'));
    this.$('body').set('placeholder', t('Http post body'));
    this.$('body').set('value', (initialValue && initialValue.body) || '');
  }

  options() {
    return {
      showErrorsOnReset: false,
      validateOnBlur: false
    };
  }

  plugins() {
    const isValidUrl = str => {
      try {
        // eslint-disable-next-line no-new
        new URL(str);
        return true;
      } catch (e) {
        return false;
      }
    };

    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          validator.register('url', isValidUrl, undefined);
        }
      })
      // dvr: validatorjs
    };
  }

  /*
    Return the `fields` as a collection into the `setup()` method
    with a `rules` property for the validation.
  */
  setup() {
    return {
      fields: [
        {
          name: 'name',
          rules: 'required|string'
        },
        {
          name: 'action',
          rules: 'required|string'
        },
        {
          name: 'method',
          rules: ''
        },
        {
          name: 'url',
          rules: 'required|url'
        },
        {
          name: 'contentType',
          rules: 'required'
        },
        {
          name: 'body',
          rules: 'string'
        }
      ]
    };
  }

  /*
    Event Hooks
  */
  hooks() {
    return {
      onSuccess(form) {
        this.onSuccess(form.values());
      },
      onError(form) {
        // get all form errors
        console.log('All form errors', form.errors());
        this.onError(form.errors());
      }
    };
  }
}
