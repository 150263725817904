import React from 'react';
import { Layout, List, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import routes from '../../constants/routes';
import style from './style.module.scss';

import stylePage from '../pages.module.scss';
import NoData from '../../components/NoData';
import { footerLabel } from '../../components/DeclarativeForm/constants';

const { Header, Content, Footer } = Layout;

export default
@inject('auth', 't', 'mainStore')
@withRouter
@observer
class DataPage extends React.Component {
  @observable addFormIsVisible = false;

  @observable selectedFormTemplateId = -1;

  @observable formView = {};

  @observable formValue = {};

  handleShowAddForm = () => {
    // const { auth } = this.props;
    // create new Item
    this.addFormIsVisible = true;
    this.addFormIsLoading = false;
  };

  renderEmptyList() {
    const { t, match } = this.props;
    return (
      <div className={stylePage.emptyList}>
        <NoData>
          <Link to={routes.to(routes.models, match.params.projectId)}>
            {t('add_your_first_data_model')}
          </Link>
        </NoData>
      </div>
    );
  }

  renderItems() {
    const { match, mainStore, t } = this.props;
    const { selectedProject } = mainStore;
    const { dataModels } = selectedProject;

    return (
      <List
        itemLayout="horizontal"
        dataSource={dataModels.values()}
        renderItem={item => (
          <List.Item key={`${selectedProject.id}_${item.id}`}>
            <List.Item.Meta
              className={style.nameFix}
              avatar={
                <Icon
                  style={{ fontSize: '32px', marginTop: 6 }}
                  type={item.icon}
                />
              }
              title={
                <Link
                  to={routes.to(
                    routes.dataRecords,
                    match.params.projectId,
                    item.id
                  )}
                >
                  {item.name || item.id}
                </Link>
              }
              description={
                item.count ? `${t('entries')} - (${item.count || 120})` : null
              }
            />
          </List.Item>
        )}
      />
    );
  }

  render() {
    const { t, mainStore } = this.props;
    const { selectedProject } = mainStore;

    return (
      <Layout>
        <Header className={stylePage.header}>
          <div className={style.header__title}>{t('data')}</div>
        </Header>
        <Content className={stylePage.content}>
          <div className={stylePage.contentBody}>
            {selectedProject.dataModels.size === 0
              ? this.renderEmptyList()
              : this.renderItems()}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{footerLabel}</Footer>
      </Layout>
    );
  }
}
