import React from 'react';
import classNames from 'classnames';
import {withRouter} from "react-router";
import { Link } from 'react-router-dom';
import {Button} from 'antd';
import style from './style.module.scss';


export default withRouter(({match, location}) => {
  const params = new URLSearchParams(location.search);
  const message = params.get('message');
  return (
    <div className={style.page}>
      <svg dangerouslySetInnerHTML={{
        __html: `
              <defs>
            <path id="four" d="M90 90 L10 90 L70 10 L 70 110"
            ></path>
            <ellipse id="zero" rx="40" ry="45" cx="50" cy="50"></ellipse>
            <linearGradient id="stripes"
                            x1="0" y1="0" x2="100%" y2="0%">
              <stop stop-color="hsl(45,100%,65%)" offset="0"></stop>
              <stop stop-color="hsl(320,100%,65%)" offset="50%"></stop>
              <stop stop-color="hsl(200,100%,60%)" offset="100%"></stop>
            </linearGradient>
          </defs>
      `
      }}/>
      <div className={style.positionBlock}>
        <div className={style.wrapper}>
          <ul className={classNames(style.list, style.listDashArrayAnim)}>
            <li className={style.list__item}>
              <svg
                viewBox="0 0 100 100"
                dangerouslySetInnerHTML={{
                  __html: `
                      <use xlink:href="#four" stroke="#2f54eb" stroke-width="1"></use>
                      <use xlink:href="#four" stroke="url(#stripes)" stroke-width="2" stroke-dasharray="500"></use>
                  `
                }}
              />
            </li>

            <li className={style.list__item}>
              <svg viewBox="0 0 100 100" dangerouslySetInnerHTML={{
                __html: `
                            <use xlink:href="#zero" stroke="#2f54eb" stroke-width="1"></use>
                <use xlink:href="#zero" stroke="url(#stripes)" stroke-width="2" stroke-dasharray="500">
                </use>
              `
              }}/>
            </li>

            <li className={style.list__item}>
              <svg viewBox="0 0 100 100" dangerouslySetInnerHTML={{
                __html: `
                            <use xlink:href="#four" stroke="#2f54eb" stroke-width="1"></use>
                <use xlink:href="#four" stroke="url(#stripes)" stroke-width="2" stroke-dasharray="500"></use>
              `
              }}/>
            </li>
          </ul>
          <div className={style.info}>
            <p className={style.info__title}>Oops... NOT FOUND</p>
            <Link to="/">
              <Button type="primary" className={style.info__btn} ghost icon="home">Home</Button>
            </Link>
            <p className={style.info__text}>{message && message}</p>
          </div>
        </div>
      </div>
    </div>
  );
});
