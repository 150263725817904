import React from 'react';
import { Button, Icon, Modal } from 'antd';
import classNames from 'classnames';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import style from './style.module.scss';

const IconList = [
  'area-chart',
  'pie-chart',
  'bar-chart',
  'dot-chart',
  'line-chart',
  'radar-chart',
  'heat-map',
  'fall',
  'rise',
  'stock',
  'box-plot',
  'fund',
  'sliders',
  'lock',
  'unlock',
  'bars',
  'book',
  'calendar',
  'cloud',
  'cloud-download',
  'code',
  'copy',
  'credit-card',
  'delete',
  'desktop',
  'download',
  'ellipsis',
  'file',
  'file-text',
  'file-unknown',
  'file-pdf',
  'file-word',
  'file-excel',
  'file-jpg',
  'file-ppt',
  'file-markdown',
  'file-add',
  'folder',
  'folder-open',
  'folder-add',
  'hdd',
  'frown',
  'meh',
  'smile',
  'inbox',
  'laptop',
  'appstore',
  'link',
  'mail',
  'mobile',
  'notification',
  'paper-clip',
  'picture',
  'poweroff',
  'reload',
  'search',
  'setting',
  'share-alt',
  'shopping-cart',
  'tablet',
  'tag',
  'tags',
  'to-top',
  'upload',
  'user',
  'video-camera',
  'home',
  'cloud-upload',
  'star',
  'heart',
  'environment',
  'eye',
  'eye-invisible',
  'camera',
  'save',
  'team',
  'solution',
  'phone',
  'filter',
  'exception',
  'export',
  'customer-service',
  'qrcode',
  'scan',
  'like',
  'dislike',
  'message',
  'pay-circle',
  'calculator',
  'pushpin',
  'bulb',
  'select',
  'switcher',
  'rocket',
  'bell',
  'disconnect',
  'database',
  'compass',
  'barcode',
  'hourglass',
  'key',
  'flag',
  'layout',
  'printer',
  'sound',
  'usb',
  'skin',
  'tool',
  'sync',
  'wifi',
  'car',
  'schedule',
  'user-add',
  'user-delete',
  'usergroup-add',
  'usergroup-delete',
  'man',
  'woman',
  'shop',
  'gift',
  'idcard',
  'medicine-box',
  'red-envelope',
  'coffee',
  'copyright',
  'trademark',
  'safety',
  'wallet',
  'bank',
  'trophy',
  'contacts',
  'global',
  'shake',
  'api',
  'fork',
  'dashboard',
  'table',
  'profile',
  'alert',
  'audit',
  'branches',
  'build',
  'border',
  'crown',
  'experiment',
  'fire',
  'money-collect',
  'property-safety',
  'read',
  'reconciliation',
  'rest',
  'security-scan',
  'insurance',
  'interaction',
  'safety-certificate',
  'project',
  'thunderbolt',
  'block',
  'cluster',
  'deployment-unit',
  'dollar',
  'euro',
  'pound',
  'file-done',
  'file-exclamation',
  'file-protect',
  'file-search',
  'file-sync',
  'gateway',
  'gold',
  'robot',
  'shopping'
];

@inject('t')
@observer
class IconSelector extends React.Component {
  @observable isVisible = false;

  handleOnOpenSelector = () => {
    this.isVisible = true;
  };

  handleOnSelectIcon = value => {
    this.hideIconSelector();
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  hideIconSelector = () => {
    this.isVisible = false;
  };

  render() {
    const { value, t, onChange, className, ...rest } = this.props;
    console.log('observable RENDER', this.isVisible);
    return [
      <Button
        key="icon-selector-handler"
        htmlType="button"
        onClick={this.handleOnOpenSelector}
        size="large"
        className={classNames(style.iconHandler, className)}
        {...rest}
      >
        {value && <Icon type={value} style={{ fontSize: 32 }} />}
      </Button>,
      this.isVisible ? (
        <Modal
          key="icon-selector-modal"
          width={512}
          title={t('Choose icon')}
          visible={this.isVisible}
          onCancel={this.hideIconSelector}
          footer={null}
          closable
        >
          <div className={style.list}>
            {IconList.map(icon => (
              <Button
                key={icon}
                style={{
                  padding: 8,
                  margin: 4,
                  height: 'auto',
                  paddingBottom: 4
                }}
                htmlType="button"
                onClick={event => {
                  event.stopPropagation();
                  this.handleOnSelectIcon(icon);
                }}
              >
                <Icon type={icon} style={{ fontSize: 32 }} />
              </Button>
            ))}
          </div>
        </Modal>
      ) : null
    ];
  }
}

export default IconSelector;
