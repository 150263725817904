import { flow, getParentOfType, types } from 'mobx-state-tree';
import axios from 'axios';
import qs from 'qs';
import FieldModel from './field';
import urls from '../constants/urls';
import Project from './Project';

const DataModelMeta = types.model('DataModelMeta', {
  isSingleton: types.boolean,
  isVisibleInPageEditor: types.boolean
});

const DataModel = types
  .model('DataModel', {
    id: types.identifier,
    name: types.string,
    meta: DataModelMeta,
    alias: types.maybe(types.string),
    schema: types.array(FieldModel),
    createdAt: types.Date,
    updatedAt: types.Date,
    icon: 'file',
    description: '-',
    token: 'n/a',
    count: 0
  })
  .preProcessSnapshot(({ createdAt, updatedAt, ...rest } = {}) => ({
    createdAt: Date.parse(createdAt),
    updatedAt: Date.parse(updatedAt),
    ...rest
  }))
  .volatile(() => ({
    dataItem: {}
  }))
  .views(self => ({
    get project() {
      return getParentOfType(self, Project);
    },
    get title() {
      return self.name || self.alias || self.id;
    }
  }))
  .actions(self => ({
    fetchEntryCount: flow(function*() {
      const { data } = yield axios.get(
        `${urls.data(self.project.id, self.id)}/count`
      );
      self.count = data;
    }),
    fetchTotal: flow(function*() {
      const { data } = yield axios.get(
        `${urls.data(self.project.id, self.id)}/total`
      );
      return data;
    }),
    fetchEntries: flow(function*({ limit, offset, filters, sorter, compact }) {
      const queryParams = { limit, offset, filters, sorter, compact };
      const stringified = qs.stringify(queryParams, {
        arrayFormat: 'repeat',
        addQueryPrefix: true
      });
      const { data } = yield axios.get(
        urls.data(self.project.id, self.id) + stringified
      );
      if (self.meta.isSingleton) {
        // эта хуйня нужна чтоб редактор страниц работал, там
        // модель-данные
        self.dataItem = data[0];
      }
      return data;
    }),
    fetchEntry: flow(function*(dataId, compact = false) {
      let compactParam = '';
      if (compact) {
        compactParam = '?compact';
      }
      const { data } = yield axios.get(
        `${urls.data(self.project.id, self.id)}/${dataId}${compactParam}`
      );
      if (self.meta.isSingleton) {
        self.dataItem = data;
      }
      return data;
    }),
    saveEntry: flow(function*(payload) {
      if (payload.id && payload.id !== 'new') {
        const { data } = yield axios.put(
          `${urls.data(self.project.id, self.id)}/${payload.id}`,
          payload
        );
        return data;
      }
      const { data } = yield axios.post(
        urls.data(self.project.id, self.id),
        payload
      );
      if (self.meta.isSingleton) {
        self.dataItem = data;
      }
      return data;
    }),
    removeEntry: flow(function*(id) {
      if (self.meta.isSingleton) {
        self.dataItem = {};
      }
      return yield axios.delete(`${urls.data(self.project.id, self.id)}/${id}`);
    })
  }));

export default DataModel;
