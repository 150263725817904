import { types } from 'mobx-state-tree';

export default types.model('ViewFieldModal', {
  kind: types.string,
  label: types.maybe(types.string),
  help: types.maybe(types.string),
  description: types.maybe(types.string),
  title: types.maybe(types.string),
  default: '',
  placeholder: types.maybe(types.string),
  items: types.maybeNull(types.maybe(types.map(types.string)))
});
