function toMap(arr = [], key = 'id') {
  if (Array.isArray(arr)) {
    return arr.reduce((accumulator, currentValue) => {
      accumulator[currentValue[key]] = currentValue;
      return accumulator;
    }, {});
  }
  return {};
}

const xahMapToObj = aMap => {
  const obj = {};
  aMap.forEach((v, k) => {
    obj[k] = v;
  });
  return obj;
};

export { toMap, xahMapToObj };
